import React, { useState } from 'react'
import { PageHeader, Button, Form, Steps, Row, Col, Divider } from 'antd'
import { Link } from 'react-router-dom'
import { StoreValue } from 'antd/lib/form/interface'
import { useParams } from 'react-router'
import { AvatarProps } from 'antd/lib/avatar'

import { headerIcons } from '../../../constants/IconsConstants'
import { Routes } from '../../../constants/RoutesConstants'
import VouchersWizard from '../VouchersWizard'
import VouchersCsvUpload from '../VouchersCsvUpload'
import {
  CreatePromotionMutationVariables as CreateVariables,
  UpdatePromotionMutationVariables as UpdateVariables,
  useCreatePromotionMutation,
  useUpdatePromotionMutation,
} from '../../../apollo/generated/api'
import { PromotionFormData } from '../types'
import SuccessSvg from '../../../assets/svgs/Success.svg'
import { handleMutationResult } from '../../../apollo'
import { buildPromotionPayload } from '../../../utils/promotionUtils'

const { Step } = Steps

export enum FormViews {
  NEW = 'new',
  EDIT = 'edit',
}

interface Props {
  view?: FormViews
  data?: PromotionFormData
}

const defaultValues: Partial<PromotionFormData> = {
  firstOrderOnly: true,
}

const VouchersForm: React.FC<Props> = ({ view, data }) => {
  const initialValues = { ...defaultValues, ...data } as PromotionFormData
  const { id } = useParams<{ id: string }>()
  const [form] = Form.useForm()
  const [currentStep, setCurrentStep] = useState(0)
  const [promotionCreated, setPromotionCreated] = useState(false)
  const [showCsvModal, setShowCsvModal] = useState(false)
  const [createPromotionMutation, { loading: createLoading }] = useCreatePromotionMutation()
  const [updatePromotionMutation, { loading: updateLoading }] = useUpdatePromotionMutation()
  const [formData, setFormData] = useState<PromotionFormData | undefined>(initialValues)
  const isEditing = view === FormViews.EDIT
  const nextStep = () => setCurrentStep(currentStep + 1)
  const prevStep = () => setCurrentStep(currentStep - 1)

  const handleSubmit = (): void => {
    if (!formData || !formData?.settings) return

    const mutation = isEditing
      ? updatePromotionMutation({ variables: buildPromotionPayload({ id, formData }) as UpdateVariables })
      : createPromotionMutation({ variables: buildPromotionPayload({ formData }) as CreateVariables })

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    void handleMutationResult(mutation, isEditing ? 'updatePromotion' : 'createPromotion', {
      notifications: {
        success: {
          title: `Promotion ${isEditing ? 'updated' : 'created'}`,
        },
      },
    }).then(() => setPromotionCreated(true))
  }

  const renderWizard = (): JSX.Element => {
    switch (currentStep) {
      case 0:
        return <VouchersWizard.General />
      case 1:
        return <VouchersWizard.Create data={formData} isEditing={isEditing} setData={setFormData} />
      case 2:
        return <VouchersWizard.Summary data={formData} />
      default:
        return <div>Step does not have any content</div>
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const saveData = (_: string, info: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (!info?.changedFields?.length) return

    const {
      changedFields: [
        {
          name: [name],
          value,
        },
      ],
    }: { changedFields: Array<StoreValue> } = info
    const newData = { [name]: value }

    setFormData({ ...formData, ...(newData as PromotionFormData) })
  }

  const validateStep = async () => {
    await form.validateFields().then(() => {
      nextStep()
    })
  }
  const headerProps = {
    title: isEditing ? 'Editing voucher' : 'Create new voucher',
    avatar: {
      src: isEditing ? headerIcons.EDIT_ICON : headerIcons.VOUCHER_ICON,
      shape: 'square',
    } as AvatarProps,
    ...(!isEditing
      ? {
          extra: [
            <Button key="csvButton" onClick={() => setShowCsvModal(true)}>
              Import .csv
            </Button>,
          ],
        }
      : null),
  }

  return (
    <>
      <VouchersCsvUpload visible={showCsvModal} closeModal={() => setShowCsvModal(false)} />

      <PageHeader {...headerProps} />

      <Row justify="center">
        <Col md={24} lg={18} xl={12}>
          {promotionCreated ? (
            <div style={{ textAlign: 'center' }}>
              <img style={{ width: 400, display: 'block', margin: '30px auto 100px' }} src={SuccessSvg} alt="Success" />

              <Button style={{ marginRight: 20 }}>
                <Link to={Routes.VOUCHERS_CSV_FILES}>Download .csv files</Link>
              </Button>

              <Button type="primary">
                <Link to={Routes.VOUCHERS}>Go to vouchers</Link>
              </Button>
            </div>
          ) : (
            <Form.Provider onFormChange={saveData}>
              <Form initialValues={formData} name="vouchers-form" form={form} layout="vertical">
                <Steps size="small" current={currentStep} style={{ margin: '20px 0 40px' }}>
                  <Step title="General" />
                  <Step title="Create" />
                  <Step title="Summary" />
                </Steps>

                {renderWizard()}

                <Divider />

                <Row gutter={16} justify="center">
                  {currentStep > 0 ? (
                    <Col span={8}>
                      <Button size="large" onClick={prevStep} block>
                        Back
                      </Button>
                    </Col>
                  ) : null}

                  <Col span={8}>
                    {currentStep === 2 ? (
                      <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        disabled={createLoading || updateLoading}
                        loading={createLoading || updateLoading}
                        block
                        onClick={handleSubmit}
                        data-testid="voucher-submit"
                      >
                        {isEditing ? 'Update voucher' : 'Confirm and create'}
                      </Button>
                    ) : (
                      <Button type="primary" size="large" data-testid="next-step" onClick={validateStep} block>
                        Next step
                      </Button>
                    )}
                  </Col>
                </Row>
              </Form>
            </Form.Provider>
          )}
        </Col>
      </Row>
    </>
  )
}

export default VouchersForm
