/* eslint-disable @typescript-eslint/no-unsafe-call */
import { InMemoryCache, ApolloClient, NormalizedCacheObject, HttpLink, DocumentNode } from '@apollo/client'
import { getOperationName, relayStylePagination } from '@apollo/client/utilities'

import config from '../config'
import { getUserSession } from '../utils'

import introspectionResult from './generated/introspection'

const customFetch = (uri: RequestInfo, options?: RequestInit): Promise<Response> => {
  const token = getUserSession().session?.token.value
  const authorization = token ? `Bearer ${token}` : ''
  const headers = new Headers({
    ...options?.headers,
    Authorization: authorization,
  })
  const config = options ?? {}

  config.headers = headers

  return fetch(uri, config)
}
const cache = new InMemoryCache({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  possibleTypes: introspectionResult.possibleTypes,
  typePolicies: {
    Customer: {
      fields: {
        userPlan: { merge: false },
        deliveryStats: { merge: false },
      },
    },
    Order: {
      fields: {
        adjustments: { merge: false },
        pricing: {
          merge: (previous: object = {}, incoming: object): object => ({ ...previous, ...incoming }),
        },
      },
    },
    ReferralProgramSetting: {
      keyFields: ['country', 'brand'],
    },
    Query: {
      fields: {
        promotions: relayStylePagination(),
      },
    },
  },
})
const link = new HttpLink({
  uri: config.adminApiEndpoint,
  fetch: customFetch,
})

export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({ cache, link })
export const queryNames = (...documents: DocumentNode[]): string[] => {
  const names = documents.map(doc => getOperationName(doc))

  return names.filter((name): name is string => name != null)
}
