/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { ExecutionResult } from 'graphql'
import { notification } from 'antd'
import { ApolloError } from '@apollo/client'

import * as messages from '../constants/MessagesConstants'
import Stage from '../constants/StagesConstants'
import config from '../config'

type MutationResponse<MutationData, MutationName extends keyof MutationData> = {
  readonly [K in MutationName]?: ({ __typename?: string } & unknown) | null
}

export type MutationResultNotification = {
  title?: string
  description?: string
}

export type MutationHandlerOptions = {
  notifications?: {
    success?: { title?: string; description?: string }
    error?: { title?: string }
  }
}

export type HandlerResult<MutationData> = {
  data?: MutationData | null
  error?: ApolloError
}

const errorNotification = (description: string, options?: MutationHandlerOptions): void =>
  notification.error({
    message: options?.notifications?.error?.title ?? messages.ERROR_TITLE,
    description,
    duration: 0,
  })
const handleSuccess = (options?: MutationHandlerOptions): void =>
  notification.success({
    message: options?.notifications?.success?.title || messages.MUTATION_SUCCESS,
    description: options?.notifications?.success?.description || messages.KEEP_GOING,
  })

const handleMutationException = (error: Error, options?: MutationHandlerOptions): void => {
  let { message } = error

  if ('networkError' in error || 'graphQLErrors' in error) {
    const apolloError = error as ApolloError

    if (apolloError.networkError) {
      message = messages.NETWORK_ERROR
    } else if (apolloError.graphQLErrors) {
      message = apolloError.graphQLErrors.map(e => e.message).join('\n')
    }
  }

  if (![Stage.TEST, Stage.PRODUCTION].includes(config.stage)) {
    console.error(error)
  }

  return errorNotification(message, options)
}

export async function handleMutationResult<
  MutationData extends MutationResponse<MutationData, ResultKey>,
  ResultKey extends keyof MutationData
>(
  mutation: Promise<ExecutionResult<MutationData>>,
  resultKey: ResultKey,
  options?: MutationHandlerOptions,
): Promise<HandlerResult<MutationData>> {
  let response

  try {
    response = await mutation
  } catch (ex) {
    handleMutationException(ex, options)

    return { error: ex }
  }

  handleSuccess(options)

  return response
}
