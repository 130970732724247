import { Form, Select } from 'antd'
import humanizeString from 'humanize-string'
import React from 'react'

import { PromotionType } from '../../apollo/generated/api'

interface Props {
  label?: string
  required?: boolean
}

function PromotionTypeSelect({ label, required }: Props): JSX.Element {
  return (
    <Form.Item label={label} name="promotionType" rules={[{ required, message: 'Please select a promotion type.' }]}>
      <Select
        style={{ width: '100%' }}
        placeholder="Promotion type"
        data-testid="promotion-type"
        optionFilterProp="children"
        allowClear
        showSearch
      >
        {Object.values(PromotionType).map(type => (
          <Select.Option value={type} key={type}>
            {humanizeString(type)}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

PromotionTypeSelect.defaultProps = {
  label: 'Select a promotion type',
  required: false,
}

export default PromotionTypeSelect
