import { RcFile, UploadProps } from 'antd/lib/upload'
import csv from 'csvtojson'
import camelcaseKeys from 'camelcase-keys'

const options = {
  trim: true,
  ignoreEmpty: true,
  delimiter: 'auto',
}
const allowedHeaders = {
  campaignName: 'Campaign Name',
  promotionType: 'Promotion Type',
  description: 'Description',
  marketingCopy: 'Marketing Copy',
  targetGroup: 'Target Group',
  brand: 'Brand',
  country: 'Country',
  expirationDate: 'Expiration Date',
  voucherCode: 'Voucher Code',
  usageLimitPerUser: 'Usage Limit Per User',
  numberOfRedemptions: 'Number of Redemptions',
  discountValue: 'Discount Value',
  discountType: 'Discount Type',
  freeShipping: 'Free Shipping',
}

interface Props {
  setRejected: Function
  setData: Function
}

export const draggerProps = ({ setRejected, setData }: Props): UploadProps => ({
  accept: `.csv`,
  name: `files`,
  multiple: false,
  beforeUpload: (file: RcFile): boolean => {
    const reader = new FileReader()

    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (!e.target?.result) return

      void csv(options)
        .on('header', (header: string[]) => {
          const rejected: Array<{ wrongHeader: string; correctHeader: string }> = []

          header.forEach((header, index) => {
            if (!Object.values(allowedHeaders).includes(header.toString())) {
              rejected.push({
                wrongHeader: header,
                correctHeader: Object.values(allowedHeaders)[index],
              })
            }
          })

          setRejected(rejected)
        })
        .fromString(e.target.result as string)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        .then(data => setData(camelcaseKeys(data)))
    }

    reader.readAsText(file)

    return false
  },
})
