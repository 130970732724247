import { Button, Form, Table, Row, Col, Typography, Space } from 'antd'
import { Store } from 'antd/lib/form/interface'
import React from 'react'
import { generatePath, Link } from 'react-router-dom'

import { Promotion, usePromotionsLazyQuery } from '../../../apollo/generated/api'
import { ScreenBreakpoints } from '../../../constants/LayoutConstants'
import { Routes } from '../../../constants/RoutesConstants'
import { useNetworkStatus } from '../../../hooks'
import { handleError } from '../../../utils/errorUtils'
import { parseBoolean, parseEmpty } from '../../../utils/textUtils'
import { VouchersSearchForm } from '../VouchersSearchForm'

const { Text } = Typography
const PAGE_SIZE = 20

type ApiPromotion = { node: Pick<Promotion, 'id'> }

const VouchersSearch: React.FC = () => {
  const [form] = Form.useForm()
  const [getVouchers, { data, called, refetch, fetchMore, networkStatus }] = usePromotionsLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })
  const { isLoading, isLoadingMore } = useNetworkStatus(networkStatus)
  const entries = (data?.promotions.edges || []) as ApiPromotion[]
  const pageInfo = data?.promotions.pageInfo
  const canLoadMore = !!entries?.length && pageInfo?.hasNextPage

  const handleLoadMore = () => {
    if (pageInfo?.hasNextPage && pageInfo?.endCursor) {
      void handleError(() => fetchMore?.({ variables: { after: pageInfo.endCursor } }))
    }
  }

  const handleFilter = (filters: Store) => {
    const variables = {
      code: parseEmpty(filters.code),
      name: parseEmpty(filters.name),
      brand: filters.brand,
      country: filters.country,
      promotionType: filters.promotionType,
      targetingGroup: filters.targetingGroup,
      hasSplitVouchers: parseBoolean(filters.hasSplitVouchers),
      valueType: filters.valueType,
      first: PAGE_SIZE,
    }

    void handleError(() => (called ? refetch?.(variables) : getVouchers?.({ variables })))
  }

  return (
    <>
      <VouchersSearchForm form={form} onSubmit={handleFilter} />

      <Table
        rowKey="node.id"
        data-testid="search-voucher-results-table"
        dataSource={entries}
        loading={isLoading}
        scroll={{ x: ScreenBreakpoints.SM }}
        columns={[
          { title: 'id', dataIndex: ['node', 'id'] },
          {
            title: 'Code',
            dataIndex: ['node', 'code'],
            render: (value: string) => (
              <Text code copyable>
                {value}
              </Text>
            ),
          },
          { title: 'Description', dataIndex: ['node', 'name'] },
          { title: 'Expiration date', dataIndex: ['node', 'expirationDate'] },
          { title: 'Created by', dataIndex: ['node', 'createdBy'] },
          {
            title: 'Link',
            render: ({ node: { id } }: ApiPromotion): JSX.Element => (
              <Space size="middle">
                <Link to={generatePath(Routes.VOUCHER_DETAIL, { id })}>View</Link>
                <Link to={generatePath(Routes.VOUCHER_EDIT, { id })}>Edit</Link>
              </Space>
            ),
          },
        ]}
        pagination={false}
      />

      {canLoadMore && (
        <Row justify="center" style={{ paddingTop: '1.5rem' }}>
          <Col>
            <Button onClick={handleLoadMore} loading={isLoadingMore}>
              Load More
            </Button>
          </Col>
        </Row>
      )}
    </>
  )
}

export default VouchersSearch
