/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Button, Card, Modal, Typography, Upload, Progress, Result, Alert } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import { StoreValue } from 'antd/lib/form/interface'
import pThrottle from 'p-throttle'

import {
  CreatePromotionInputType,
  PromotionTargetingGroup,
  useCreatePromotionMutation,
  ValueType,
} from '../../../apollo/generated/api'

import { draggerProps } from './config'

const { Text, Title } = Typography
const { Dragger } = Upload
const DATA_LIMIT = 101 // Limit is 100, setting +1 to count the header line

interface Props {
  visible?: boolean
  closeModal: () => void
}

const normFile = (e: StoreValue) => {
  if (Array.isArray(e)) return e

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return e.fileList
}

type PromotionType = CreatePromotionInputType & {
  campaignName: string
  targetGroup: PromotionTargetingGroup
  voucherCode: string
  numberOfRedemptions: number
  discountValue: number
  discountType: ValueType
}

const VouchersCsvUpload: React.FC<Props> = ({ visible, closeModal }) => {
  const [form] = Form.useForm()
  const [rejected, setRejected] = useState<Array<{ wrongHeader: string; correctHeader: string }>>()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<PromotionType[]>()
  const [totalCreated, setTotalCreated] = useState(0)
  const [overLimit, setOverLimit] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [createPromotionMutation] = useCreatePromotionMutation()
  const canSubmit = () => data?.length && !rejected?.length

  const handleUpload = () => {
    if (!data) return

    if (data.length > DATA_LIMIT) {
      setOverLimit(true)

      return
    }

    setLoading(true)
    setTotalCreated(0)
    setCompleted(false)

    let saved = 0
    const throttle = pThrottle({
      limit: 1,
      interval: 1000,
    })
    const throttled = throttle((promotion: PromotionType) => {
      void createPromotionMutation({
        variables: {
          createPromotion: {
            name: promotion.campaignName,
            promotionType: promotion.promotionType,
            description: promotion.description,
            marketingCopy: promotion.marketingCopy,
            targetingGroup: promotion.targetGroup,
            brand: promotion.brand,
            country: promotion.country,
            expirationDate: promotion.expirationDate,
            code: promotion.voucherCode,
            usageLimitPerUser: Number(promotion.usageLimitPerUser),
            usageLimit: Number(promotion.numberOfRedemptions),
            value: Number(promotion.discountValue),
            valueType: promotion.discountType,
            freeShipping: promotion.freeShipping?.toString() === 'TRUE',
          },
        },
      })

      return Promise.resolve(promotion)
    })

    void data.map(async (promotion: PromotionType) => {
      await throttled(promotion)

      saved += 1

      setTotalCreated(saved)

      return promotion
    })
  }

  useEffect(() => {
    if (totalCreated === data?.length) {
      setCompleted(true)
      form.resetFields()
    }
  }, [totalCreated])

  const PromotionForm = () => (
    <>
      <Text>
        Importing .csv files is propitious to human error, so make sure you are importing exactly the data you want.
      </Text>

      <Alert
        type={overLimit ? 'error' : 'warning'}
        message={
          overLimit
            ? `You're trying to upload more than 100 lines, double check your .csv file`
            : 'There is a limit of 100 lines per csv file.'
        }
        style={{ marginTop: 20 }}
        showIcon
      />

      {rejected?.length ? (
        <Alert
          type="error"
          message={
            <ul style={{ paddingLeft: 20 }}>
              {rejected.map(({ wrongHeader, correctHeader }) => (
                <li>
                  We've found a problem, the header <Text strong>{wrongHeader}</Text> should be{' '}
                  <Text strong copyable>
                    {correctHeader}
                  </Text>
                </li>
              ))}
            </ul>
          }
          style={{ marginTop: 20 }}
          showIcon
        />
      ) : null}

      <Row style={{ marginTop: 30 }} gutter={16}>
        <Col span={12}>
          <Card title="CSV Template" style={{ height: '100%' }}>
            If you're not sure how the .csv file should look like you can always download an example template.
            <div style={{ marginTop: 30 }}>
              <Button>
                <a href={`${process.env.PUBLIC_URL}/templates/promotion.csv`} download="promotion.csv">
                  Download template
                </a>
              </Button>
            </div>
          </Card>
        </Col>

        <Col span={12}>
          <Card title="Upload .csv file" style={{ height: '100%' }}>
            <Form form={form} onFinish={handleUpload}>
              <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                <Dragger {...draggerProps({ setRejected, setData })}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-hint" style={{ padding: '0 20px' }}>
                    Drag your .csv file here, we'll validate the data as best as we can before creating any data.
                  </p>
                </Dragger>
              </Form.Item>

              <Button htmlType="submit" type="primary" style={{ marginTop: 30 }} disabled={!canSubmit()} block>
                Upload .csv
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  )
  const PromotionProgress = () => (
    <div style={{ textAlign: 'center', margin: '40px 0' }}>
      <Title level={3}>Do not close this window.</Title>
      <Text type="secondary">
        We're creating your promotions, bare with us and don't close this window until all are created.
      </Text>

      <Row justify="center" style={{ margin: '40px 0' }}>
        <Col span={12}>
          <Progress
            percent={data ? (100 * totalCreated) / data.length : 0}
            status="active"
            format={() => `${totalCreated} of ${data?.length}`}
          />
        </Col>
      </Row>
    </div>
  )
  const Completed = () => (
    <Result
      status="success"
      title="All done!"
      subTitle="All promotions have been created, you can now close this window."
      extra={[<Button onClick={closeModal}>Awesome, close this window</Button>]}
    />
  )

  return (
    <Modal
      onCancel={closeModal}
      title="Import .csv file"
      width={980}
      footer={null}
      visible={visible}
      maskClosable={false}
      closable={!loading}
    >
      {!loading ? <PromotionForm /> : null}
      {loading && !completed ? <PromotionProgress /> : null}
      {completed ? <Completed /> : null}
    </Modal>
  )
}

export default VouchersCsvUpload
