import { Form, Select } from 'antd'
import React from 'react'

import { PromotionTargetingGroup } from '../../apollo/generated/api'
import { MappedTargetGroups } from '../../constants/AppConstants'

interface Props {
  label?: string
  required?: boolean
}

function TargetGroupSelect({ label, required }: Props): JSX.Element {
  return (
    <Form.Item label={label} name="targetingGroup" rules={[{ required, message: 'Please select a targeting group.' }]}>
      <Select
        style={{ width: '100%' }}
        placeholder="Target group"
        data-testid="target-group"
        optionFilterProp="children"
        allowClear
        showSearch
      >
        {Object.values(PromotionTargetingGroup).map(group => (
          <Select.Option value={group} key={group}>
            {MappedTargetGroups[group]}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

TargetGroupSelect.defaultProps = {
  label: 'Select a target group',
  required: false,
}

export default TargetGroupSelect
