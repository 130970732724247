import { Button, Card, Col, Form, Input, Row } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { Store } from 'antd/lib/form/interface'
import React, { useState } from 'react'
import styled from 'styled-components'

import { ScreenBreakpoints } from '../../../constants/LayoutConstants'
import BrandDropdown from '../../BrandDropdown'
import CountryDropdown from '../../CountryDropdown'
import { PromotionTypeSelect, TargetGroupSelect, ValueTypeSelect } from '../../Inputs'
import BooleanSelect from '../../Inputs/BooleanSelect'

import VouchersAdvancedSearchToggle from './VouchersAdvancedSearchToggle'

interface Props {
  form?: FormInstance
  onSubmit?: (data: Store) => void
}

function VouchersSearchForm({ form, onSubmit }: Props): JSX.Element {
  const [showsAdvancedFilters, setShowsAdvancedFilters] = useState(false)

  const toggleAdvancedFilters = () => {
    setShowsAdvancedFilters((state: boolean) => !state)
  }

  return (
    <Card
      actions={[<VouchersAdvancedSearchToggle isEnabled={showsAdvancedFilters} onClick={toggleAdvancedFilters} />]}
      style={{ marginBottom: 25 }}
    >
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <Row gutter={16}>
          <Col xs={24} md={20}>
            <Row gutter={16} align="top">
              <Col xs={24} md={12} lg={6}>
                <CountryDropdown />
              </Col>

              <Col xs={24} md={12} lg={6}>
                <BrandDropdown />
              </Col>

              <Col xs={24} md={12} lg={6}>
                <Form.Item name="code" label="Code">
                  <Input data-testid="code-input" />
                </Form.Item>
              </Col>

              <Col xs={24} md={12} lg={6}>
                <Form.Item name="name" label="Name">
                  <Input data-testid="name-input" />
                </Form.Item>
              </Col>
            </Row>

            {showsAdvancedFilters && (
              <>
                <Row gutter={16} align="bottom">
                  <Col xs={24} md={12} lg={6}>
                    <PromotionTypeSelect label="Promotion type" />
                  </Col>

                  <Col xs={24} md={12} lg={6}>
                    <TargetGroupSelect label="Target group" />
                  </Col>

                  <Col xs={24} md={12} lg={6}>
                    <BooleanSelect label="Split Voucher" name="hasSplitVouchers" testId="has-split-vouchers" />
                  </Col>

                  <Col xs={24} md={12} lg={6}>
                    <ValueTypeSelect />
                  </Col>
                </Row>
              </>
            )}
          </Col>

          <Col xs={24} md={4}>
            <FormActionsContainer>
              <Form.Item>
                <Button htmlType="submit" data-testid="voucher-search-submit" block>
                  Search
                </Button>
              </Form.Item>
            </FormActionsContainer>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

VouchersSearchForm.defaultProps = {
  form: undefined,
  onSubmit: undefined,
}

const FormActionsContainer = styled.div`
  @media (min-width: ${ScreenBreakpoints.SM}px) {
    padding-top: 1.95rem;
  }

  @media (min-width: ${ScreenBreakpoints.MD}px) {
    padding-top: 1.85rem;
  }
`

export default VouchersSearchForm
