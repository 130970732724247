import React, { useEffect, useRef } from 'react'
import { notification } from 'antd'
import useOnlineStatus from '@rehooks/online-status'

import { CONNECTION_DOWN_MESSAGE, CONNECTION_RESTORED_MESSAGE } from '../../constants/ErrorConstants'

function noNetworkDetected(): void {
  notification.warning({
    message: 'Internet Down',
    description: CONNECTION_DOWN_MESSAGE,
  })
}

function networkDetected(): void {
  notification.success({
    message: 'Connection restored',
    description: CONNECTION_RESTORED_MESSAGE,
  })
}

function withNetworkProvider<TProps = {}>(WrappedComponent: React.ComponentType<TProps>): React.ComponentType<TProps> {
  function NetworkProvider(props: TProps): React.ReactElement<TProps> {
    const onlineStatus = useOnlineStatus()
    const firstRun = useRef(true)

    useEffect(() => {
      if (firstRun.current) {
        firstRun.current = false

        return
      }

      if (onlineStatus) {
        networkDetected()
      } else {
        noNetworkDetected()
      }
    }, [onlineStatus])

    return <WrappedComponent {...props} />
  }

  return NetworkProvider
}

export default withNetworkProvider
