import moment from 'moment'

import {
  CreatePromotionMutationVariables,
  MultiplePromotionSettingsInputType,
  UpdatePromotionMutationVariables,
  ValueType,
} from '../../apollo/generated/api'
import { PromotionFormData } from '../../components/Vouchers/types'

type FormData = Omit<PromotionFormData, 'value' | 'valueType'>
type PromotionPayload = CreatePromotionMutationVariables | UpdatePromotionMutationVariables

interface PayloadBuilderInput {
  id?: string
  formData: FormData
}

interface DataPayloadBuilderInput {
  isCreating: boolean
  isSelfCreatedVoucher: boolean
  formData: FormData
}

export function buildPromotionPayload({ id, formData }: PayloadBuilderInput): PromotionPayload {
  const isSelfCreatedVoucher = !!formData.firstOrderOnly
  const data = buildDataPayload({ isCreating: !id, isSelfCreatedVoucher, formData })

  if (!id) {
    return {
      createPromotion: { ...data },
      ...(isSelfCreatedVoucher
        ? { quantity: 1 }
        : { quantity: formData.quantity || 1, prefix: formData.codePrefix, codeLength: formData.codeLength }),
    }
  }

  return {
    id: Number(id),
    updatePromotion: { ...data },
  }
}

function buildDataPayload({ isCreating, isSelfCreatedVoucher, formData }: DataPayloadBuilderInput) {
  const firstVoucherSettings = formData.settings?.find(setting => setting.orderOrdinal === 1)
  const enforceSystemGeneratedVoucherDefaults = isCreating && !isSelfCreatedVoucher

  return {
    name: formData.name,
    description: formData.description,
    brand: formData.brand,
    country: formData.country,
    marketingCopy: formData.marketingCopy,
    platform: formData.platform || null,
    promotionType: formData.promotionType,
    targetingGroup: formData.targetingGroup,
    expirationDate: moment(formData.expirationDate).format('YYYY-MM-DD'),
    settings: buildSettingsInput(formData.settings?.filter(setting => setting.orderOrdinal !== 1)),
    value: Number(firstVoucherSettings?.value),
    valueType: firstVoucherSettings?.valueType || ValueType.Fixed,
    freeShipping: firstVoucherSettings?.freeShipping || false,
    ...(enforceSystemGeneratedVoucherDefaults
      ? { code: undefined, usageLimit: 1, usageLimitPerUser: 1 }
      : { code: formData.code, usageLimit: formData.usageLimit, usageLimitPerUser: formData.usageLimitPerUser }),
  }
}

function buildSettingsInput(
  voucherSetttings: MultiplePromotionSettingsInputType[] | null | undefined,
): MultiplePromotionSettingsInputType[] | undefined {
  if (voucherSetttings?.every(setting => setting.value === 0)) return []

  return voucherSetttings?.map(
    ({ value, valueType, freeShipping, orderOrdinal }): MultiplePromotionSettingsInputType => {
      return {
        value: Number(value),
        valueType,
        orderOrdinal,
        freeShipping,
      }
    },
  )
}
