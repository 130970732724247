import React from 'react'
import { Layout, Menu as BaseMenu, Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import styled from 'styled-components'

import { getFullYear } from '../../utils'
import * as Authentication from '../../components/Authentication'
import { Routes } from '../../constants/RoutesConstants'
import { ScreenBreakpoints } from '../../constants/LayoutConstants'

type Menus = Array<{
  link: string
  name: string
}>

const menus: Menus = [
  { link: Routes.VOUCHERS, name: 'Vouchers' },
  { link: Routes.REFERRALS, name: 'Referrals' },
]

function MainContainer({ children }: React.PropsWithChildren<unknown>): JSX.Element {
  const { pathname } = useLocation()
  const parentPath = pathname.split('/')[1]
  const selectedMenu = menus.find(({ link }) => link === `/${parentPath}`)?.link || ''

  return (
    <Layout style={{ minHeight: '100%' }}>
      <Header>
        <Row>
          <Col xs={4} sm={4}>
            <Logo>Chilli</Logo>
          </Col>

          <Col xs={18} sm={16}>
            <Menu selectedKeys={[selectedMenu]}>
              {menus.map(({ link, name }) => (
                <Menu.Item key={link}>
                  <Link to={link}>{name}</Link>
                </Menu.Item>
              ))}
            </Menu>
          </Col>

          <Col xs={2} sm={4}>
            <Menu style={{ textAlign: 'right' }}>
              <Menu.Item>
                <Authentication.Logout />
              </Menu.Item>
            </Menu>
          </Col>
        </Row>
      </Header>

      <Content>{children}</Content>

      <Footer>&copy; {getFullYear()} Marley Spoon</Footer>
    </Layout>
  )
}

const Header = styled(Layout.Header)`
  background: #ffffff;
  border: 1px solid #f0f0f0;
  height: auto;
`
const Logo = styled.div`
  color: #ff383c;
  font-size: 25px;
  font-family: Zhi Mang Xing;
`
const Menu = styled(BaseMenu).attrs({ mode: 'horizontal' })`
  border: transparent;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
`
const Content = styled(Layout.Content)`
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 5px 0 rgba(0, 0, 0, 0.03);
  padding: 20px;

  @media (min-width: ${ScreenBreakpoints.SM}px) {
    margin: 25px 50px;
    padding: 40px;
  }
`
const Footer = styled(Layout.Footer)`
  color: rgba(0, 0, 0, 0.5);
  font-size: 11px;
  text-align: center;
`

export default MainContainer
