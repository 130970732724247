import React from 'react'
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom'

import { getUserSession, destroyUserSession, SessionMessages } from '../../../utils'
import { Routes } from '../../../constants/RoutesConstants'
import { REDIRECT_PATH } from '../../../constants/AppConstants'
import MainContainer from '../../../containers/MainContainer'
import { UserRole } from '../../../utils/authUtils/authUtils'

const PrivateRoute: React.FC<RouteProps & { roles?: UserRole[] }> = props => {
  const { hasValidSession } = getUserSession()
  const { pathname } = useLocation()

  if (!hasValidSession()) {
    destroyUserSession()

    localStorage.setItem(REDIRECT_PATH, pathname)

    return (
      <Redirect
        to={{
          pathname: Routes.LOGIN,
          state: { error: SessionMessages.InvalidUserSession },
        }}
      />
    )
  }

  return (
    <MainContainer>
      <Route {...props}>{props.children}</Route>
    </MainContainer>
  )
}

export default PrivateRoute
