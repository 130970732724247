import React, { useState, useEffect } from 'react'
import { Form, Row, Col, InputNumber, Divider, Switch, Card, Table, Select, Checkbox, Button } from 'antd'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import { SelectValue } from 'antd/lib/select'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import humanizeString from 'humanize-string'

import { MultiplePromotionSettingsInputType, ValueType } from '../../../../apollo/generated/api'
import { PromotionFormData } from '../../types'

import SelfCreatedVoucherFlow from './SelfCreatedVoucherFlow'
import MultipleVoucherFlow from './MultipleVoucherFlow'

const { Column } = Table
const { Option } = Select
const MAX_INTEGER_SUPPORTED = 2147483647
const vouchersConfig = {
  SINGLE: 1,
  MULTIPLE: 3, // +1 to keep the first row data consistent
  LIMIT: 8,
}

enum VoucherField {
  DISCOUNT_VALUE = 'value',
  DISCOUNT_TYPE = 'valueType',
  FREE_SHIPPING = 'freeShipping',
}

const VouchersWizardCreate: React.FC<{
  data: PromotionFormData | undefined
  setData: Function
  isEditing?: boolean
}> = ({ data, setData, isEditing }) => {
  const [selfCreatedVoucher, setSelfCreatedVoucher] = useState(data?.firstOrderOnly ?? true)
  const [splitVoucher, setSplitVoucher] = useState(data?.splitVoucher ?? true)
  const [vouchersTotal, setVouchersTotal] = useState(1)

  const voucherEntry = (index?: number) => {
    const orderOrdinal = index ?? (data?.settings?.length || 0)

    return {
      orderOrdinal: orderOrdinal + 1,
      value: ('' as unknown) as number,
      valueType: ValueType.Fixed,
      freeShipping: false,
    }
  }

  const buildVouchersData = (): Array<MultiplePromotionSettingsInputType> => {
    const entries = [...Array(vouchersTotal).keys()]

    return entries.map(voucherEntry)
  }
  const toggleSelfCreatedVoucher = () => setSelfCreatedVoucher(!selfCreatedVoucher)
  const toggleSplitVoucher = () => setSplitVoucher(!splitVoucher)

  const updateVouchersData = ({
    value,
    dataIndex,
    field,
  }: {
    value: number | string | boolean | SelectValue | undefined
    dataIndex: number
    field: string
  }): void => {
    setData({
      ...data,
      settings: data?.settings?.map((data, index) => (index === dataIndex ? { ...data, [field]: value } : data)),
    })
  }

  const addVoucherData = (): void => {
    const settings = data?.settings || []

    setData({ ...data, settings: [...settings, voucherEntry()] })
  }

  const removeVoucherData = ({ dataIndex }: { dataIndex: number }): void => {
    setData({
      ...data,
      settings: data?.settings?.filter((_, index) => index !== dataIndex),
    })
  }

  const VouchersTable = (): JSX.Element => {
    return (
      <Table dataSource={data?.settings || []} pagination={false} data-testid="vouchers-table">
        <Column
          title="Ordinal"
          dataIndex="ordinal"
          key="ordinal"
          render={(value, _, index: number) => `Order #${index + 1}`}
        />

        <Column
          title="Discount Value"
          dataIndex="value"
          key="value"
          render={(value, _, index: number) => (
            <InputNumber
              onBlur={(event: React.FocusEvent<HTMLInputElement>) =>
                updateVouchersData({ value: event.target.value, dataIndex: index, field: VoucherField.DISCOUNT_VALUE })
              }
              data-testid={`voucher-value-${index}`}
              style={{ width: '100%' }}
              value={value}
            />
          )}
        />

        <Column
          title="Type of discount"
          dataIndex="valueType"
          key="valueType"
          render={(value, _, index) => (
            <Select
              onChange={(value: SelectValue) =>
                updateVouchersData({ value, dataIndex: index, field: VoucherField.DISCOUNT_TYPE })
              }
              data-testid={`discount-type-${index}`}
              defaultValue={value}
              style={{ width: '100%' }}
            >
              {Object.values(ValueType).map(type => (
                <Option value={type} key={type}>
                  {humanizeString(type)}
                </Option>
              ))}
            </Select>
          )}
        />

        <Column
          title="Free Shipping"
          dataIndex="freeShipping"
          key="freeShipping"
          align="center"
          render={(value, _, index) => (
            <Checkbox
              checked={value}
              onChange={(e: CheckboxChangeEvent) =>
                updateVouchersData({ value: e.target.checked, dataIndex: index, field: VoucherField.FREE_SHIPPING })
              }
              data-testid={`free-shipping-${index}`}
            />
          )}
        />

        {splitVoucher ? (
          <Column
            title="Actions"
            align="center"
            render={(value, _, index) => (
              <Button type="link" onClick={() => removeVoucherData({ dataIndex: index })} danger>
                <DeleteOutlined />
              </Button>
            )}
          />
        ) : null}
      </Table>
    )
  }

  useEffect(() => {
    setVouchersTotal(splitVoucher ? vouchersConfig.MULTIPLE : vouchersConfig.SINGLE)
  }, [splitVoucher])

  useEffect(() => {
    // This whole `useEffect` needs a little refactor
    // and more thinking on the logic between Split Voucher.
    if (isEditing) return

    const firstSetting = data?.settings?.[0]

    setData({ ...data, settings: splitVoucher ? [...buildVouchersData()] : [firstSetting] })
  }, [vouchersTotal])

  return (
    <>
      <Card
        title={
          <>
            Self-created voucher
            <Form.Item
              name="firstOrderOnly"
              valuePropName="checked"
              style={{ display: 'inline-block', marginTop: -4, marginBottom: 0 }}
            >
              <Switch
                defaultChecked={selfCreatedVoucher}
                onChange={toggleSelfCreatedVoucher}
                style={{ marginLeft: 10 }}
                data-testid="self-voucher-switch"
              />
            </Form.Item>
          </>
        }
      >
        {selfCreatedVoucher ? (
          <SelfCreatedVoucherFlow data={data} />
        ) : (
          <MultipleVoucherFlow data={data} isEditing={isEditing} />
        )}
      </Card>

      <Divider />

      <Card title="Usage Limits">
        <Row gutter={16}>
          <Col span={10}>
            <Form.Item
              label="Usage limit per User"
              rules={[{ required: true, message: 'Please type the usage limit per user!' }]}
              {...(selfCreatedVoucher
                ? {
                    name: 'usageLimitPerUser',
                  }
                : null)}
            >
              <InputNumber
                style={{ width: '100%' }}
                data-testid="usage-limit-per-user"
                max={MAX_INTEGER_SUPPORTED}
                min={1}
                {...(!selfCreatedVoucher
                  ? {
                      disabled: true,
                      value: 1,
                    }
                  : null)}
              />
            </Form.Item>
          </Col>

          <Col span={10}>
            {selfCreatedVoucher ? (
              <Form.Item
                name="usageLimit"
                label="Number of redemptions"
                rules={[{ required: true, message: 'Please type the number of redemptions!' }]}
              >
                <InputNumber style={{ width: '100%' }} data-testid="usage-limit" max={MAX_INTEGER_SUPPORTED} min={1} />
              </Form.Item>
            ) : (
              <Form.Item
                name="quantity"
                label="Number of vouchers to generate"
                rules={[{ required: true, message: 'Please type the number of vouchers!' }]}
              >
                <InputNumber style={{ width: '100%' }} data-testid="quantity" max={100000} />
              </Form.Item>
            )}
          </Col>
        </Row>
      </Card>

      <Divider />

      <Card
        title={
          <>
            Split voucher
            <Form.Item
              name="splitVoucher"
              valuePropName="checked"
              style={{ display: 'inline-block', marginTop: -4, marginBottom: 0 }}
            >
              <Switch
                defaultChecked={splitVoucher}
                checked={splitVoucher}
                data-testid="split-voucher"
                onChange={toggleSplitVoucher}
                style={{ marginLeft: 10 }}
              />
            </Form.Item>
          </>
        }
      >
        <VouchersTable />

        {splitVoucher && data?.settings && data.settings.length < vouchersConfig.LIMIT ? (
          <div style={{ textAlign: 'right', marginTop: 20 }}>
            <Button onClick={addVoucherData} icon={<PlusOutlined />}>
              Add
            </Button>
          </div>
        ) : null}
      </Card>
    </>
  )
}

export default VouchersWizardCreate
