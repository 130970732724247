import React from 'react'
import { PageHeader } from 'antd'

import { headerIcons } from '../../constants/IconsConstants'

import ReferralsSettingsForm from './ReferralsSettingForm'

const Referrals: React.FC = () => {
  return (
    <>
      <PageHeader title="Referrals" avatar={{ src: headerIcons.VOUCHER_ICON, shape: 'square' }} />
      <ReferralsSettingsForm />
    </>
  )
}

export default Referrals
