import React from 'react'
import { Button } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import { Routes } from '../../../constants/RoutesConstants'
import config from '../../../config'

const buildLoginUrl = (baseUrl: string, currentLocation: string): string => {
  const redirectUrl = new URL(Routes.VERCEL_OAUTH_CALLBACK, currentLocation)
  const loginUrl = new URL(baseUrl)

  loginUrl.searchParams.set('redirect_uri', redirectUrl.toString())

  return loginUrl.toString()
}

const VercelLogin: React.FC<{ loginUrl?: string; currentLocation?: string }> = ({
  loginUrl = config.vercelLoginUrl,
  currentLocation = window.location.href,
}) => {
  if (!loginUrl) {
    throw new Error("Can't render the VercelLogin button as the loginUrl is missing")
  }

  return (
    <Button
      data-qa="vercel-login-button"
      data-testid="vercel-login-button"
      type="primary"
      size="large"
      icon={<ExclamationCircleOutlined />}
      href={buildLoginUrl(loginUrl, currentLocation)}
    >
      Login with Vercel
    </Button>
  )
}

export default VercelLogin
