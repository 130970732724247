import React from 'react'
import { Card, PageHeader, Button } from 'antd'
import { Link } from 'react-router-dom'

import { headerIcons } from '../../constants/IconsConstants'
import { Routes } from '../../constants/RoutesConstants'

import VouchersSearch from './VouchersSearch'

function Vouchers(): JSX.Element {
  return (
    <>
      <Card title="Let's use Breadcrumbs! 🥐" style={{ marginBottom: 16 }}>
        <div style={{ marginLeft: 40 }}>
          <p>
            Chilli is being discontinued. But don't worry, we now have Breadcrumbs, which is Chilli with more options!
          </p>
          <p>You can still use here to consult legacy voucher.</p>
          <p>
            If you need support, please contact TEMAKI team via channel
            <a href="https://marleyspoon.slack.com/archives/C01THAV52FM"> #sp-temaki</a> on Slack.
          </p>
          <p>
            To create new campaigns, please:
            <Button style={{ backgroundColor: '#fadc5c', marginLeft: 20 }}>
              <a href={process.env.REACT_APP_BREADCRUMBS_URL}>Go to Breadcrumbs</a>
            </Button>
          </p>
        </div>
      </Card>

      <PageHeader
        title="Vouchers"
        avatar={{ src: headerIcons.VOUCHER_ICON, shape: 'square' }}
        extra={[
          <Button key="downloadCsvButton">
            <Link to={Routes.VOUCHERS_CSV_FILES}>Download .csv files</Link>
          </Button>,
        ]}
      />
      <VouchersSearch />
    </>
  )
}

export default Vouchers
