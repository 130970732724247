import React from 'react'
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom'
import { notification } from 'antd'

import MainContainer from '../../containers/MainContainer'
import GeneralError from '../../packages/GeneralError'
import { NOT_FOUND } from '../../constants/MessagesConstants'
import { Routes } from '../../constants/RoutesConstants'
import { getUserSession } from '../../utils'
import { PrivateRoute, StageRoute } from '../Routing'
import Stage from '../../constants/StagesConstants'
import VercelAuthCallback from '../Authentication/VercelAuthCallback'
import Login from '../Login'
import Vouchers from '../Vouchers'
import Referrals from '../Referrals'

const CatchAll: React.FC<RouteComponentProps & { isAuthenticated: boolean }> = ({ location, isAuthenticated }) => {
  const isHomepage = location.pathname === Routes.ROOT
  const shouldRedirect = !isAuthenticated || isHomepage

  if (shouldRedirect) {
    // Needs fixing once auth is implemented
    const redirectPath = isAuthenticated ? Routes.VOUCHERS : Routes.LOGIN

    return <Redirect to={redirectPath} />
  }

  return (
    <MainContainer>
      <GeneralError message={NOT_FOUND} status={404} />
    </MainContainer>
  )
}

const App: React.FC = () => {
  const { hasValidSession } = getUserSession()

  notification.config({ placement: 'bottomRight' })

  return (
    <div className="app">
      <Switch>
        <Route path={Routes.LOGIN} component={Login} />

        <StageRoute stage={Stage.VERCEL} path={Routes.VERCEL_OAUTH_CALLBACK} exact component={VercelAuthCallback} />

        <PrivateRoute path={Routes.DASHBOARD} render={() => <div>Dashboard</div>} exact />

        <PrivateRoute path={Routes.VOUCHERS} component={Vouchers.List} exact />
        <PrivateRoute path={Routes.REFERRALS} component={Referrals} exact />
        <PrivateRoute path={Routes.VOUCHERS_CREATE} component={Vouchers.Form} exact />
        <PrivateRoute path={Routes.VOUCHERS_CSV_FILES} component={Vouchers.CsvFiles} exact />
        <PrivateRoute path={Routes.VOUCHER_DETAIL} component={Vouchers.Details} exact />
        <PrivateRoute path={Routes.VOUCHER_EDIT} component={Vouchers.Edit} exact />

        <Route render={(props): React.ReactElement => <CatchAll {...props} isAuthenticated={hasValidSession()} />} />
      </Switch>
    </div>
  )
}

export default App
