import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date in ISO 8601 format */
  Date: string;
  /** A UTC date & time with time difference information */
  DateTime: string;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: string;
  /** Represents untyped JSON */
  JSON: string;
  /** A Time representation with HH:MM */
  Time: string;
  _Any: any;
};

/** Add On related information */
export type AddOn = {
  __typename?: 'AddOn';
  category?: Maybe<AddOnCategory>;
  /** Contents of the Add On */
  contents?: Maybe<AddOnContent>;
  /** Description of the Add On */
  description?: Maybe<Scalars['String']>;
  /** Document attached to the Add On */
  document?: Maybe<Document>;
  /** Unique Identifier for the Add On */
  id: Scalars['Int'];
  /** Image for the Add On in the given size */
  image?: Maybe<Image>;
  /** True if this addon is a fruit box */
  isFruitBox: Scalars['Boolean'];
  /** Name of the Add On */
  name: Scalars['String'];
  /** Complete pricing information for the add on */
  pricing?: Maybe<Pricing>;
  /** Number of people this Add On serves */
  servings?: Maybe<Scalars['String']>;
  /** SKU of the Add On */
  sku?: Maybe<Scalars['String']>;
};


/** Add On related information */
export type AddOnImageArgs = {
  size: ImageSizeEnum;
};

/** Bundle Related content */
export type AddOnBundle = {
  __typename?: 'AddOnBundle';
  /** List of Delivery Options available for the Bundle */
  deliveryOptions?: Maybe<Array<Maybe<DeliveryOption>>>;
  /** ID of the Bundle */
  id?: Maybe<Scalars['Int']>;
  /** The graphql_name of the Bundle */
  name?: Maybe<Scalars['String']>;
  /** List of Recipes composing the Add On */
  recipes?: Maybe<Array<Maybe<Recipe>>>;
};


/** Bundle Related content */
export type AddOnBundleDeliveryOptionsArgs = {
  zipcode: Scalars['String'];
};

/** Add On Category related information */
export type AddOnCategory = {
  __typename?: 'AddOnCategory';
  /** Unique Identifier for the category */
  id: Scalars['Int'];
  /** Name of the category */
  name: Scalars['String'];
};

/** Add On contents */
export type AddOnContent = {
  __typename?: 'AddOnContent';
  /** List of Bundles composing the Add On */
  bundles?: Maybe<Array<Maybe<AddOnBundle>>>;
};

/** Address related information */
export type Address = {
  __typename?: 'Address';
  /** The city of the recipient */
  city?: Maybe<Scalars['String']>;
  /** The company of the recipient */
  company?: Maybe<Scalars['String']>;
  /** The delivery code of the recipient */
  deliveryCode?: Maybe<Scalars['String']>;
  /** The first name of the recipient */
  firstName?: Maybe<Scalars['String']>;
  /** Complete first line of the address, eg: Berliner Strasse 39 */
  fullStreetAddress?: Maybe<Scalars['String']>;
  /** The house number of the recipient */
  houseNumber?: Maybe<Scalars['String']>;
  /** ID of the Address */
  id: Scalars['Int'];
  /** The instructions of the recipient */
  instructions?: Maybe<Scalars['String']>;
  /** The last name of the recipient */
  lastName?: Maybe<Scalars['String']>;
  /** The first address line of the recipient */
  line1?: Maybe<Scalars['String']>;
  /** The second address line of the recipient */
  line2?: Maybe<Scalars['String']>;
  /** The phone of the recipient */
  phone?: Maybe<Scalars['String']>;
  /** Information about the state of the recipient */
  state?: Maybe<State>;
  /** The type of the recipient */
  type?: Maybe<Scalars['String']>;
  /** The zipcode of the recipient */
  zipcode?: Maybe<Scalars['String']>;
};

/**
 * [Admin Only] Meta information about specific requirements for creating/editing
 * an address in the context of this user (e.g. if the address requires a 'state') 
 */
export type AddressMeta = {
  __typename?: 'AddressMeta';
  /** If true, the address requires a stateId (see customer.country.states for available options) */
  isStateIdRequired: Scalars['Boolean'];
  /** If true, the address requires a type */
  isTypeRequired: Scalars['Boolean'];
};

/**
 * An Adjustment tracks an adjustment to the price of
 *                  an Order, an order's Line Item, or an order's Shipments.
 */
export type Adjustment = {
  __typename?: 'Adjustment';
  /** Amount of the Adjustment */
  amount: Scalars['Float'];
  /** ID of the Adjustment */
  id: Scalars['Int'];
  /** Description of the Adjustment */
  label: Scalars['String'];
  /** What gave origin to the adjustment, can be a Promotion or TaxRate */
  source?: Maybe<AdjustmentSource>;
  /** The adjustment's source type */
  sourceType?: Maybe<Scalars['String']>;
  /** Current state of the Adjustment */
  state: AdjustmentStateEnum;
  /** Type of the Adjustment */
  type: AdjustmentTypeEnum;
};

/** Possible Adjustment source object details */
export type AdjustmentSource = Promotion | TaxRate;

/** List of possible Adjustment States */
export enum AdjustmentStateEnum {
  /** Adjustment is closed */
  Closed = 'CLOSED',
  /** Adjustment is opened */
  Opened = 'OPENED'
}

/** List of possible Adjustment Types */
export enum AdjustmentTypeEnum {
  /** Adjustment from a Line Item */
  LineItem = 'LINE_ITEM',
  /** Adjustment from an Order */
  Order = 'ORDER',
  /** Adjustment from Add-On */
  OrderAddOn = 'ORDER_ADD_ON',
  /** Adjustment from a Shipment */
  Shipment = 'SHIPMENT'
}

/** Adyen Key payment method details */
export type AdyenKey = {
  __typename?: 'AdyenKey';
  /** Payment token reference */
  pspReference?: Maybe<Scalars['String']>;
};

/** Allergen related information */
export type Allergen = {
  __typename?: 'Allergen';
  /** Recipe's shipped ingredients the allergen is contained in. */
  containedIn: Array<Ingredient>;
  /** Image of the allergen. */
  image?: Maybe<Image>;
  /** A runtime generated identifier for the allergen. */
  key: Scalars['String'];
  /** Allergen name. */
  name: Scalars['String'];
};


/** Allergen related information */
export type AllergenImageArgs = {
  size?: Maybe<ImageSizeEnum>;
};

/** AssumedIngredient related information */
export type AssumedIngredient = {
  __typename?: 'AssumedIngredient';
  /** Assumed ingredient name */
  name?: Maybe<Scalars['String']>;
};

/** Bank Account payment details */
export type BankAccount = {
  __typename?: 'BankAccount';
  /** Bank Identifier Code */
  bic: Scalars['String'];
  /** International Bank Account Number */
  iban: Scalars['String'];
  /** The bank holder's name */
  name: Scalars['String'];
};

/** List of available Brands */
export enum BrandEnum {
  /** Dinnerly */
  Dn = 'DN',
  /** Marley Spoon */
  Ms = 'MS'
}

/** Chef related information */
export type Chef = {
  __typename?: 'Chef';
  /** Avatar image for the chef in the given size */
  avatar?: Maybe<Image>;
  /** Biography of the chef */
  bio: Scalars['String'];
  /** ID of the chef */
  id: Scalars['Int'];
  /** Name of the chef */
  name: Scalars['String'];
  /** Quote of the chef */
  quote: Scalars['String'];
};


/** Chef related information */
export type ChefAvatarArgs = {
  size: ImageSizeEnum;
};

/** Country related information */
export type Country = {
  __typename?: 'Country';
  /** ID for the country */
  id: Scalars['Int'];
  /** Country iso2 code */
  iso: CountryEnum;
  /** The country name */
  name?: Maybe<Scalars['String']>;
  /** List of states belonging to the country */
  states: Array<State>;
};

/** List of available Countries */
export enum CountryEnum {
  /** Austria */
  At = 'AT',
  /** Australia */
  Au = 'AU',
  /** Belgium */
  Be = 'BE',
  /** Germany */
  De = 'DE',
  /** Denmark */
  Dk = 'DK',
  /** Great Britain */
  Gb = 'GB',
  /** Netherlands */
  Nl = 'NL',
  /** Sweden */
  Se = 'SE',
  /** United States of America */
  Us = 'US'
}

/** The information of the promotion to be created */
export type CreatePromotionInputType = {
  /** Brand linked to the promotion */
  brand: BrandEnum;
  /** Voucher code */
  code?: Maybe<Scalars['String']>;
  /** Country linked to the promotion */
  country: CountryEnum;
  /** Additional info for the promotion */
  description?: Maybe<Scalars['String']>;
  /** Date when the promotion expires */
  expirationDate?: Maybe<Scalars['Date']>;
  /** If the Voucher is applicable to first order only */
  firstOrderOnly?: Maybe<Scalars['Boolean']>;
  /** If the promotion offers free shipping */
  freeShipping?: Maybe<Scalars['Boolean']>;
  /**
   * Message displayed on the frontend when you apply the voucher, if left blank
   * will be automatically filled depending on the promotion type
   */
  marketingCopy?: Maybe<Scalars['String']>;
  /** If the promotion affects multiple orders */
  multiple?: Maybe<Scalars['Boolean']>;
  /** Promotion/Campaign title */
  name?: Maybe<Scalars['String']>;
  /** Allowed platform */
  platform?: Maybe<PromotionPlatformType>;
  /** Type of promotion */
  promotionType: PromotionType;
  /** Config for promotions to affect the N following orders. */
  settings?: Maybe<Array<MultiplePromotionSettingsInputType>>;
  /** Date when the promotion is valid */
  startDate?: Maybe<Scalars['Date']>;
  /** Which group to target */
  targetingGroup: PromotionTargetingGroup;
  /** Amount of times the voucher can be used */
  usageLimit?: Maybe<Scalars['Int']>;
  /** Amount of times the voucher can be used per user */
  usageLimitPerUser?: Maybe<Scalars['Int']>;
  /** Amount of the promocode (fixed or percentage) */
  value: Scalars['Float'];
  /** Amount of the promocode (fixed or percentage) */
  valueType: ValueType;
};

/** CreditCard payment details */
export type CreditCard = {
  __typename?: 'CreditCard';
  /** Type of card */
  cardType?: Maybe<Scalars['String']>;
  /** Last 4 digits on the card */
  lastDigits?: Maybe<Scalars['String']>;
  /** Month of expiration */
  month?: Maybe<Scalars['String']>;
  /** Card holder name  */
  name?: Maybe<Scalars['String']>;
  /** Year of expiration */
  year?: Maybe<Scalars['String']>;
};

/** Credit Reason related information */
export type CreditReason = {
  __typename?: 'CreditReason';
  /** Unique Identifier for the Credit Reason */
  id: Scalars['Int'];
  /** Name of the Credit Reason */
  name: Scalars['String'];
};

/** Possible values Currency */
export enum CurrencyEnum {
  /** AU Dollar */
  Aud = 'AUD',
  /** Danish Krone */
  Dkk = 'DKK',
  /** Euro */
  Eur = 'EUR',
  /** Great Britan Pound */
  Gbp = 'GBP',
  /** Swedish Krone */
  Sek = 'SEK',
  /** US Dollar */
  Usd = 'USD'
}

/** Customer related information */
export type Customer = {
  __typename?: 'Customer';
  /** Information about the account credit of the customer */
  accountCredit?: Maybe<Scalars['Float']>;
  /** Active payment source */
  activePaymentSource?: Maybe<PaymentSource>;
  /** All add-ons available to the customer */
  addOns: Array<AddOn>;
  /** The default address of the customer */
  address?: Maybe<Address>;
  /**
   * [Admin Only] Meta information about specific requirements for creating/editing
   * an address in the context of this user (e.g. if the address requires has a 'state') 
   */
  addressMeta: AddressMeta;
  /** The list of addresses associated with the customer */
  addresses: Array<CustomerAddress>;
  allOrders: OrderConnection;
  /** The set of actions that can be executed over the current customer */
  availableActions: Array<CustomerAvailableAction>;
  /** Currently available specials for the customer */
  availableSpecials?: Maybe<Array<Special>>;
  /** Bookmarked recipes */
  bookmarkedRecipes?: Maybe<Array<UserRecipeBookmark>>;
  /** Customer brand */
  brand: BrandEnum;
  /** The country of the customer */
  country?: Maybe<Country>;
  /** List of possible reasons to add/remove credit from the customer's account. */
  creditReasons: Array<CreditReason>;
  /** Credits given to a user */
  credits: Array<UserCredit>;
  /** Possible delivery options for creating/updating a customer order, taking into account exceptions in the next 2 months */
  deliveryOptions: Array<DeliveryOption>;
  /** Delivery stats */
  deliveryStats?: Maybe<DeliveryStats>;
  /** The Email of the customer */
  email?: Maybe<Scalars['String']>;
  /** User events related with account changes */
  events: Array<UserEvent>;
  /** List of features and their availability for the customer */
  features?: Maybe<Array<Feature>>;
  /** Final delivery for the customer */
  finalDelivery?: Maybe<Delivery>;
  /** The name of the customer */
  firstName?: Maybe<Scalars['String']>;
  /** Information about the food preferences of the customer */
  foodPreferences?: Maybe<UserFoodPreferences>;
  /** id */
  id?: Maybe<Scalars['Int']>;
  /** If the customer is vegetarian */
  isVegetarian?: Maybe<Scalars['Boolean']>;
  /** Marketing messages sent to the customer in the past month */
  marketingMessages: Array<UserMarketingMessage>;
  /** Information about the membership of the customer */
  membership?: Maybe<Membership>;
  /** Next delivery for the customer */
  nextDelivery?: Maybe<NextDelivery>;
  /**
   * List of all the available notification settings. Each notification setting can
   * be enabled/disabled for the current customer.
   */
  notificationSettings: Array<CustomerNotificationSetting>;
  /** List of orders of the customer */
  orders?: Maybe<Array<Order>>;
  /** @deprecated Please, use all_orders instead */
  paginatedOrders: PaginatedOrdersResponse;
  /** All user's payment sources */
  paymentSources: Array<PaymentSource>;
  /**
   * List of payable pending orders of the customer
   * @deprecated Please use orders(scope: PENDING) instead
   */
  pendingOrders?: Maybe<Array<Order>>;
  /** Information about the referral feature, such as availability and sent referrals */
  referrals?: Maybe<ReferralFeature>;
  /** Taste profile steps and data for the customer */
  tasteProfileWizard?: Maybe<TasteProfileWizard>;
  tasteProfileWizardStep?: Maybe<Step>;
  /** The customer's timezone */
  timezone?: Maybe<Scalars['String']>;
  /** Opt-ins the customer to trusted payments */
  trusted: Scalars['Boolean'];
  /**
   * Upcoming user promotion settings
   * @deprecated Use Order -> UpcomingPromotion
   */
  upcomingPromotionSettings: Array<UserPromotionSettings>;
  /** Information about the plan of the customer */
  userPlan?: Maybe<UserPlan>;
  /** Type of the user */
  userType?: Maybe<UserType>;
  /** Information about the visit of the customer */
  visit?: Maybe<Visit>;
};


/** Customer related information */
export type CustomerAllOrdersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Customer related information */
export type CustomerDeliveryOptionsArgs = {
  includeDatesPastCutoff?: Maybe<Scalars['Boolean']>;
  zipcode?: Maybe<Scalars['String']>;
};


/** Customer related information */
export type CustomerOrdersArgs = {
  after?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
  scope?: Maybe<OrderScopeEnum>;
  sortBy?: Maybe<OrderSortBy>;
  sortDirection?: Maybe<SortDirection>;
};


/** Customer related information */
export type CustomerPaginatedOrdersArgs = {
  page: Scalars['Int'];
  perPage?: Maybe<Scalars['Int']>;
};


/** Customer related information */
export type CustomerTasteProfileWizardStepArgs = {
  identifier: TasteProfilePreferenceEnum;
};

/** The set of actions that, given the customer state, can be executed upon it */
export enum CustomerAction {
  /** The customer is in an active membership so orders can be created */
  CreateOrder = 'CREATE_ORDER'
}

/** Customer address association */
export type CustomerAddress = {
  __typename?: 'CustomerAddress';
  /** The actual address information */
  address: Address;
  /** The customer the address is associated with */
  customer: Customer;
  /** A descriptive name for the address (e.g. 'Home Address' or 'Office') */
  description?: Maybe<Scalars['String']>;
  /** The customer address identifier */
  id: Scalars['Int'];
  /** True if the address is marked the default user address */
  isDefault: Scalars['Boolean'];
};

/** Given the current customer state, the set of actions that can be executed */
export type CustomerAvailableAction = {
  __typename?: 'CustomerAvailableAction';
  /** The action that can be executed upon the current customer */
  action: CustomerAction;
  /** A small description of the intent of the action */
  description: Scalars['String'];
};

/**
 * Determines whether a customer wants to receive automatic notifications when
 * certain events occur (e.g. an automated email when an order is skipped)
 */
export type CustomerNotificationSetting = {
  __typename?: 'CustomerNotificationSetting';
  /** Is the notification type enabled for the customer? */
  enabled: Scalars['Boolean'];
  /** Unique identifier for this user setting */
  id: Scalars['Int'];
  /** A (non-localized) Human readable label for the notification type. */
  label: Scalars['String'];
  /** The type of the notification */
  type: NotificationTypeEnum;
};



/** Delivery related information */
export type Delivery = {
  __typename?: 'Delivery';
  /** Date of delivery */
  date?: Maybe<Scalars['Date']>;
  /** The description of the delivery */
  description?: Maybe<Scalars['String']>;
  /** The Id of the delivery */
  id: Scalars['Int'];
  /** The intended date of the delivery */
  intendedDate?: Maybe<Scalars['Date']>;
  /** Is the delivery exceptional? */
  isExceptional?: Maybe<Scalars['Boolean']>;
  /** List of all delivery options to the products of this delivery */
  options?: Maybe<Array<DeliveryOption>>;
  /** List of shipments from the delivery */
  shipments?: Maybe<Array<Shipment>>;
  /** The current time slot of the delivery */
  timeSlot?: Maybe<TimeSlot>;
};


/** Delivery related information */
export type DeliveryOptionsArgs = {
  zipcode?: Maybe<Scalars['String']>;
};

/** Delivery Option related information */
export type DeliveryOption = DeliveryOptionInterface & {
  __typename?: 'DeliveryOption';
  /** The day no further changes can be made to orders (excluding VIP changes) */
  cutoffDate?: Maybe<Scalars['Date']>;
  /** Date of delivery */
  date?: Maybe<Scalars['Date']>;
  /** List of possible Time Slots */
  slots: Array<TimeSlot>;
};

/** Delivery Option related information */
export type DeliveryOptionInterface = {
  /** The day no further changes can be made to orders (excluding VIP changes) */
  cutoffDate?: Maybe<Scalars['Date']>;
  /** Date of delivery */
  date?: Maybe<Scalars['Date']>;
};

/** DeliveryStats related information */
export type DeliveryStats = {
  __typename?: 'DeliveryStats';
  /** Average Gross Price Per Box */
  averageGrossPricePerBox?: Maybe<Scalars['Float']>;
  /** Average Net Price */
  averageNetPricePerBox?: Maybe<Scalars['Float']>;
  /** Currency */
  currency?: Maybe<CurrencyEnum>;
  /** Final Delivery Date */
  finalDeliveryDate?: Maybe<Scalars['Date']>;
  /** First Delivery Date */
  firstDeliveryDate?: Maybe<Scalars['Date']>;
  /** Last Delivery Date */
  lastDeliveryDate?: Maybe<Scalars['Date']>;
  /** Next Delivery Date */
  nextDeliveryDate?: Maybe<Scalars['Date']>;
  /** Number of regular orders */
  numberOfOrders?: Maybe<Scalars['Int']>;
  /** Number of special orders */
  numberOfSpecialOrders?: Maybe<Scalars['Int']>;
  /** Order Frequency of the customer */
  orderFrequency?: Maybe<Scalars['Float']>;
  /** Total Revenue */
  totalRevenue?: Maybe<Scalars['Float']>;
  /** Total Voucher Amount */
  totalVoucherAmount?: Maybe<Scalars['Float']>;
};

/** Direct Debit payment details */
export type DirectDebit = {
  __typename?: 'DirectDebit';
  /** Bank Identifier Code */
  bic?: Maybe<Scalars['String']>;
  /** International Bank Account Number */
  iban?: Maybe<Scalars['String']>;
  /** Direct Debit Name */
  name?: Maybe<Scalars['String']>;
};

/** Document related information */
export type Document = {
  __typename?: 'Document';
  /** URL of the document */
  url?: Maybe<Scalars['String']>;
};

/** List of available Error codes */
export enum ErrorCodeEnum {
  /** Failed to add fruitbox to the customer user plan */
  AddFruitboxToCustomerFailed = 'ADD_FRUITBOX_TO_CUSTOMER_FAILED',
  /** Failed to delete adjustment */
  AdjustmentDeleteFailed = 'ADJUSTMENT_DELETE_FAILED',
  /** The adjustment was not found */
  AdjustmentNotFound = 'ADJUSTMENT_NOT_FOUND',
  /** There was an error creating the admin user */
  AdminUserCreationFailed = 'ADMIN_USER_CREATION_FAILED',
  /** The provided admin user couldn't be found */
  AdminUserNotFound = 'ADMIN_USER_NOT_FOUND',
  /** Failed to anonymize customer and remove it from Iterable */
  AnonymizeCustomerFailed = 'ANONYMIZE_CUSTOMER_FAILED',
  /** The credit reason is invalid */
  CreditReasonNotFound = 'CREDIT_REASON_NOT_FOUND',
  /** Cannot delete the customer default address */
  CustomerAddressCannotDeleteDefault = 'CUSTOMER_ADDRESS_CANNOT_DELETE_DEFAULT',
  /** Failed to delete a customer address */
  CustomerAddressDeleteFailed = 'CUSTOMER_ADDRESS_DELETE_FAILED',
  /** Address not found for customer */
  CustomerAddressNotFound = 'CUSTOMER_ADDRESS_NOT_FOUND',
  /** Customer not found */
  CustomerNotFound = 'CUSTOMER_NOT_FOUND',
  /** Failed to update user credit */
  CustomerUpdateCreditFailed = 'CUSTOMER_UPDATE_CREDIT_FAILED',
  /** Failed to delete lead with the given params */
  DeleteLeadFailed = 'DELETE_LEAD_FAILED',
  /** Couldn't delete promotion */
  DeletePromotionFailed = 'DELETE_PROMOTION_FAILED',
  /** Invalid delivery option */
  DeliveryOptionNotFound = 'DELIVERY_OPTION_NOT_FOUND',
  /** Failed to flag order for quality control */
  FlagForQualityControlFailed = 'FLAG_FOR_QUALITY_CONTROL_FAILED',
  /** Failed to find cancellation reason for fruitbox */
  FruitboxCancelReasonNotFound = 'FRUITBOX_CANCEL_REASON_NOT_FOUND',
  /** There fruitbox addon is not available for this user */
  FruitboxNotAvailableForCustomer = 'FRUITBOX_NOT_AVAILABLE_FOR_CUSTOMER',
  /** The provided address is invalid */
  InvalidAddress = 'INVALID_ADDRESS',
  /** The provided amount is invalid */
  InvalidAmount = 'INVALID_AMOUNT',
  /** Cancel membership reason not found or is not active */
  InvalidCancelMembershipReason = 'INVALID_CANCEL_MEMBERSHIP_REASON',
  /** Credit reason is not valid or is inactive */
  InvalidCreditReason = 'INVALID_CREDIT_REASON',
  /** The provided email is invalid */
  InvalidEmail = 'INVALID_EMAIL',
  /** An invalid notification type was provided */
  InvalidNotificationType = 'INVALID_NOTIFICATION_TYPE',
  /** The operation can't be executed because of the order's state */
  InvalidOrderState = 'INVALID_ORDER_STATE',
  /** Failed to find lead with given params */
  LeadNotFound = 'LEAD_NOT_FOUND',
  /** Failed to mass credit the given orders */
  MassCreditFailed = 'MASS_CREDIT_FAILED',
  /** The refund can't be issued because there isn't a negative adjustment associated with the order */
  NegativeAdjustmentNotFound = 'NEGATIVE_ADJUSTMENT_NOT_FOUND',
  /** The refund can't be issued because the order isn't marked as complete */
  NonCompleteOrder = 'NON_COMPLETE_ORDER',
  /** The refund can be issued because there's no complete payment for the order */
  NonCompletePayment = 'NON_COMPLETE_PAYMENT',
  /** Not all of the notification types were provided */
  NotificationTypeMissing = 'NOTIFICATION_TYPE_MISSING',
  /** Failed to add fruitbox to order */
  OrderAddFruitboxFailed = 'ORDER_ADD_FRUITBOX_FAILED',
  /** Order is already paid */
  OrderAlreadyPaid = 'ORDER_ALREADY_PAID',
  /** Failed to apply promotion to the order */
  OrderApplyPromotionFailed = 'ORDER_APPLY_PROMOTION_FAILED',
  /** Invalid input when applying promotion */
  OrderApplyPromotionInvalidInput = 'ORDER_APPLY_PROMOTION_INVALID_INPUT',
  /** There was an error while cancelling the order */
  OrderCancellationFailed = 'ORDER_CANCELLATION_FAILED',
  /** Failed to mark the order as complete */
  OrderCompletionFailed = 'ORDER_COMPLETION_FAILED',
  /** There was an error while creating the order */
  OrderCreationFailed = 'ORDER_CREATION_FAILED',
  /** Can't update order delivery */
  OrderDeliveryUpdateFailed = 'ORDER_DELIVERY_UPDATE_FAILED',
  /** Specified order does not exist */
  OrderNotFound = 'ORDER_NOT_FOUND',
  /** Failed to pay for order */
  OrderPayFailed = 'ORDER_PAY_FAILED',
  /** Failed to regenerate order */
  OrderRegeneratedFailed = 'ORDER_REGENERATED_FAILED',
  /** Failed to regenerate order */
  OrderRegenerationFailed = 'ORDER_REGENERATION_FAILED',
  /** Failed to remove fruitbox from order */
  OrderRemoveFruitboxFailed = 'ORDER_REMOVE_FRUITBOX_FAILED',
  /** Failed to find reason to remove fruitbox from order */
  OrderRemoveFruitboxReasonNotFound = 'ORDER_REMOVE_FRUITBOX_REASON_NOT_FOUND',
  /** Cannot update order after delivery date */
  OrderUpdateFailedAfterDelivery = 'ORDER_UPDATE_FAILED_AFTER_DELIVERY',
  /** Failed to update order recipes */
  OrderUpdateRecipesFailed = 'ORDER_UPDATE_RECIPES_FAILED',
  /** Failed to pause customer membership */
  PauseMembershipFailed = 'PAUSE_MEMBERSHIP_FAILED',
  /** The payment doesn't exist */
  PaymentNotFound = 'PAYMENT_NOT_FOUND',
  /** Payment not refundable */
  PaymentRefundFailed = 'PAYMENT_REFUND_FAILED',
  /** Couldn't find promotion */
  PromotionNotFound = 'PROMOTION_NOT_FOUND',
  /** Couldn't find referral program settings for given arguments */
  ReferralProgramSettingsNotFound = 'REFERRAL_PROGRAM_SETTINGS_NOT_FOUND',
  /** The refund can't be issued because the sum of adjustment values is greater than the amount paid */
  RefundAdjustmentOverflow = 'REFUND_ADJUSTMENT_OVERFLOW',
  /** Failed to resume customer membership */
  ResumeMembershipFailed = 'RESUME_MEMBERSHIP_FAILED',
  /** Failed to stop customer membership */
  StopMembershipFailed = 'STOP_MEMBERSHIP_FAILED',
  /** Failed to unlock account */
  UnlockAccountFailed = 'UNLOCK_ACCOUNT_FAILED',
  /** Partial refunds are not supported by the current payment method */
  UnsupportedPartialRefund = 'UNSUPPORTED_PARTIAL_REFUND',
  /** There was an unexpected error updating the customer notification types */
  UpdateCustomerNotificationTypesFailed = 'UPDATE_CUSTOMER_NOTIFICATION_TYPES_FAILED',
  /** Failed to update order contents */
  UpdateOrderContentsFailed = 'UPDATE_ORDER_CONTENTS_FAILED',
  /** Failed to update the plan */
  UpdatePlanFailed = 'UPDATE_PLAN_FAILED',
  /** Failed to update promotion with the given params */
  UpdatePromotionFailed = 'UPDATE_PROMOTION_FAILED',
  /** Failed to update referral program settings */
  UpdateReferralProgramSettingsFailed = 'UPDATE_REFERRAL_PROGRAM_SETTINGS_FAILED',
  /** There was an error updating the customer taste profile */
  UpdateTasteProfileFailed = 'UPDATE_TASTE_PROFILE_FAILED',
  /** There was an error voiding the customer payment source */
  VoidPaymentSourceFailed = 'VOID_PAYMENT_SOURCE_FAILED'
}

/** Reason why the event occurred */
export type EventReason = {
  __typename?: 'EventReason';
  /** The reason that lead to the creation of the event */
  active: Scalars['Boolean'];
  /** Children of this event */
  children: Array<EventReason>;
  /** Humanized form for the event key */
  displayName: Scalars['String'];
  /** The type of event as a Enum */
  enumValue: EventTypeEnum;
  /** Id for the event reason */
  id: Scalars['Int'];
  /** The type of event */
  key: Scalars['String'];
  /** Who performed the event, user or the system */
  source?: Maybe<Scalars['String']>;
};

/** List of available EventReasons */
export enum EventReasonEnum {
  /** Admin */
  Admin = 'ADMIN',
  /** Customer service did not reply in time */
  CustomerServiceDidNotReplyInTime = 'CUSTOMER_SERVICE_DID_NOT_REPLY_IN_TIME',
  /** Delivery instructions were not followed */
  DeliveryInstructionsWereNotFollowed = 'DELIVERY_INSTRUCTIONS_WERE_NOT_FOLLOWED',
  /** Delivery problem with box */
  DeliveryProblemWithBox = 'DELIVERY_PROBLEM_WITH_BOX',
  /** Delivery slots inconvenient */
  DeliverySlotsInconvenient = 'DELIVERY_SLOTS_INCONVENIENT',
  /** Delivery times inflexible */
  DeliveryTimesInflexible = 'DELIVERY_TIMES_INFLEXIBLE',
  /** Didnt like taste */
  DidntLikeTaste = 'DIDNT_LIKE_TASTE',
  /** Didnt meet expectations */
  DidntMeetExpectations = 'DIDNT_MEET_EXPECTATIONS',
  /** Dont like recipes */
  DontLikeRecipes = 'DONT_LIKE_RECIPES',
  /** Ease */
  Ease = 'EASE',
  /** Email no reason */
  EmailNoReason = 'EMAIL_NO_REASON',
  /** Ingredients were damaged */
  IngredientsWereDamaged = 'INGREDIENTS_WERE_DAMAGED',
  /** Ingredient quality not good */
  IngredientQualtityNotGood = 'INGREDIENT_QUALTITY_NOT_GOOD',
  /** Instructions unclear */
  InstructionsUnclear = 'INSTRUCTIONS_UNCLEAR',
  /** Its not good value for money */
  ItsNotGoodValueForMoney = 'ITS_NOT_GOOD_VALUE_FOR_MONEY',
  /** Its too expensive */
  ItsTooExpensive = 'ITS_TOO_EXPENSIVE',
  /** It doesnt fit in my schedule */
  ItDoesntFitInMySchedule = 'IT_DOESNT_FIT_IN_MY_SCHEDULE',
  /** It does not fit my budget */
  ItDoesNotFitMyBudget = 'IT_DOES_NOT_FIT_MY_BUDGET',
  /** I am a customer of multiple meal kit services */
  IAmACustomerOfMultipleMealKitServices = 'I_AM_A_CUSTOMER_OF_MULTIPLE_MEAL_KIT_SERVICES',
  /** I am cooking but no one is eating my kids are picky */
  IAmCookingButNoOneIsEatingMyKidsArePicky = 'I_AM_COOKING_BUT_NO_ONE_IS_EATING_MY_KIDS_ARE_PICKY',
  /** I am moving out of your delivery area */
  IAmMovingOutOfYourDeliveryArea = 'I_AM_MOVING_OUT_OF_YOUR_DELIVERY_AREA',
  /** I am not happy with the attitude of the customer service employee */
  IAmNotHappyWithTheAttitudeOfTheCustomerServiceEmployee = 'I_AM_NOT_HAPPY_WITH_THE_ATTITUDE_OF_THE_CUSTOMER_SERVICE_EMPLOYEE',
  /** I didnt receive my box */
  IDidntReceiveMyBox = 'I_DIDNT_RECEIVE_MY_BOX',
  /** I did not get the customer support i need */
  IDidNotGetTheCustomerSupportINeed = 'I_DID_NOT_GET_THE_CUSTOMER_SUPPORT_I_NEED',
  /** I dont like the packaging */
  IDontLikeThePackaging = 'I_DONT_LIKE_THE_PACKAGING',
  /** I dont like the recipes variety */
  IDontLikeTheRecipesVariety = 'I_DONT_LIKE_THE_RECIPES_VARIETY',
  /** I dont want a subscription and would prefer single orders */
  IDontWantASubscriptionAndWouldPreferSingleOrders = 'I_DONT_WANT_A_SUBSCRIPTION_AND_WOULD_PREFER_SINGLE_ORDERS',
  /** I experienced shipping problems */
  IExperiencedShippingProblems = 'I_EXPERIENCED_SHIPPING_PROBLEMS',
  /** I had issues with the ingredients */
  IHadIssuesWithTheIngredients = 'I_HAD_ISSUES_WITH_THE_INGREDIENTS',
  /** I had website app problems */
  IHadWebsiteAppProblems = 'I_HAD_WEBSITE_APP_PROBLEMS',
  /** I need more meal planning flexibility */
  INeedMoreMealPlanningFlexibility = 'I_NEED_MORE_MEAL_PLANNING_FLEXIBILITY',
  /** I prefer another meal kit to marley spoon */
  IPreferAnotherMealKitToMarleySpoon = 'I_PREFER_ANOTHER_MEAL_KIT_TO_MARLEY_SPOON',
  /** I travel often */
  ITravelOften = 'I_TRAVEL_OFTEN',
  /** I want to try another meal kit */
  IWantToTryAnotherMealKit = 'I_WANT_TO_TRY_ANOTHER_MEAL_KIT',
  /** I want to try a different meal kit */
  IWantToTryADifferentMealKit = 'I_WANT_TO_TRY_A_DIFFERENT_MEAL_KIT',
  /** I want to try my first box before i order again */
  IWantToTryMyFirstBoxBeforeIOrderAgain = 'I_WANT_TO_TRY_MY_FIRST_BOX_BEFORE_I_ORDER_AGAIN',
  /** I want to try the product before subscribing */
  IWantToTryTheProductBeforeSubscribing = 'I_WANT_TO_TRY_THE_PRODUCT_BEFORE_SUBSCRIBING',
  /** I was missing ingredients */
  IWasMissingIngredients = 'I_WAS_MISSING_INGREDIENTS',
  /** I was reactivated without my consent */
  IWasReactivatedWithoutMyConsent = 'I_WAS_REACTIVATED_WITHOUT_MY_CONSENT',
  /** I will not able to use the service anymore */
  IWillNotAbleToUseTheServiceAnymore = 'I_WILL_NOT_ABLE_TO_USE_THE_SERVICE_ANYMORE',
  /** Marketing account */
  MarketingAccount = 'MARKETING_ACCOUNT',
  /** Missing ingredients */
  MissingIngredients = 'MISSING_INGREDIENTS',
  /** My delivery was damaged */
  MyDeliveryWasDamaged = 'MY_DELIVERY_WAS_DAMAGED',
  /** My delivery was delayed */
  MyDeliveryWasDelayed = 'MY_DELIVERY_WAS_DELAYED',
  /** My family situation is changing */
  MyFamilySituationIsChanging = 'MY_FAMILY_SITUATION_IS_CHANGING',
  /** My order was not cancelled */
  MyOrderWasNotCancelled = 'MY_ORDER_WAS_NOT_CANCELLED',
  /** My problem was not resolved */
  MyProblemWasNotResolved = 'MY_PROBLEM_WAS_NOT_RESOLVED',
  /** Skip reason survey */
  None = 'NONE',
  /** Not kid friendly */
  NotKidFriendly = 'NOT_KID_FRIENDLY',
  /** Other */
  Other = 'OTHER',
  /** Payment failure */
  PaymentFailure = 'PAYMENT_FAILURE',
  /** Prep cooking time */
  PrepCookingTime = 'PREP_COOKING_TIME',
  /** Problems with box */
  ProblemsWithBox = 'PROBLEMS_WITH_BOX',
  /** Quality of ingredients */
  QualityOfIngredients = 'QUALITY_OF_INGREDIENTS',
  /** Quality problem with box */
  QualityProblemWithBox = 'QUALITY_PROBLEM_WITH_BOX',
  /** Recent problem with box */
  RecentProblemWithBox = 'RECENT_PROBLEM_WITH_BOX',
  /** Service too inflexible */
  ServiceTooInflexible = 'SERVICE_TOO_INFLEXIBLE',
  /** Serving sizes too small */
  ServingSizesTooSmall = 'SERVING_SIZES_TOO_SMALL',
  /** Skipping more than 8 weeks */
  SkippingMoreThan_8Weeks = 'SKIPPING_MORE_THAN_8_WEEKS',
  /** Subscription too inflexible */
  SubscriptionTooInflexible = 'SUBSCRIPTION_TOO_INFLEXIBLE',
  /** Takes too much time */
  TakesTooMuchTime = 'TAKES_TOO_MUCH_TIME',
  /** Taste */
  Taste = 'TASTE',
  /** Test account */
  TestAccount = 'TEST_ACCOUNT',
  /** There is too much packaging */
  ThereIsTooMuchPackaging = 'THERE_IS_TOO_MUCH_PACKAGING',
  /** The ingredients do not fit my dietary requirements */
  TheIngredientsDoNotFitMyDietaryRequirements = 'THE_INGREDIENTS_DO_NOT_FIT_MY_DIETARY_REQUIREMENTS',
  /** The portion sizes are too small */
  ThePortionSizesAreTooSmall = 'THE_PORTION_SIZES_ARE_TOO_SMALL',
  /** The quality of ingredients was disappointing */
  TheQualityOfIngredientsWasDisappointing = 'THE_QUALITY_OF_INGREDIENTS_WAS_DISAPPOINTING',
  /** The recipes are boring */
  TheRecipesAreBoring = 'THE_RECIPES_ARE_BORING',
  /** The recipes are too exotic */
  TheRecipesAreTooExotic = 'THE_RECIPES_ARE_TOO_EXOTIC',
  /** The recipes dont vary enough */
  TheRecipesDontVaryEnough = 'THE_RECIPES_DONT_VARY_ENOUGH',
  /** The recipes take too long to cook */
  TheRecipesTakeTooLongToCook = 'THE_RECIPES_TAKE_TOO_LONG_TO_COOK',
  /** Took more time than expected */
  TookMoreTimeThanExpected = 'TOOK_MORE_TIME_THAN_EXPECTED',
  /** Too complicated to cook */
  TooComplicatedToCook = 'TOO_COMPLICATED_TO_COOK',
  /** Too expensive */
  TooExpensive = 'TOO_EXPENSIVE',
  /** Too much packaging */
  TooMuchPackaging = 'TOO_MUCH_PACKAGING',
  /** Try competitor */
  TryCompetitor = 'TRY_COMPETITOR',
  /** Try first before second */
  TryFirstBeforeSecond = 'TRY_FIRST_BEFORE_SECOND',
  /** Website app was hard to use */
  WebsiteAppWasHardToUse = 'WEBSITE_APP_WAS_HARD_TO_USE'
}

/** Reason why the event occurred */
export type EventType = {
  __typename?: 'EventType';
  /** Human readable event name */
  displayName?: Maybe<Scalars['String']>;
  /** The type of event */
  name: Scalars['String'];
};

/** List of available EventType */
export enum EventTypeEnum {
  /** Address changed */
  AddressChanged = 'ADDRESS_CHANGED',
  /** Add on added */
  AddOnAdded = 'ADD_ON_ADDED',
  /** Add on changed */
  AddOnChanged = 'ADD_ON_CHANGED',
  /** Add on removed */
  AddOnRemoved = 'ADD_ON_REMOVED',
  /** Adjustment added */
  AdjustmentAdded = 'ADJUSTMENT_ADDED',
  /** Adjustment deleted */
  AdjustmentDeleted = 'ADJUSTMENT_DELETED',
  /** Cancel order */
  CancelOrder = 'CANCEL_ORDER',
  /** Complete order */
  CompleteOrder = 'COMPLETE_ORDER',
  /** Delivery details changed */
  DeliveryDetailsChanged = 'DELIVERY_DETAILS_CHANGED',
  /** Order flagged for quality control */
  OrderFlaggedForQualityControl = 'ORDER_FLAGGED_FOR_QUALITY_CONTROL',
  /** Order in production */
  OrderInProduction = 'ORDER_IN_PRODUCTION',
  /** Order plan changed */
  OrderPlanChanged = 'ORDER_PLAN_CHANGED',
  /** Order recipes changed */
  OrderRecipesChanged = 'ORDER_RECIPES_CHANGED',
  /** Order regeneration */
  OrderRegeneration = 'ORDER_REGENERATION',
  /** Order skip fruitbox */
  OrderSkipFruitbox = 'ORDER_SKIP_FRUITBOX',
  /** Pause membership */
  PauseMembership = 'PAUSE_MEMBERSHIP',
  /** Payment information removed */
  PaymentInformationRemoved = 'PAYMENT_INFORMATION_REMOVED',
  /** Payment order */
  PaymentOrder = 'PAYMENT_ORDER',
  /** Place order */
  PlaceOrder = 'PLACE_ORDER',
  /** Plan add fruitbox */
  PlanAddFruitbox = 'PLAN_ADD_FRUITBOX',
  /** Plan skip fruitbox */
  PlanSkipFruitbox = 'PLAN_SKIP_FRUITBOX',
  /** Printed recipe cards changed */
  PrintedRecipeCardsChanged = 'PRINTED_RECIPE_CARDS_CHANGED',
  /** Reactivate membership */
  ReactivateMembership = 'REACTIVATE_MEMBERSHIP',
  /** Recipe given high rating */
  RecipeGivenHighRating = 'RECIPE_GIVEN_HIGH_RATING',
  /** Recipe given low rating */
  RecipeGivenLowRating = 'RECIPE_GIVEN_LOW_RATING',
  /** Refund order */
  RefundOrder = 'REFUND_ORDER',
  /** Require payment authentication order */
  RequirePaymentAuthenticationOrder = 'REQUIRE_PAYMENT_AUTHENTICATION_ORDER',
  /** Require payment authorization order */
  RequirePaymentAuthorizationOrder = 'REQUIRE_PAYMENT_AUTHORIZATION_ORDER',
  /** Resume membership */
  ResumeMembership = 'RESUME_MEMBERSHIP',
  /** Skip order */
  SkipOrder = 'SKIP_ORDER',
  /** Start membership */
  StartMembership = 'START_MEMBERSHIP',
  /** Stop membership */
  StopMembership = 'STOP_MEMBERSHIP',
  /** Taste profile changed */
  TasteProfileChanged = 'TASTE_PROFILE_CHANGED',
  /** Unskip order */
  UnskipOrder = 'UNSKIP_ORDER',
  /** User plan changed */
  UserPlanChanged = 'USER_PLAN_CHANGED'
}

/** A feature */
export type Feature = {
  __typename?: 'Feature';
  /** If the Feature is enabled for a customer */
  isEnabled?: Maybe<Scalars['Boolean']>;
  /** Identifier of the Feature */
  name?: Maybe<Scalars['String']>;
};

/** Food Preference Options related information */
export type FoodPreferenceOption = {
  __typename?: 'FoodPreferenceOption';
  /** Is Enabled? */
  enabled: Scalars['Boolean'];
  /** Id */
  id: Scalars['Int'];
  /** Name */
  name: Scalars['String'];
};

/** Fruitbox add on for UserPlan */
export type FruitBox = {
  __typename?: 'FruitBox';
  /** If the fruitbox is available for the user plan */
  available: Scalars['Boolean'];
  /** If the fruitbox is enabled for that user plan */
  enabled: Scalars['Boolean'];
  /** Price information */
  pricePerUnit?: Maybe<Scalars['Float']>;
  /** Number of servings per fruitbox */
  servings?: Maybe<Scalars['Int']>;
};

/** Represents the promotion files generated by bulk creation */
export type GeneratedPromotionFile = {
  __typename?: 'GeneratedPromotionFile';
  /** When the file was created */
  createdAt: Scalars['ISO8601DateTime'];
  /** Link pointing to where the file is stored */
  link?: Maybe<Scalars['String']>;
};


/** Image related information */
export type Image = {
  __typename?: 'Image';
  /** URL of the image */
  url?: Maybe<Scalars['String']>;
};

/** List of available Images Sizes */
export enum ImageSizeEnum {
  /** Large size */
  Large = 'LARGE',
  /** Medium size */
  Medium = 'MEDIUM',
  /** Small size */
  Small = 'SMALL',
  /** Thumbnail size */
  Thumb = 'THUMB'
}

/** Possible kinds of ingredients for a recipe */
export type Ingredient = AssumedIngredient | ShippedIngredient;

/** Invoice */
export type Invoice = {
  __typename?: 'Invoice';
  /** ID of the invoice */
  id?: Maybe<Scalars['Int']>;
  /** Unique record string for an invoice */
  number?: Maybe<Scalars['String']>;
};

/** Represents an invoice recipient payment details */
export type InvoiceRecipient = {
  __typename?: 'InvoiceRecipient';
  /** Id of the invoice */
  id: Scalars['Int'];
};


/** Meal attribute related information */
export type MealAttribute = {
  __typename?: 'MealAttribute';
  /** Id of the meal attribute */
  id: Scalars['Int'];
  /** Key of the meal attribute */
  key?: Maybe<Scalars['String']>;
  /** Translated meal attribute name */
  name?: Maybe<Scalars['String']>;
};

/** MealCategory related information */
export type MealCategory = {
  __typename?: 'MealCategory';
  /** Text to be displayed describing the meal category. */
  displayText?: Maybe<Scalars['String']>;
  /** meal category */
  key?: Maybe<MealType>;
};

/** Given meal and quantity information for an OrderRecipe */
export type MealQuantity = {
  __typename?: 'MealQuantity';
  /** Number of meals for an order_recipe */
  numberOfMeals: Scalars['Int'];
  /** Total number of portions for an order_recipe */
  numberOfPortions: Scalars['Int'];
};

/** List of Meal Types */
export enum MealType {
  /** Fish */
  Fish = 'FISH',
  /** Meat */
  Meat = 'MEAT',
  /** Veggie */
  Veggie = 'VEGGIE',
  /** Wine & Dessert */
  WineAndDessert = 'WINE_AND_DESSERT'
}

/** Membership related information */
export type Membership = {
  __typename?: 'Membership';
  /** Most recent cancelation date - field present only for canceled memberships */
  cancelledAt?: Maybe<Scalars['DateTime']>;
  /** Date when membership will be resumed. Only present if membership is paused */
  pausedUntil?: Maybe<Scalars['Date']>;
  /** Next possible delivery dates for a first order of membership */
  startDeliveryDates: Array<Scalars['Date']>;
  /** Start date of the Membership */
  startedAt?: Maybe<Scalars['DateTime']>;
  /** Stats of the Membership */
  stats?: Maybe<MembershipStats>;
  /** Current status of the membership */
  status?: Maybe<MembershipStatusEnum>;
};

/** MembershipStats related information */
export type MembershipStats = {
  __typename?: 'MembershipStats';
  /** Last cancellation date */
  lastCancellationDate?: Maybe<Scalars['DateTime']>;
  /** Last cancellation reason */
  lastCancellationReason?: Maybe<Scalars['String']>;
  /** Number of reactivations */
  numberOfReactivations?: Maybe<Scalars['Int']>;
};

/** List of possible Membership Statuses */
export enum MembershipStatusEnum {
  /** Membership is active */
  Active = 'ACTIVE',
  /** Not a Member */
  None = 'NONE',
  /** Membership is paused */
  Paused = 'PAUSED',
  /** Membership is stopped */
  Stopped = 'STOPPED'
}

export type MenuContentOptionsInputType = {
  /** Filter order recipes based on selected meal types */
  mealTypes?: Maybe<Array<Scalars['String']>>;
  /** Filter menu contents based on which recipe types are supported by the client */
  supportedRecipeTypes?: Maybe<Array<RecipeTypeEnum>>;
};

/** Configuration for multiple promotions to be created */
export type MultiplePromotionSetting = {
  __typename?: 'MultiplePromotionSetting';
  /** If the promotion offers free shipping */
  freeShipping: Scalars['Boolean'];
  /** Which of the n next order to affect */
  orderOrdinal: Scalars['Int'];
  /** Amount of the promocode (fixed or percentage) */
  value: Scalars['Float'];
  /** Amount of the promocode (fixed or percentage) */
  valueType: ValueType;
};

/** Configuration for multiple promotions to be created */
export type MultiplePromotionSettingsInputType = {
  /** If the promotion offers free shipping */
  freeShipping?: Maybe<Scalars['Boolean']>;
  /** Which of the n next order to affect */
  orderOrdinal: Scalars['Int'];
  /** Amount of the promocode (fixed or percentage) */
  value: Scalars['Float'];
  /** Amount of the promocode (fixed or percentage) */
  valueType: ValueType;
};

/** Error that happened during a mutation */
export type MutationError = {
  __typename?: 'MutationError';
  /** Code of the error */
  errorCode: ErrorCodeEnum;
  /** Localised human readable error message */
  message: Scalars['String'];
};

/** All errors that occurred during the mutation */
export type MutationErrorWrapper = {
  __typename?: 'MutationErrorWrapper';
  /** All errors that happened */
  errors: Array<MutationError>;
};

/** The mutation root of the ApiSchema. */
export type MutationRoot = {
  __typename?: 'MutationRoot';
  /** Create a given number of promotions */
  createPromotion?: Maybe<Scalars['Boolean']>;
  /** Delete a promotion from the system */
  deletePromotion?: Maybe<MutationErrorWrapper>;
  /** Updates a promotion */
  updatePromotion?: Maybe<PromotionResponse>;
  /** Updates the referral program config for a given country and brand */
  updateReferralProgramSettings?: Maybe<UpdateReferralProgramSettingResponse>;
};


/** The mutation root of the ApiSchema. */
export type MutationRootCreatePromotionArgs = {
  codeLength?: Maybe<Scalars['Int']>;
  createPromotion: CreatePromotionInputType;
  prefix?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
};


/** The mutation root of the ApiSchema. */
export type MutationRootDeletePromotionArgs = {
  id: Scalars['Int'];
};


/** The mutation root of the ApiSchema. */
export type MutationRootUpdatePromotionArgs = {
  id: Scalars['Int'];
  updatePromotion: CreatePromotionInputType;
};


/** The mutation root of the ApiSchema. */
export type MutationRootUpdateReferralProgramSettingsArgs = {
  referralSettings: ReferralProgramSettingInputType;
};

/** Next Delivery related information */
export type NextDelivery = {
  __typename?: 'NextDelivery';
  /** Date of delivery */
  date: Scalars['Date'];
  /** The current time slot of the delivery */
  timeSlot: UpcomingTimeSlot;
};

/** Notification */
export type Notification = {
  __typename?: 'Notification';
  /** description of the Notification */
  description: Scalars['String'];
  /** title of the Notification */
  title: Scalars['String'];
  /** type of the Notification */
  type: OrderNotificationTypeEnum;
};

/** Available notification types */
export enum NotificationTypeEnum {
  /** E-mail with a reminder to rate recipes from my last box */
  DishRatingSurvey = 'DISH_RATING_SURVEY',
  /** Emails with information about what I need for my upcoming delivery */
  KitchenPreparation = 'KITCHEN_PREPARATION',
  /** Emails with new meal options for the upcoming delivery week */
  PlacedOrderReminder = 'PLACED_ORDER_REMINDER',
  /** Emails with new meal options for skipped delivery weeks */
  SkippedOrderReminder = 'SKIPPED_ORDER_REMINDER'
}

/** Nutritional information (nutritions with insufficient data are filtered out) */
export type NutritionalInformation = {
  __typename?: 'NutritionalInformation';
  /** Nutrition key */
  key: Scalars['String'];
  /** Localized name of the nutrition */
  name: Scalars['String'];
  /** Value per 100g value */
  per100g?: Maybe<Scalars['Float']>;
  /** Value per serving */
  perPortion?: Maybe<Scalars['Float']>;
  /** Total value */
  total?: Maybe<Scalars['Float']>;
};

/** Order related information */
export type Order = {
  __typename?: 'Order';
  /** Add on categories available for this order */
  addOnCategories: Array<AddOnCategory>;
  /** List of an Order eligible Adjustments */
  adjustments?: Maybe<Array<Adjustment>>;
  /** The set of actions that can be executed over the current order */
  availableActions: Array<OrderAvailableAction>;
  /** Add ons which are available for this order */
  availableAddOns: Array<AddOn>;
  /** Bill date of the order */
  billDate?: Maybe<Scalars['Date']>;
  /** Information about the billing address */
  billingAddress?: Maybe<Address>;
  /** The brand of the order */
  brand: BrandEnum;
  /** When the order was completed */
  completedAt?: Maybe<Scalars['Date']>;
  /** Information about the contents of the order */
  contents?: Maybe<OrderContents>;
  /** The country of the order */
  country: CountryEnum;
  /** The Customer the order belongs to */
  customer?: Maybe<Customer>;
  /** Number of days until order is not longer editable */
  daysLeftToEdit?: Maybe<Scalars['Int']>;
  /** List of deliveries for the order */
  deliveries?: Maybe<Array<Delivery>>;
  /** Delivery date of the order */
  deliveryDate?: Maybe<Scalars['Date']>;
  /** Order Changes */
  history: Array<OrderEvent>;
  /** Identifier of the order */
  id: Scalars['Int'];
  /** Invoice for the order */
  invoice?: Maybe<Invoice>;
  /** Whether Market feature is enabled */
  marketAvailable: Scalars['Boolean'];
  /** Data about the available menu for the order */
  menu?: Maybe<OrderMenu>;
  /** UUID of the menu recommendation at the time of order creation */
  menuRecommendationUuid?: Maybe<Scalars['String']>;
  /** Notifications about the order */
  notifications: Array<Notification>;
  /** Number of the order */
  number: Scalars['String'];
  /** Business Status of the Order */
  orderStatus?: Maybe<OrderStatus>;
  /** Ordinal of the order */
  ordinal?: Maybe<Scalars['Int']>;
  /** The last Payment of the order */
  payment?: Maybe<Payment>;
  /** The current payment state of the order */
  paymentState?: Maybe<OrderPaymentState>;
  /** All the order payments sorted by creation date */
  payments: Array<Payment>;
  /** Information about the Plan that generated the order */
  plan?: Maybe<Plan>;
  /** The limits of the order */
  planLimits: PlanLimits;
  /** Complete pricing information for the add on */
  pricing?: Maybe<OrderPricing>;
  /** Production-related information */
  productionInfo?: Maybe<ProductionInfo>;
  /** Available refund options for the order */
  refundOptions?: Maybe<OrderRefundOptions>;
  /** Segment of the order */
  segment?: Maybe<SegmentEnum>;
  /** Information about the shipping address */
  shippingAddress?: Maybe<Address>;
  /** Internal State of the Order - do not expose this to the clients */
  state?: Maybe<OrderStateEnum>;
  /**
   * Business Status of the Order
   * @deprecated This field is deprecated, please use orderStatus instead.
   */
  status?: Maybe<OrderStatusEnum>;
  /** Promotion candidate to be used in this order */
  upcomingPromotion?: Maybe<Promotion>;
  /** Last update at */
  updatedAt: Scalars['ISO8601DateTime'];
};


/** Order related information */
export type OrderMenuArgs = {
  contentOptions?: Maybe<MenuContentOptionsInputType>;
  filter?: Maybe<Scalars['String']>;
};

/** The set of actions that, given the order state, can be executed upon it */
export enum OrderAction {
  /** The order can be cancelled */
  CancelOrder = 'CANCEL_ORDER',
  /** The order can be marked as complete */
  CompleteOrder = 'COMPLETE_ORDER',
  /** The order can be flagged for quality control */
  QualityControlOrder = 'QUALITY_CONTROL_ORDER',
  /** The order can be skipped */
  SkipOrder = 'SKIP_ORDER',
  /** The order can be unskipped */
  UnskipOrder = 'UNSKIP_ORDER'
}

/** Information related to the order('s) add on(s) */
export type OrderAddOn = {
  __typename?: 'OrderAddOn';
  /** Information about the add on of the order */
  addOn?: Maybe<AddOn>;
  /** Additional add-on tax per unit */
  additionalTaxTotal: Scalars['Float'];
  /** Flag that indicates whether this add on is available on the order delivery date */
  availableForDelivery: Scalars['Boolean'];
  /** Included add-on tax per unit */
  includedTaxTotal: Scalars['Float'];
  /** Add-on price per unit */
  price: Scalars['Float'];
  /** Quantity of the add on */
  quantity?: Maybe<Scalars['Int']>;
};

/** Given the current order state, the set of actions that can be executed */
export type OrderAvailableAction = {
  __typename?: 'OrderAvailableAction';
  /** The action that can be executed upon the current order */
  action: OrderAction;
  /** A small description of the intent of the action */
  description: Scalars['String'];
};

/** The connection type for Order. */
export type OrderConnection = {
  __typename?: 'OrderConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrderEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Order>>>;
  /** Relay-based page info */
  pageInfo: RelayPageInfo;
  totalCount: Scalars['Int'];
};

/** Information related to the contents of the order */
export type OrderContents = {
  __typename?: 'OrderContents';
  /** List of addOns of the order */
  addOns?: Maybe<Array<OrderAddOn>>;
  /** List of recipes of the order */
  recipes: Array<OrderRecipe>;
};


/** Information related to the contents of the order */
export type OrderContentsRecipesArgs = {
  state?: Maybe<RecipeState>;
};

/** An edge in a connection. */
export type OrderEdge = {
  __typename?: 'OrderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Order>;
};

/** Information related to the order events */
export type OrderEvent = {
  __typename?: 'OrderEvent';
  /** When the event was created */
  createdAt: Scalars['DateTime'];
  /** Information about the add on of the order */
  details?: Maybe<Scalars['String']>;
  /** Event Name */
  name: Scalars['String'];
  /** Who performed the event, user or the system */
  source?: Maybe<Scalars['String']>;
};

/** Available menu and options for order menu */
export type OrderMenu = {
  __typename?: 'OrderMenu';
  /** List of available filters for the menu */
  filters?: Maybe<Array<OrderMenuFilter>>;
  /** Information to help clients handle menu interactions */
  meta?: Maybe<OrderMenuMeta>;
  /** List of Recipes composing the Menu */
  recipes?: Maybe<Array<OrderRecipe>>;
};

/** Menu Filter object */
export type OrderMenuFilter = {
  __typename?: 'OrderMenuFilter';
  /** If the filter is selected as part of the menu query */
  isSelected?: Maybe<Scalars['Boolean']>;
  /** Unique identifier of the menu filter */
  key?: Maybe<Scalars['String']>;
  /** Translated name of the menu filter. */
  name?: Maybe<Scalars['String']>;
};

/** Meta data for the Order Menu */
export type OrderMenuMeta = {
  __typename?: 'OrderMenuMeta';
  /** List of quantities allowed for order's recipes */
  quantities?: Maybe<Array<OrderMenuMetaQuantity>>;
};

/** Quantity restrictions for order's recipes */
export type OrderMenuMetaQuantity = {
  __typename?: 'OrderMenuMetaQuantity';
  /** Text to be displayed describing the quantity. Ex: '2 Portions' */
  displayText?: Maybe<Scalars['String']>;
  /** Quantity */
  quantity?: Maybe<Scalars['Int']>;
};

/** List of available types of notifications for an order */
export enum OrderNotificationTypeEnum {
  /** Exceptional delivery notification for an Order */
  ExceptionalDelivery = 'EXCEPTIONAL_DELIVERY',
  /** Invalid payment notification for an Order */
  PaymentMethodInvalid = 'PAYMENT_METHOD_INVALID'
}

/** List of all possible order payment states */
export enum OrderPaymentState {
  /** The order is not fully paid yet. */
  BalanceDue = 'BALANCE_DUE',
  /** The order was successfully paid but the customer           paid more than the expected amount. */
  CreditOwed = 'CREDIT_OWED',
  /** The order payment has failed. */
  Failed = 'FAILED',
  /** The order was successfully paid. */
  Paid = 'PAID',
  /** The order payment was cancelled. */
  Void = 'VOID'
}

/** Related information for Order price */
export type OrderPriceInterface = {
  /** Shipment total with virtual pricing taken into consideration */
  shipmentTotal?: Maybe<Scalars['Float']>;
  /** Whether or not additional tax disclaimer should be displayed */
  showAdditionalTax?: Maybe<Scalars['Boolean']>;
  /** Total price to pay with virtual pricing taken into consideration */
  total?: Maybe<Scalars['Float']>;
};

/** Order Pricing related information */
export type OrderPricing = OrderPriceInterface & {
  __typename?: 'OrderPricing';
  /** Total price of all add-ons without any discount/credit */
  addOnsTotal?: Maybe<Scalars['Float']>;
  /** Sum of all additional taxes of all recipes */
  additionalTax?: Maybe<Scalars['Float']>;
  /** Currency of the order */
  currency?: Maybe<CurrencyEnum>;
  /** Total discount applied to order with virtual pricing taken into consideration */
  discount?: Maybe<Scalars['Float']>;
  /** extra fees */
  extraFees?: Maybe<Array<PriceComponent>>;
  /** Sum of all included taxes of all recipes */
  includedTax?: Maybe<Scalars['Float']>;
  /** Total price of the recipes without any discount/credit */
  itemTotal?: Maybe<Scalars['Float']>;
  /** Payment total done so far */
  paymentTotal?: Maybe<Scalars['Float']>;
  /** price breakdown */
  priceBreakdown: Array<PriceComponent>;
  /** Total price of all recipes including extra fees, without any discount/credit */
  recipesTotal?: Maybe<Scalars['Float']>;
  /** Shipment total with virtual pricing taken into consideration */
  shipmentTotal?: Maybe<Scalars['Float']>;
  /** Shipping cost of the order */
  shippingCost?: Maybe<Scalars['Float']>;
  /** Whether or not additional tax disclaimer should be displayed */
  showAdditionalTax?: Maybe<Scalars['Boolean']>;
  /** Total price to pay WITHOUT virtual pricing taken into consideration */
  subtotal?: Maybe<Scalars['Float']>;
  /** Taxes of the order */
  taxes?: Maybe<Array<Maybe<Tax>>>;
  /** Total price to pay with virtual pricing taken into consideration */
  total?: Maybe<Scalars['Float']>;
};

/** Order Recipe related information */
export type OrderRecipe = RecipeInterface & {
  __typename?: 'OrderRecipe';
  /** List of recipe allergens */
  allergens: Array<Allergen>;
  /** List of Assumed ingredients of the Recipe */
  assumedIngredients?: Maybe<Array<AssumedIngredient>>;
  /** List of meal attributes of the Recipe */
  attributes?: Maybe<Array<MealAttribute>>;
  /** Meal category of the recipe */
  category?: Maybe<MealCategory>;
  /** The recipe's chef */
  chef: Chef;
  /** Cooking Tip of the Recipe */
  cookingTip?: Maybe<Scalars['String']>;
  /** Description of a Recipe */
  description?: Maybe<Scalars['String']>;
  /** Difficulty Level of a recipe */
  difficulty?: Maybe<RecipeDifficultyEnum>;
  /** Serving time */
  duration?: Maybe<RecipeDuration>;
  /** recipe fees */
  extraFees: Array<PriceComponent>;
  /** Full name of the Recipe with title and subtitle */
  fullName: Scalars['String'];
  /** Unique Identifier for the Recipe */
  id?: Maybe<Scalars['Int']>;
  /** Image for the Recipe in the given size */
  image?: Maybe<Image>;
  /** If the recipe is bookmarked or not */
  isBookmarked: Scalars['Boolean'];
  /**
   * If the recipe is a classic or not
   * @deprecated Deprecated field
   */
  isClassic?: Maybe<Scalars['Boolean']>;
  /** If the recipe is recommended or not */
  isRecommended?: Maybe<Scalars['Boolean']>;
  /** Meal Quantity information for an OrderRecipe */
  mealQuantity: MealQuantity;
  /** Meal Type of the Recipe */
  mealType?: Maybe<MealType>;
  /** Nutritional info for the recipe */
  nutritionalInformation: Array<NutritionalInformation>;
  /** Percentage of local ingredients */
  percentageOfLocalIngredients: Scalars['Int'];
  /**
   * Quantity of the recipe
   * @deprecated Use mealQuantity.numberOfPortions instead.
   */
  quantity?: Maybe<Scalars['Int']>;
  /** Recipe Card */
  recipeCardUrl?: Maybe<Scalars['String']>;
  /** Type of a recipe */
  recipeType: RecipeTypeEnum;
  /** Segment of the Plan */
  segment?: Maybe<SegmentEnum>;
  /** Information about the Shipped Ingredients */
  shippedIngredients?: Maybe<Array<ShippedIngredient>>;
  /** Slug of the recipe */
  slug?: Maybe<Scalars['String']>;
  /** Preperations steps of the Recipe */
  steps?: Maybe<Array<RecipeStep>>;
  /** Subtitle of the Recipe */
  subtitle?: Maybe<Scalars['String']>;
  /** Title of the Recipe */
  title?: Maybe<Scalars['String']>;
  /** Detail of the Recipe */
  url?: Maybe<Scalars['String']>;
  /** users ratings for a recipe */
  userRecipeRating?: Maybe<UserRecipeRating>;
  /** List of utensils of the Recipe */
  utensils?: Maybe<Array<Utensil>>;
};


/** Order Recipe related information */
export type OrderRecipeExtraFeesArgs = {
  feeType?: Maybe<PriceComponentTypeEnum>;
};


/** Order Recipe related information */
export type OrderRecipeImageArgs = {
  size: ImageSizeEnum;
};

/** Details about the options available to refund a specific order */
export type OrderRefundOptions = {
  __typename?: 'OrderRefundOptions';
  /** Error that will be triggered if the mutation is invoked */
  error?: Maybe<MutationError>;
  /** The (inclusive) amount that can currently be refunded (null if no refund is possible). */
  refundableAmount?: Maybe<Scalars['Float']>;
};

/** The list of possible Order scopes. */
export enum OrderScopeEnum {
  /** The scope representing at home orders. */
  AtHome = 'AT_HOME',
  /** The scope representing completed orders in the last 6 months. */
  Complete = 'COMPLETE',
  /** The scope representing all pending orders. */
  Pending = 'PENDING',
  /** The scope representing all upcoming orders. */
  Upcoming = 'UPCOMING',
  /** The scope representing all orders up to today (including today). */
  UpToToday = 'UP_TO_TODAY'
}

/** List of order sort types. */
export enum OrderSortBy {
  /** Created At */
  CreatedAt = 'CREATED_AT',
  /** Delivery Date */
  DeliveryDate = 'DELIVERY_DATE',
  /** Updated At */
  UpdatedAt = 'UPDATED_AT'
}

/** List of possible Order States */
export enum OrderStateEnum {
  /** Order is cancelled */
  Cancelled = 'CANCELLED',
  /** Order is complete */
  Complete = 'COMPLETE',
  /** Order payment is in progress */
  Payment = 'PAYMENT',
  /** Order is waiting for payment authentication  */
  PaymentAuthenticationRequired = 'PAYMENT_AUTHENTICATION_REQUIRED',
  /** Order is waiting for payment authorization */
  PaymentAuthorizationRequired = 'PAYMENT_AUTHORIZATION_REQUIRED',
  /** Order is pending */
  Pending = 'PENDING',
  /** Order is placed */
  Placed = 'PLACED',
  /** Order is skipped */
  Skipped = 'SKIPPED'
}

/** Business Status of the Order */
export type OrderStatus = {
  __typename?: 'OrderStatus';
  canApplyVoucher: Scalars['Boolean'];
  canChangeDeliveryTime: Scalars['Boolean'];
  canEditBox: Scalars['Boolean'];
  canSkip: Scalars['Boolean'];
  /** If the box is being produced */
  inProduction?: Maybe<Scalars['Boolean']>;
  /** Represents the Order Status value */
  key: OrderStatusEnum;
};

/** Order Bussines Status: Lists the available order         business status recognized by the system. */
export enum OrderStatusEnum {
  /** At Home: The order is “hopefully“ at the clients delivery           place and up to the next 6 days. */
  AtHome = 'AT_HOME',
  /**
   * Editable: The order was created and the Cutoff Date/Time           has not
   * been reached. The order can still be “Skipped“ or “Cancelled“.
   */
  Editable = 'EDITABLE',
  /** Ignored: The order was never produced and remain on “non complete”           state after Cutoff Date/Time. */
  Ignore = 'IGNORE',
  /** In Progress: The order is completed and in production. */
  InProgress = 'IN_PROGRESS',
  /**
   * In Transit: The order is produced and on the way to the client.           The
   * order is delivered or about to be delivered. This states           covers the
   * delivering windows time. To the moment we don’t have           feedback from
   * carriers when the order is delivered. So we           assumed it is delivered.
   */
  InTransit = 'IN_TRANSIT',
  /** Past: The order is at client’s place and 6 days are already past. */
  Past = 'PAST',
  /**
   * Payment: The order was successfully paid but is not set           completed
   * yet. (Will be automatically set complete by the system)
   */
  Payment = 'PAYMENT',
  /** Payment Error: The order was paid but the payment failed           to be processed. */
  PaymentError = 'PAYMENT_ERROR',
  /** Skipped: The order was skipped by the user or a CC agent. */
  Skipped = 'SKIPPED',
  /** Skipped Closed: The order was skipped and the Cutoff Date/Time           passed so it can not be re-scheduled. */
  SkippedClosed = 'SKIPPED_CLOSED'
}

/** Pagination info object attached to paginated responses */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Current page */
  currentPage: Scalars['Int'];
  /** Next page to request */
  nextPage?: Maybe<Scalars['Int']>;
  /** Number of records per page */
  perPage: Scalars['Int'];
  /** Previous page to request */
  prevPage?: Maybe<Scalars['Int']>;
  /** Total number of records */
  totalCount: Scalars['Int'];
  /** Total number of pages in collection */
  totalPageCount: Scalars['Int'];
};

/** Paginate customer orders response */
export type PaginatedOrdersResponse = {
  __typename?: 'PaginatedOrdersResponse';
  orders: Array<Order>;
  /** Pagination info */
  pageInfo: PageInfo;
};

/** Payment related information */
export type Payment = {
  __typename?: 'Payment';
  /** Amount of the payment */
  amountPaid?: Maybe<Scalars['Float']>;
  /** Payment authentication URL for the payment */
  authenticationUrl?: Maybe<Scalars['String']>;
  /** Payment authorization URL for the payment */
  authorizationUrl?: Maybe<Scalars['String']>;
  /** Payment creation date */
  createdAt: Scalars['DateTime'];
  /** Payment id */
  id: Scalars['Int'];
  /** Log Entries for the payment, detailing the transaction and what could have went wrong with it */
  logEntries: Array<PaymentLogEntry>;
  /** PaymentMethod of the payment */
  method?: Maybe<PaymentMethod>;
  /** When the payment was marked as owed */
  owedAt?: Maybe<Scalars['DateTime']>;
  /** Payment Response */
  responseMessage?: Maybe<Scalars['String']>;
  /** Payment source */
  source: PaymentSource;
  /** State of the payment */
  state?: Maybe<Scalars['String']>;
  /** Payment transaction token */
  transactionToken?: Maybe<Scalars['String']>;
};

/** Possible Payment source object details */
export type PaymentCredentials = AdyenKey | BankAccount | CreditCard | DirectDebit | InvoiceRecipient | PaypalCustomer | PreapprovalKey;

/** Details on payment transaction, used for debugging by cc-experts */
export type PaymentLogEntry = {
  __typename?: 'PaymentLogEntry';
  /** Log Entry id */
  id: Scalars['Int'];
  /** The success or error message */
  message: Scalars['String'];
};

/** Method related information */
export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  /** Method id */
  id: Scalars['Int'];
  /** Payment method logos */
  logos?: Maybe<Array<Scalars['String']>>;
  /** Name of the method */
  name: Scalars['String'];
  /** Payment method type */
  type: PaymentMethodTypeEnum;
};

/** List of available payment method types */
export enum PaymentMethodTypeEnum {
  /** Bancontact */
  Bancontact = 'BANCONTACT',
  /** Credit Card */
  CreditCard = 'CREDIT_CARD',
  /** Direct Debit */
  DirectDebit = 'DIRECT_DEBIT',
  /** Ideal */
  Ideal = 'IDEAL',
  /** Invoicing */
  Invoicing = 'INVOICING',
  /** Braintree */
  Paypal = 'PAYPAL',
  /** Paypal adapative payment */
  PaypalAdaptivePayment = 'PAYPAL_ADAPTIVE_PAYMENT',
  /** Sepa */
  Sepa = 'SEPA'
}

/** Returns the payment source */
export type PaymentSource = {
  __typename?: 'PaymentSource';
  /** If the payment method is currently active */
  active: Scalars['Boolean'];
  /** Details on the payment source, card info, iban etc */
  credentials: PaymentCredentials;
  /** When the payment method was removed */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Payment method */
  method?: Maybe<PaymentMethod>;
};

/** PaypalCustomer, representing both paypal and braintree transactions */
export type PaypalCustomer = {
  __typename?: 'PaypalCustomer';
  /** Customer email */
  email?: Maybe<Scalars['String']>;
};

/** Plan related information */
export type Plan = PlanInterface & {
  __typename?: 'Plan';
  /** If the plan allows for a vegetarian only box */
  allowVegetarian?: Maybe<Scalars['Boolean']>;
  /** Display title for plan */
  displayTitle?: Maybe<Scalars['String']>;
  /** Id of Plan */
  id?: Maybe<Scalars['Int']>;
  /** If the plan should be the default selected plan */
  isDefault?: Maybe<Scalars['Boolean']>;
  /** Number of meals per week in the Plan */
  numberOfMeals?: Maybe<Scalars['Int']>;
  /** Number of portions per meal in the Plan */
  numberOfPortions?: Maybe<Scalars['Int']>;
  /** Price per portion */
  pricePerPortion?: Maybe<Scalars['Float']>;
  /** Segment of the Plan */
  segment?: Maybe<SegmentEnum>;
  /** Segment display title of the plan */
  segmentDisplayTitle?: Maybe<Scalars['String']>;
  /** Shipping cost for plan */
  shippingCost?: Maybe<Scalars['Float']>;
  /** Total price of the plan */
  total?: Maybe<Scalars['Float']>;
  /** Total price of plan including shipping cost */
  totalWithShipping?: Maybe<Scalars['Float']>;
};

/** Plan related information */
export type PlanInterface = {
  /** If the plan allows for a vegetarian only box */
  allowVegetarian?: Maybe<Scalars['Boolean']>;
  /** Display title for plan */
  displayTitle?: Maybe<Scalars['String']>;
  /** Id of Plan */
  id?: Maybe<Scalars['Int']>;
  /** If the plan should be the default selected plan */
  isDefault?: Maybe<Scalars['Boolean']>;
  /** Number of meals per week in the Plan */
  numberOfMeals?: Maybe<Scalars['Int']>;
  /** Number of portions per meal in the Plan */
  numberOfPortions?: Maybe<Scalars['Int']>;
  /** Price per portion */
  pricePerPortion?: Maybe<Scalars['Float']>;
  /** Segment of the Plan */
  segment?: Maybe<SegmentEnum>;
  /** Segment display title of the plan */
  segmentDisplayTitle?: Maybe<Scalars['String']>;
  /** Shipping cost for plan */
  shippingCost?: Maybe<Scalars['Float']>;
  /** Total price of the plan */
  total?: Maybe<Scalars['Float']>;
  /** Total price of plan including shipping cost */
  totalWithShipping?: Maybe<Scalars['Float']>;
};

/** Limits of the Order */
export type PlanLimits = {
  __typename?: 'PlanLimits';
  /** Minimun Number of Meals */
  maxMeals: Scalars['Int'];
  /** Minimun Number of Portions per Meal */
  maxPortions: Scalars['Int'];
  /** Maximal Number of Meals */
  minMeals: Scalars['Int'];
  /** Maximal Number of Portions per Meal */
  minPortions: Scalars['Int'];
};

/** Represents an the pre approval key model for payments with paypal */
export type PreapprovalKey = {
  __typename?: 'PreapprovalKey';
  /** preapproval key id */
  id: Scalars['Int'];
};

export type PriceComponent = {
  __typename?: 'PriceComponent';
  /** Customer facing description for this component */
  label?: Maybe<Scalars['String']>;
  /** Quantity of the price component */
  quantity: Scalars['Int'];
  /** Total amount for this price component */
  totalAmount: Scalars['Float'];
  /** Type of the price component */
  type: PriceComponentTypeEnum;
};

/** List of available price components */
export enum PriceComponentTypeEnum {
  /** Add ons */
  AddOns = 'ADD_ONS',
  /** Base Price */
  BasePrice = 'BASE_PRICE',
  /** Discount */
  Discount = 'DISCOUNT',
  /** Fruitbox Fee */
  FruitboxFee = 'FRUITBOX_FEE',
  /** Meals */
  Meals = 'MEALS',
  /** Premium Fee */
  PremiumFee = 'PREMIUM_FEE',
  /** Recipes */
  Recipes = 'RECIPES',
  /** Shipping Fee */
  ShippingFee = 'SHIPPING_FEE'
}

/** Generic pricing information for any object */
export type Pricing = {
  __typename?: 'Pricing';
  /** Currency */
  currency?: Maybe<CurrencyEnum>;
  /** Gross Value */
  gross?: Maybe<Scalars['Float']>;
  /** Net Value */
  net?: Maybe<Scalars['Float']>;
  /** Shipping cost */
  shipping?: Maybe<Scalars['Float']>;
  /** Included Tax value */
  tax?: Maybe<Scalars['Float']>;
  /** Total cost */
  total?: Maybe<Scalars['Float']>;
};

/** Production-related information */
export type ProductionInfo = {
  __typename?: 'ProductionInfo';
  /** Production date of the order */
  productionDate?: Maybe<Scalars['Date']>;
  /** Production date time of the order, includes TZ */
  productionDatetime?: Maybe<Scalars['DateTime']>;
  /** 4-char code for the production site */
  productionSite?: Maybe<Scalars['String']>;
  /** Amount of hours it takes to produce and ship a box after billing */
  transitTime?: Maybe<Scalars['Int']>;
};

/** A promotion record */
export type Promotion = {
  __typename?: 'Promotion';
  /** Promotion brand */
  brand: BrandEnum;
  /** The code of the promotion */
  code?: Maybe<Scalars['String']>;
  /** Promotion country */
  country: CountryEnum;
  /** The creator of the promotion, null meaning it was created by the system */
  createdBy?: Maybe<Scalars['String']>;
  /** Additional info for the promotion */
  description?: Maybe<Scalars['String']>;
  /** Value of the promotion */
  discount: Scalars['Float'];
  /** Date when the promotion expires */
  expirationDate?: Maybe<Scalars['Date']>;
  /** Voucher only applicable to first order */
  firstOrderOnly?: Maybe<Scalars['Boolean']>;
  /** If the promotion offers free shipping */
  freeShipping?: Maybe<Scalars['Boolean']>;
  /** id */
  id: Scalars['Int'];
  /** Is the promotion valid */
  isValid?: Maybe<Scalars['Boolean']>;
  /**
   * Message displayed on the frontend when you apply the voucher,if left blank
   * will be automatically filled depending on the promotion type
   */
  marketingCopy?: Maybe<Scalars['String']>;
  /** If the promotion affects multiple orders */
  multiple: Scalars['Boolean'];
  /** Promotion/Campaign title */
  name?: Maybe<Scalars['String']>;
  /** The parent promotion */
  parent?: Maybe<Promotion>;
  /** Allowed platform */
  platform?: Maybe<PromotionPlatformType>;
  /** Type of promotion */
  promotionType?: Maybe<PromotionType>;
  /** Config for promotions to affect the N following orders. */
  settings: Array<MultiplePromotionSetting>;
  /** Date when the promotion is valid */
  startDate?: Maybe<Scalars['Date']>;
  /** Which group to target */
  targetingGroup?: Maybe<PromotionTargetingGroup>;
  /** The type of the promotion identifying its channel */
  type?: Maybe<Scalars['String']>;
  /** Amount of times the voucher can be used */
  usageLimit?: Maybe<Scalars['Int']>;
  /** Amount of times the voucher can be used */
  usageLimitPerUser?: Maybe<Scalars['Int']>;
  /** Number of current valid usages for the promotion */
  usages: Scalars['Int'];
  /** Date when the promotion becomes active and can be applied */
  validFrom?: Maybe<Scalars['Date']>;
  /** Date until the promotion can be applied */
  validTo?: Maybe<Scalars['Date']>;
  /** Promotion value */
  value: Scalars['Float'];
  /** Type of discount, fixed or percentage */
  valueType: ValueType;
};

/** The connection type for Promotion. */
export type PromotionConnection = {
  __typename?: 'PromotionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PromotionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Promotion>>>;
  /** Relay-based page info */
  pageInfo: RelayPageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PromotionEdge = {
  __typename?: 'PromotionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Promotion>;
};

/** List of possible promotion platform types */
export enum PromotionPlatformType {
  /** Mobile */
  Mobile = 'MOBILE',
  /** Web */
  Web = 'WEB'
}

/** Possible outcomes for promotion mutations */
export type PromotionResponse = MutationErrorWrapper | Promotion;

/** List of possible targeting groups for promotions */
export enum PromotionTargetingGroup {
  /** Active Customers Only */
  ActiveCustomers = 'active_customers',
  /** New Customers Only */
  NewCustomers = 'new_customers',
  /** Reactivating Customers Only */
  ReactivatingCustomers = 'reactivating_customers'
}

/** List of possible promotion campaign type */
export enum PromotionType {
  /** AFFILIATE_COMPARISON */
  AffiliateComparison = 'AFFILIATE_COMPARISON',
  /** AFFILIATE_NETWORK */
  AffiliateNetwork = 'AFFILIATE_NETWORK',
  /** APP */
  App = 'APP',
  /** CCI_OUTBOUND_REACTIVATION */
  CciOutboundReactivation = 'CCI_OUTBOUND_REACTIVATION',
  /** CONTENT */
  Content = 'CONTENT',
  /** COOPERATIONS */
  Cooperations = 'COOPERATIONS',
  /** COW */
  Cow = 'COW',
  /** CROSS_DIRECT_MAIL */
  CrossDirectMail = 'CROSS_DIRECT_MAIL',
  /** CROSS_DISPLAY */
  CrossDisplay = 'CROSS_DISPLAY',
  /** CROSS_EMAIL */
  CrossEmail = 'CROSS_EMAIL',
  /** CROSS_PUSH */
  CrossPush = 'CROSS_PUSH',
  /** CROSS_SMS */
  CrossSms = 'CROSS_SMS',
  /** CROSS_WHATSAPP */
  CrossWhatsapp = 'CROSS_WHATSAPP',
  /** CUSTOMER_CARE */
  CustomerCare = 'CUSTOMER_CARE',
  /** DIRECT_CONTENT */
  DirectContent = 'DIRECT_CONTENT',
  /** DIRECT_REACH */
  DirectReach = 'DIRECT_REACH',
  /** DIRECT_REMARKETING */
  DirectRemarketing = 'DIRECT_REMARKETING',
  /** DIRECT_SALES */
  DirectSales = 'DIRECT_SALES',
  /** DISPLAY_TEST */
  DisplayTest = 'DISPLAY_TEST',
  /** EMPLOYEE */
  Employee = 'EMPLOYEE',
  /** EMPLOYEE_FREE */
  EmployeeFree = 'EMPLOYEE_FREE',
  /** EMPLOYER_BENEFIT */
  EmployerBenefit = 'EMPLOYER_BENEFIT',
  /** ENGAGEMENT_DIRECT_MAIL */
  EngagementDirectMail = 'ENGAGEMENT_DIRECT_MAIL',
  /** ENGAGEMENT_DISPLAY */
  EngagementDisplay = 'ENGAGEMENT_DISPLAY',
  /** ENGAGEMENT_EMAIL */
  EngagementEmail = 'ENGAGEMENT_EMAIL',
  /** ENGAGEMENT_PUSH */
  EngagementPush = 'ENGAGEMENT_PUSH',
  /** ENGAGEMENT_SMS */
  EngagementSms = 'ENGAGEMENT_SMS',
  /** ENGAGEMENT_WHATSAPP */
  EngagementWhatsapp = 'ENGAGEMENT_WHATSAPP',
  /** FACEBOOK_REACH */
  FacebookReach = 'FACEBOOK_REACH',
  /** FACEBOOK_RETARGETING */
  FacebookRetargeting = 'FACEBOOK_RETARGETING',
  /** GDN_REACH */
  GdnReach = 'GDN_REACH',
  /** GDN_RETARGETING */
  GdnRetargeting = 'GDN_RETARGETING',
  /** GIFTCARD */
  Giftcard = 'GIFTCARD',
  /** GROUP_BUYING */
  GroupBuying = 'GROUP_BUYING',
  /** H2H */
  H2H = 'H2H',
  /** INF */
  Inf = 'INF',
  /** INSERTS_MAGAZINE */
  InsertsMagazine = 'INSERTS_MAGAZINE',
  /** INSERTS_PACKAGE */
  InsertsPackage = 'INSERTS_PACKAGE',
  /** LEAD_CONVERSION */
  LeadConversion = 'LEAD_CONVERSION',
  /** LETTERBOX */
  Letterbox = 'LETTERBOX',
  /** NATIVE_REACH */
  NativeReach = 'NATIVE_REACH',
  /** NATIVE_RETARGETING */
  NativeRetargeting = 'NATIVE_RETARGETING',
  /** NEWSPAPER */
  Newspaper = 'NEWSPAPER',
  /** ONLINE_DIRECT */
  OnlineDirect = 'ONLINE_DIRECT',
  /** ONLINE_TEST */
  OnlineTest = 'ONLINE_TEST',
  /** OTHERS_OFFLINE */
  OthersOffline = 'OTHERS_OFFLINE',
  /** OTHERS_ONLINE_RETARGETING */
  OthersOnlineRetargeting = 'OTHERS_ONLINE_RETARGETING',
  /** OTHERS_REACH */
  OthersReach = 'OTHERS_REACH',
  /** OTT */
  Ott = 'OTT',
  /** OUTBRAIN_REACH */
  OutbrainReach = 'OUTBRAIN_REACH',
  /** OUTBRAIN_REMARKETING */
  OutbrainRemarketing = 'OUTBRAIN_REMARKETING',
  /** PAID_SOCIAL_TEST */
  PaidSocialTest = 'PAID_SOCIAL_TEST',
  /** PINTEREST_REACH */
  PinterestReach = 'PINTEREST_REACH',
  /** PINTEREST_RETARGETING */
  PinterestRetargeting = 'PINTEREST_RETARGETING',
  /** PODCAST */
  Podcast = 'PODCAST',
  /** POSTER */
  Poster = 'POSTER',
  /** PRICE_INCREASE_VOUCHER */
  PriceIncreaseVoucher = 'PRICE_INCREASE_VOUCHER',
  /** PROGRAMMATIC */
  Programmatic = 'PROGRAMMATIC',
  /** PROGRAMMATIC_REACH */
  ProgrammaticReach = 'PROGRAMMATIC_REACH',
  /** PROGRAMMATIC_REMARKETING */
  ProgrammaticRemarketing = 'PROGRAMMATIC_REMARKETING',
  /** PR_CUSTOMER */
  PrCustomer = 'PR_CUSTOMER',
  /** PR_TEST */
  PrTest = 'PR_TEST',
  /** RADIO */
  Radio = 'RADIO',
  /** REACTIVATION_D2D */
  ReactivationD2D = 'REACTIVATION_D2D',
  /** REACTIVATION_DIRECT_MAIL */
  ReactivationDirectMail = 'REACTIVATION_DIRECT_MAIL',
  /** REACTIVATION_DISPLAY */
  ReactivationDisplay = 'REACTIVATION_DISPLAY',
  /** REACTIVATION_EMAIL */
  ReactivationEmail = 'REACTIVATION_EMAIL',
  /** REACTIVATION_PUSH */
  ReactivationPush = 'REACTIVATION_PUSH',
  /** REACTIVATION_SMS */
  ReactivationSms = 'REACTIVATION_SMS',
  /** REACTIVATION_WHATSAPP */
  ReactivationWhatsapp = 'REACTIVATION_WHATSAPP',
  /** REFERRAL */
  Referral = 'REFERRAL',
  /** REFERRAL_FLYER */
  ReferralFlyer = 'REFERRAL_FLYER',
  /** REFERRER */
  Referrer = 'REFERRER',
  /** RETENTION_CARD */
  RetentionCard = 'RETENTION_CARD',
  /** RETENTION_D2D */
  RetentionD2D = 'RETENTION_D2D',
  /** RETENTION_DIRECT_MAIL */
  RetentionDirectMail = 'RETENTION_DIRECT_MAIL',
  /** RETENTION_DISPLAY */
  RetentionDisplay = 'RETENTION_DISPLAY',
  /** RETENTION_EMAIL */
  RetentionEmail = 'RETENTION_EMAIL',
  /** RETENTION_GIFTS */
  RetentionGifts = 'RETENTION_GIFTS',
  /** RETENTION_PHONE */
  RetentionPhone = 'RETENTION_PHONE',
  /** RETENTION_PUSH */
  RetentionPush = 'RETENTION_PUSH',
  /** RETENTION_SMS */
  RetentionSms = 'RETENTION_SMS',
  /** RETENTION_WHATSAPP */
  RetentionWhatsapp = 'RETENTION_WHATSAPP',
  /** SEM */
  Sem = 'SEM',
  /** SEM_BING */
  SemBing = 'SEM_BING',
  /** SEM_BRAND */
  SemBrand = 'SEM_BRAND',
  /** SEM_SHOPPING */
  SemShopping = 'SEM_SHOPPING',
  /** SEO_BR */
  SeoBr = 'SEO_BR',
  /** SEO_NB */
  SeoNb = 'SEO_NB',
  /** SNAPCHAT_REACH */
  SnapchatReach = 'SNAPCHAT_REACH',
  /** SNAPCHAT_REMARKETING */
  SnapchatRemarketing = 'SNAPCHAT_REMARKETING',
  /** SOCIAL */
  Social = 'SOCIAL',
  /** SPECIAL */
  Special = 'SPECIAL',
  /** TABOOLA_REACH */
  TaboolaReach = 'TABOOLA_REACH',
  /** TABOOLA_REMARKETING */
  TaboolaRemarketing = 'TABOOLA_REMARKETING',
  /** TEST_ORDER */
  TestOrder = 'TEST_ORDER',
  /** TV */
  Tv = 'TV',
  /** TV_SHOW */
  TvShow = 'TV_SHOW',
  /** TWITTER_REACH */
  TwitterReach = 'TWITTER_REACH',
  /** TWITTER_REMARKETING */
  TwitterRemarketing = 'TWITTER_REMARKETING',
  /** WOM_BOX */
  WomBox = 'WOM_BOX',
  /** WOOLWORTHS_LEADS */
  WoolworthsLeads = 'WOOLWORTHS_LEADS',
  /** WORD_OF_MOUTH */
  WordOfMouth = 'WORD_OF_MOUTH',
  /** YOUTUBE_REACH */
  YoutubeReach = 'YOUTUBE_REACH',
  /** YOUTUBE_RETARGETING */
  YoutubeRetargeting = 'YOUTUBE_RETARGETING'
}

/** The query root of the ApiSchema. */
export type QueryRoot = {
  __typename?: 'QueryRoot';
  _entities: Array<Maybe<_Entity>>;
  _service: _Service;
  /** Retrieves the last generated promotion files */
  generatedPromotionFiles: Array<GeneratedPromotionFile>;
  /** Retrieves a promotion by id */
  getPromotion?: Maybe<Promotion>;
  /** Retrieves a promotion by code through a case-insensitve, exact match search */
  getPromotionByCode?: Maybe<Promotion>;
  /** Filterable promotions list */
  promotions: PromotionConnection;
  /** Returns the referral settings for a given country and brand */
  referralProgramSettings?: Maybe<ReferralProgramSetting>;
};


/** The query root of the ApiSchema. */
export type QueryRoot_entitiesArgs = {
  representations: Array<Scalars['_Any']>;
};


/** The query root of the ApiSchema. */
export type QueryRootGeneratedPromotionFilesArgs = {
  filename?: Maybe<Scalars['String']>;
};


/** The query root of the ApiSchema. */
export type QueryRootGetPromotionArgs = {
  id: Scalars['Int'];
};


/** The query root of the ApiSchema. */
export type QueryRootGetPromotionByCodeArgs = {
  brand?: Maybe<BrandEnum>;
  code: Scalars['String'];
  country?: Maybe<CountryEnum>;
};


/** The query root of the ApiSchema. */
export type QueryRootPromotionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  brand: BrandEnum;
  code?: Maybe<Scalars['String']>;
  country: CountryEnum;
  first?: Maybe<Scalars['Int']>;
  hasSplitVouchers?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  promotionType?: Maybe<PromotionType>;
  targetingGroup?: Maybe<PromotionTargetingGroup>;
  valueType?: Maybe<ValueType>;
};


/** The query root of the ApiSchema. */
export type QueryRootReferralProgramSettingsArgs = {
  brand: BrandEnum;
  country: CountryEnum;
};

/** Recipe related information */
export type Recipe = RecipeInterface & {
  __typename?: 'Recipe';
  /** List of recipe allergens */
  allergens: Array<Allergen>;
  /** List of Assumed ingredients of the Recipe */
  assumedIngredients?: Maybe<Array<AssumedIngredient>>;
  /** List of meal attributes of the Recipe */
  attributes?: Maybe<Array<MealAttribute>>;
  /** Meal category of the recipe */
  category?: Maybe<MealCategory>;
  /** The recipe's chef */
  chef: Chef;
  /** Cooking Tip of the Recipe */
  cookingTip?: Maybe<Scalars['String']>;
  /** Description of a Recipe */
  description?: Maybe<Scalars['String']>;
  /** Difficulty Level of a recipe */
  difficulty?: Maybe<RecipeDifficultyEnum>;
  /** Serving time */
  duration?: Maybe<RecipeDuration>;
  /** recipe fees */
  extraFees: Array<PriceComponent>;
  /** Full name of the Recipe with title and subtitle */
  fullName: Scalars['String'];
  /** Unique Identifier for the Recipe */
  id?: Maybe<Scalars['Int']>;
  /** Image for the Recipe in the given size */
  image?: Maybe<Image>;
  /** If the recipe is bookmarked or not */
  isBookmarked: Scalars['Boolean'];
  /**
   * If the recipe is a classic or not
   * @deprecated Deprecated field
   */
  isClassic?: Maybe<Scalars['Boolean']>;
  /** Meal Type of the Recipe */
  mealType?: Maybe<MealType>;
  /** Nutritional info for the recipe */
  nutritionalInformation: Array<NutritionalInformation>;
  /** Percentage of local ingredients */
  percentageOfLocalIngredients: Scalars['Int'];
  /** Recipe Card */
  recipeCardUrl?: Maybe<Scalars['String']>;
  /** Type of a recipe */
  recipeType: RecipeTypeEnum;
  /** Segment of the Plan */
  segment?: Maybe<SegmentEnum>;
  /** Information about the Shipped Ingredients */
  shippedIngredients?: Maybe<Array<ShippedIngredient>>;
  /** Slug of the recipe */
  slug?: Maybe<Scalars['String']>;
  /** Preperations steps of the Recipe */
  steps?: Maybe<Array<RecipeStep>>;
  /** Subtitle of the Recipe */
  subtitle?: Maybe<Scalars['String']>;
  /** Title of the Recipe */
  title?: Maybe<Scalars['String']>;
  /** Detail of the Recipe */
  url?: Maybe<Scalars['String']>;
  /** List of utensils of the Recipe */
  utensils?: Maybe<Array<Utensil>>;
};


/** Recipe related information */
export type RecipeExtraFeesArgs = {
  feeType?: Maybe<PriceComponentTypeEnum>;
};


/** Recipe related information */
export type RecipeImageArgs = {
  size: ImageSizeEnum;
};

/** Available difficulty values for a recipe */
export enum RecipeDifficultyEnum {
  /** Easy */
  Easy = 'EASY',
  /** Hard */
  Hard = 'HARD',
  /** Medium */
  Medium = 'MEDIUM'
}

/** Serving time for a recipe */
export type RecipeDuration = {
  __typename?: 'RecipeDuration';
  /** min serving time */
  from: Scalars['Int'];
  /** max serving time */
  to: Scalars['Int'];
  /** Unit of time */
  unit: Scalars['String'];
};

/** Recipe related information */
export type RecipeInterface = {
  /** List of recipe allergens */
  allergens: Array<Allergen>;
  /** List of Assumed ingredients of the Recipe */
  assumedIngredients?: Maybe<Array<AssumedIngredient>>;
  /** List of meal attributes of the Recipe */
  attributes?: Maybe<Array<MealAttribute>>;
  /** Meal category of the recipe */
  category?: Maybe<MealCategory>;
  /** The recipe's chef */
  chef: Chef;
  /** Cooking Tip of the Recipe */
  cookingTip?: Maybe<Scalars['String']>;
  /** Description of a Recipe */
  description?: Maybe<Scalars['String']>;
  /** Difficulty Level of a recipe */
  difficulty?: Maybe<RecipeDifficultyEnum>;
  /** Serving time */
  duration?: Maybe<RecipeDuration>;
  /** recipe fees */
  extraFees: Array<PriceComponent>;
  /** Full name of the Recipe with title and subtitle */
  fullName: Scalars['String'];
  /** Unique Identifier for the Recipe */
  id?: Maybe<Scalars['Int']>;
  /** Image for the Recipe in the given size */
  image?: Maybe<Image>;
  /** If the recipe is bookmarked or not */
  isBookmarked: Scalars['Boolean'];
  /**
   * If the recipe is a classic or not
   * @deprecated Deprecated field
   */
  isClassic?: Maybe<Scalars['Boolean']>;
  /** Meal Type of the Recipe */
  mealType?: Maybe<MealType>;
  /** Nutritional info for the recipe */
  nutritionalInformation: Array<NutritionalInformation>;
  /** Percentage of local ingredients */
  percentageOfLocalIngredients: Scalars['Int'];
  /** Recipe Card */
  recipeCardUrl?: Maybe<Scalars['String']>;
  /** Type of a recipe */
  recipeType: RecipeTypeEnum;
  /** Segment of the Plan */
  segment?: Maybe<SegmentEnum>;
  /** Information about the Shipped Ingredients */
  shippedIngredients?: Maybe<Array<ShippedIngredient>>;
  /** Slug of the recipe */
  slug?: Maybe<Scalars['String']>;
  /** Preperations steps of the Recipe */
  steps?: Maybe<Array<RecipeStep>>;
  /** Subtitle of the Recipe */
  subtitle?: Maybe<Scalars['String']>;
  /** Title of the Recipe */
  title?: Maybe<Scalars['String']>;
  /** Detail of the Recipe */
  url?: Maybe<Scalars['String']>;
  /** List of utensils of the Recipe */
  utensils?: Maybe<Array<Utensil>>;
};


/** Recipe related information */
export type RecipeInterfaceExtraFeesArgs = {
  feeType?: Maybe<PriceComponentTypeEnum>;
};


/** Recipe related information */
export type RecipeInterfaceImageArgs = {
  size: ImageSizeEnum;
};

/** Recipe rating information */
export type RecipeRatingInterface = {
  /** comment given while rating by the user */
  comment?: Maybe<Scalars['String']>;
  /** event reason code given by the user */
  eventReason?: Maybe<EventReasonEnum>;
  /** rating of the recipe */
  score: Scalars['Int'];
};

/** List of available RecipeState */
export enum RecipeState {
  /** Cooked Recipe */
  Cooked = 'COOKED',
  /** Uncooked Recipe */
  Uncooked = 'UNCOOKED'
}

/** Preparation step for a recipe */
export type RecipeStep = {
  __typename?: 'RecipeStep';
  /** Description of the recipe step */
  description: Scalars['String'];
  /** Image of the recipe preference */
  image?: Maybe<Image>;
  /** Title of the recipe step */
  title: Scalars['String'];
};


/** Preparation step for a recipe */
export type RecipeStepImageArgs = {
  size: ImageSizeEnum;
};

/** List of available recipe types */
export enum RecipeTypeEnum {
  /** Premium */
  Premium = 'PREMIUM',
  /** Standard */
  Standard = 'STANDARD'
}

/** A referral of type Email */
export type ReferralEmail = ReferralInterface & {
  __typename?: 'ReferralEmail';
  /** If the referral can be sent to the recipient again */
  canSendAgain?: Maybe<Scalars['Boolean']>;
  /** Unique identifier of the referral record */
  id?: Maybe<Scalars['Int']>;
  /**
   * If the referral was already redeemed by the receiver
   * @deprecated Not expressive enough for all situations. Use .status instead
   */
  isRedeemed?: Maybe<Scalars['Boolean']>;
  /** The promotion attached to the referral */
  promotion?: Maybe<Promotion>;
  /** The email of the recipient */
  recipientEmail?: Maybe<Scalars['String']>;
  /** The name of the recipient */
  recipientName?: Maybe<Scalars['String']>;
  /** The Customer who sent the referral */
  sender: Customer;
  /** The date and time when the referral was first sent */
  sentAt?: Maybe<Scalars['DateTime']>;
  /** Status of the referral */
  status?: Maybe<ReferralStatusEnum>;
  /** Type of the referral */
  type?: Maybe<Scalars['String']>;
};

/** Referral feature information for a given customer */
export type ReferralFeature = {
  __typename?: 'ReferralFeature';
  /** Number of referrals the customer can send. Returns 0 if feature cannot be used */
  availableToSend?: Maybe<Scalars['Int']>;
  /** Whether the customer can use the referral feature */
  isEnabled?: Maybe<Scalars['Boolean']>;
  /** Number of boxes customer must have completed to have access to referrals */
  minimumOrdersForReferralAccess?: Maybe<Scalars['Int']>;
  /** Number of orders that customer needs to have in order to use the feature */
  numberOfOrdersUntilEnabled?: Maybe<Scalars['Int']>;
  /** List of referrals the customer already sent or generated */
  sent?: Maybe<Array<ReferralInterface>>;
};


/** Referral feature information for a given customer */
export type ReferralFeatureSentArgs = {
  after?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
};

/** A referral that a customer sent to a friend */
export type ReferralInterface = {
  /** Unique identifier of the referral record */
  id?: Maybe<Scalars['Int']>;
  /**
   * If the referral was already redeemed by the receiver
   * @deprecated Not expressive enough for all situations. Use .status instead
   */
  isRedeemed?: Maybe<Scalars['Boolean']>;
  /** The promotion attached to the referral */
  promotion?: Maybe<Promotion>;
  /** The Customer who sent the referral */
  sender: Customer;
  /** Status of the referral */
  status?: Maybe<ReferralStatusEnum>;
  /** Type of the referral */
  type?: Maybe<Scalars['String']>;
};

/** A referral of type Link */
export type ReferralLink = ReferralInterface & {
  __typename?: 'ReferralLink';
  /** The date and time when the referral was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Unique identifier of the referral record */
  id?: Maybe<Scalars['Int']>;
  /**
   * If the referral was already redeemed by the receiver
   * @deprecated Not expressive enough for all situations. Use .status instead
   */
  isRedeemed?: Maybe<Scalars['Boolean']>;
  /** The link to redeem the referral */
  link?: Maybe<Scalars['String']>;
  /** The promotion attached to the referral */
  promotion?: Maybe<Promotion>;
  /** The Customer who sent the referral */
  sender: Customer;
  /** Status of the referral */
  status?: Maybe<ReferralStatusEnum>;
  /** Type of the referral */
  type?: Maybe<Scalars['String']>;
};

/** Referral Program Information */
export type ReferralProgramSetting = {
  __typename?: 'ReferralProgramSetting';
  /** The brand of the setting */
  brand: BrandEnum;
  /** Type of discount, value or percentage */
  configType: ValueType;
  /** Discounted value after referral */
  configValue: Scalars['Float'];
  /** The country of the setting */
  country: CountryEnum;
  /** If the referral offers free shipping */
  freeShipping: Scalars['Boolean'];
  /** Max available */
  maxAvailable: Scalars['Int'];
  /** Number of boxes customer must have completed to have access to referrals */
  minBoxes: Scalars['Int'];
  /** Config for promotions to affect the N following orders. */
  settings: Array<MultiplePromotionSetting>;
  /** Referral voucher expiration days */
  voucherExpirationDays: Scalars['Int'];
};

/** Referral Program Information */
export type ReferralProgramSettingInputType = {
  /** The brand of the setting */
  brand: BrandEnum;
  /** Type of discount, value or percentage */
  configType: ValueType;
  /** Discounted value after referral */
  configValue: Scalars['Float'];
  /** The country of the setting */
  country: CountryEnum;
  /** If the referral offers free shipping */
  freeShipping: Scalars['Boolean'];
  /** Max available */
  maxAvailable: Scalars['Int'];
  /** Number of boxes customer must have completed to have access to referrals */
  minBoxes: Scalars['Int'];
  /** Config for promotions to affect the N following orders. */
  settings?: Maybe<Array<MultiplePromotionSettingsInputType>>;
  /** Referral voucher expiration days */
  voucherExpirationDays: Scalars['Int'];
};

/** List of available Referral Status */
export enum ReferralStatusEnum {
  /** Cancelled */
  Cancelled = 'CANCELLED',
  /** Expired */
  Expired = 'EXPIRED',
  /** Not Redeemed */
  NotRedeemed = 'NOT_REDEEMED',
  /** Redeemed */
  Redeemed = 'REDEEMED'
}

/** Information about pagination in a connection. */
export type RelayPageInfo = {
  __typename?: 'RelayPageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** Possible values for Segment */
export enum SegmentEnum {
  /** Add On */
  Addon = 'ADDON',
  /** Amazon */
  Amazon = 'AMAZON',
  /** Couples */
  Couples = 'COUPLES',
  /** Custom */
  Custom = 'CUSTOM',
  /** Families */
  Families = 'FAMILIES'
}

/** Information about a slot belonging to an associated object */
export type SelectedTimeSlotOption = TimeSlotInterface & {
  __typename?: 'SelectedTimeSlotOption';
  /** List of possible Delivery Options */
  dates?: Maybe<Array<VisitFirstDeliveryDateOption>>;
  /** Start time of the Time Slot. 24H format */
  from?: Maybe<Scalars['Time']>;
  /** Start time of the Time Slot. Complete datetime. */
  fromDateTime?: Maybe<Scalars['DateTime']>;
  /** Identifier of the Time Slot */
  id: Scalars['Int'];
  /** If the Time Slot is selected */
  isSelected?: Maybe<Scalars['Boolean']>;
  /** Shipper used in Time Slot */
  shipper?: Maybe<Shipper>;
  /** End time of the Time Slot. 24H format */
  to?: Maybe<Scalars['Time']>;
  /** End time of the Time Slot. Complete datetime. */
  toDateTime?: Maybe<Scalars['DateTime']>;
};

/** Shipment related information */
export type Shipment = {
  __typename?: 'Shipment';
  /** Shipment Add Ons */
  addOns?: Maybe<Array<ShipmentAddOn>>;
  /** ID of the Shipment */
  id: Scalars['Int'];
  /** Number of the Shipment */
  number?: Maybe<Scalars['String']>;
  /** List of Recipes from the Shipment */
  recipes?: Maybe<Array<OrderRecipe>>;
  /** Shipment tracking information */
  tracking: ShipmentTrackingInfo;
};

/** Shipment Add On related information */
export type ShipmentAddOn = {
  __typename?: 'ShipmentAddOn';
  /** Unique Identifier for the related Add On */
  id?: Maybe<Scalars['Int']>;
  /** Add On name */
  name?: Maybe<Scalars['String']>;
  /** AddOn quantity */
  quantity?: Maybe<Scalars['Int']>;
};

export type ShipmentTrackingInfo = {
  __typename?: 'ShipmentTrackingInfo';
  link?: Maybe<Scalars['String']>;
  /** Shipper tracking number */
  number?: Maybe<Scalars['String']>;
};

/** Shipped Ingredient related information */
export type ShippedIngredient = {
  __typename?: 'ShippedIngredient';
  /** Information about allergens */
  allergens: Array<Allergen>;
  /** Shipped Ingredient id */
  id: Scalars['Int'];
  /** Shipped Ingredient image */
  image?: Maybe<Image>;
  /** Shipped Ingredient name */
  name?: Maybe<Scalars['String']>;
  /** Shipped Ingredient name with quantity */
  nameWithQuantity?: Maybe<Scalars['String']>;
};


/** Shipped Ingredient related information */
export type ShippedIngredientImageArgs = {
  size: ImageSizeEnum;
};

/** Shipper related information */
export type Shipper = {
  __typename?: 'Shipper';
  /** Name of the Shipper */
  name?: Maybe<Scalars['String']>;
};

/** List of sort directions. */
export enum SortDirection {
  /** Ascending order. */
  Asc = 'ASC',
  /** Descending order. */
  Desc = 'DESC'
}

/** Special related information */
export type Special = {
  __typename?: 'Special';
  /** Start date for the Special's availability */
  availableFrom?: Maybe<Scalars['Date']>;
  /** End date for the Special's availability */
  availableTo?: Maybe<Scalars['Date']>;
  /** Visual configuration properties of the Special */
  configuration?: Maybe<SpecialConfiguration>;
  /** Textual description of the delivery information */
  deliveryDescription?: Maybe<Scalars['String']>;
  /** Description of the Special */
  description?: Maybe<Scalars['String']>;
  /** Unique Identifier for the Special */
  id?: Maybe<Scalars['Int']>;
  /** If the special is age restricted */
  isAgeRestricted?: Maybe<Scalars['Boolean']>;
  /** If the Special is currently active */
  isAvailable?: Maybe<Scalars['Boolean']>;
  /** Menu with the available items for the special */
  menu?: Maybe<SpecialMenu>;
  /** Used to group similar Specials */
  namespace?: Maybe<Scalars['String']>;
  /** URL slug of the Special */
  slug?: Maybe<Scalars['String']>;
  /** Title of the Special */
  title?: Maybe<Scalars['String']>;
};

/** Configuration properties of a Special */
export type SpecialConfiguration = {
  __typename?: 'SpecialConfiguration';
  /** Background Color */
  backgroundColor?: Maybe<Scalars['String']>;
  /** Badge image URL for Desktop usage */
  desktopBadge?: Maybe<Scalars['String']>;
  /** Banner image URL for Desktop usage */
  desktopBanner?: Maybe<Scalars['String']>;
  /** Header Color */
  headerColor?: Maybe<Scalars['String']>;
  /** Banner image URL for Mobile usage */
  mobileBanner?: Maybe<Scalars['String']>;
  /** Primary Color */
  primaryColor?: Maybe<Scalars['String']>;
  /** Secondary Color */
  secondaryColor?: Maybe<Scalars['String']>;
  /** Badge image URL for Tablet usage */
  tabletBadge?: Maybe<Scalars['String']>;
  /** Banner image URL for Tablet usage */
  tabletBanner?: Maybe<Scalars['String']>;
};

/** Menu contents for the special */
export type SpecialMenu = {
  __typename?: 'SpecialMenu';
  /** List of add ons available for the special */
  addOns?: Maybe<Array<Maybe<AddOn>>>;
};

/** State related information */
export type State = {
  __typename?: 'State';
  /** The state abbreviation */
  abbreviation?: Maybe<Scalars['String']>;
  /** ID for the State */
  id: Scalars['Int'];
  /** The state name */
  name?: Maybe<Scalars['String']>;
};

/** Step related information */
export type Step = {
  __typename?: 'Step';
  /** Can we skip a Step? */
  canSkip: Scalars['Boolean'];
  /** The identifier of the Step */
  identifier: TasteProfilePreferenceEnum;
  /** The subtitle of the Step */
  subtitle?: Maybe<Scalars['String']>;
  /** The title of the Step */
  title?: Maybe<Scalars['String']>;
};

/** Possible values for taste profile preference */
export enum TasteProfilePreferenceEnum {
  /** protein preferences */
  ProteinPreferences = 'PROTEIN_PREFERENCES',
  /** recipe likes */
  RecipeLikes = 'RECIPE_LIKES',
  /** taste preferences */
  TastePreferences = 'TASTE_PREFERENCES'
}

/** Taste profile wizard related information */
export type TasteProfileWizard = {
  __typename?: 'TasteProfileWizard';
  /** whether the wizard has previously been completed */
  isComplete: Scalars['Boolean'];
  /** steps of the wizard */
  steps?: Maybe<Array<Step>>;
};

/** Tax related information */
export type Tax = {
  __typename?: 'Tax';
  /** Tax amount */
  amount?: Maybe<Scalars['Float']>;
  /** If the tax is included or not */
  isIncluded?: Maybe<Scalars['Boolean']>;
  /** Tax name */
  name?: Maybe<Scalars['String']>;
  /** Tax percentage */
  percentage?: Maybe<Scalars['Float']>;
};

/** Tax Rate object, currently used as possible type of AdjustmentSource */
export type TaxRate = {
  __typename?: 'TaxRate';
  /** Tax Rate Id */
  id: Scalars['Int'];
};


/** Time Slot related information */
export type TimeSlot = TimeSlotInterface & {
  __typename?: 'TimeSlot';
  /** Start time of the Time Slot. 24H format */
  from?: Maybe<Scalars['Time']>;
  /** Start time of the Time Slot. Complete datetime. */
  fromDateTime?: Maybe<Scalars['DateTime']>;
  /** Identifier of the Time Slot */
  id: Scalars['Int'];
  /** Shipper used in Time Slot */
  shipper?: Maybe<Shipper>;
  /** End time of the Time Slot. 24H format */
  to?: Maybe<Scalars['Time']>;
  /** End time of the Time Slot. Complete datetime. */
  toDateTime?: Maybe<Scalars['DateTime']>;
};

/** Time Slot related information */
export type TimeSlotInterface = {
  /** Start time of the Time Slot. 24H format */
  from?: Maybe<Scalars['Time']>;
  /** Start time of the Time Slot. Complete datetime. */
  fromDateTime?: Maybe<Scalars['DateTime']>;
  /** Identifier of the Time Slot */
  id: Scalars['Int'];
  /** Shipper used in Time Slot */
  shipper?: Maybe<Shipper>;
  /** End time of the Time Slot. 24H format */
  to?: Maybe<Scalars['Time']>;
  /** End time of the Time Slot. Complete datetime. */
  toDateTime?: Maybe<Scalars['DateTime']>;
};

/** Upcoming Time Slot related information */
export type UpcomingTimeSlot = {
  __typename?: 'UpcomingTimeSlot';
  /** Start time of the Time Slot. 24H format */
  from: Scalars['Time'];
  /** End time of the Time Slot. 24H format */
  to: Scalars['Time'];
};

/** Possible outcomes for update referral settings mutations */
export type UpdateReferralProgramSettingResponse = MutationErrorWrapper | ReferralProgramSetting;

/** Credits given to a user */
export type UserCredit = {
  __typename?: 'UserCredit';
  /** Amount of credit that was given */
  amount: Scalars['Float'];
  /** Date when the credit was created */
  createdAt: Scalars['DateTime'];
  /** The user that created the credit */
  createdBy?: Maybe<Scalars['String']>;
  /** Order number related with the credit */
  orderNumber?: Maybe<Scalars['String']>;
  /** Reason for giving the credit */
  reason: CreditReason;
};

/** User Events related with changes to its account */
export type UserEvent = {
  __typename?: 'UserEvent';
  /** Additional event details */
  details: Scalars['JSON'];
  /** The reason that lead to the creation of the event */
  reason?: Maybe<EventReason>;
  /** Who performed the event, user or the system */
  source?: Maybe<Scalars['String']>;
  /** Date when the event occurred */
  timestamp: Scalars['DateTime'];
  /** The type of event */
  type: EventType;
};

/** UserFoodPreferences related information */
export type UserFoodPreferences = {
  __typename?: 'UserFoodPreferences';
  /** Is the user vegetarian */
  isVegetarian?: Maybe<Scalars['Boolean']>;
  options: Array<FoodPreferenceOption>;
};

/** Marketing messages sent to the user */
export type UserMarketingMessage = {
  __typename?: 'UserMarketingMessage';
  /** Message's campaign ID */
  campaignId: Scalars['Int'];
  /** Message ID */
  id: Scalars['String'];
  /** Message's name */
  name: Scalars['String'];
  /** Message's submission date */
  sentAt: Scalars['String'];
  /** Message's subject */
  subject: Scalars['String'];
};

/** UserPlan related information */
export type UserPlan = {
  __typename?: 'UserPlan';
  /**
   * Delivery day of the UserPlan
   * @deprecated Use `weekday` instead
   */
  deliveryDay?: Maybe<Scalars['Int']>;
  /** Delivery options for the user plan */
  deliveryOptions?: Maybe<Array<UserPlanDeliveryOption>>;
  /** Fruitbox addon for user plan */
  fruitBox: FruitBox;
  /** If the customer wants printed recipe cards */
  hasPrintedRecipeCards: Scalars['Boolean'];
  /** Information about the Plan */
  plan?: Maybe<Plan>;
  /** Time slot of the UserPlan */
  timeSlot?: Maybe<TimeSlot>;
  /** Delivery day of the UserPlan, from 1 to 7, where 1 is Monday */
  weekday?: Maybe<Scalars['Int']>;
};


/** UserPlan related information */
export type UserPlanDeliveryOptionsArgs = {
  zipcode?: Maybe<Scalars['String']>;
};

/** User Plan Delivery Option related information */
export type UserPlanDeliveryOption = WeekdayDeliveryOptionInterface & {
  __typename?: 'UserPlanDeliveryOption';
  /** If the week day is the default */
  isDefault?: Maybe<Scalars['Boolean']>;
  /** If the week day is selected */
  isSelected?: Maybe<Scalars['Boolean']>;
  /** List of possible Time Slots */
  slots?: Maybe<Array<SelectedTimeSlotOption>>;
  /** Weekday of delivery, from 1 to 7, where 1 is Monday */
  weekday?: Maybe<Scalars['Int']>;
};

/** Information about the promotions that will be applied on the next orders */
export type UserPromotionSettings = {
  __typename?: 'UserPromotionSettings';
  /** Which of the n next order to affect */
  orderOrdinal: Scalars['Int'];
  /** Next promotions */
  promotion: Promotion;
};

/** Information about the user bookmarked recipes */
export type UserRecipeBookmark = {
  __typename?: 'UserRecipeBookmark';
  /** Bookmarked Recipe */
  recipe: Recipe;
};

/** User's rating for a recipe in an order */
export type UserRecipeRating = RecipeRatingInterface & {
  __typename?: 'UserRecipeRating';
  /** comment given while rating by the user */
  comment?: Maybe<Scalars['String']>;
  /** event reason code given by the user */
  eventReason?: Maybe<EventReasonEnum>;
  /** rating of the recipe */
  score: Scalars['Int'];
};

/** List of User Types */
export enum UserType {
  /** churn */
  Churn = 'CHURN',
  /** rm */
  Rm = 'RM'
}

/** Utensil related information */
export type Utensil = {
  __typename?: 'Utensil';
  /** Name of the utensil */
  name?: Maybe<Scalars['String']>;
};

/** List of possible discount types */
export enum ValueType {
  /** Fixed amount for discount */
  Fixed = 'fixed',
  /** Discount in percentage */
  Percentage = 'percentage'
}

/** Visit related information */
export type Visit = {
  __typename?: 'Visit';
  /** Customer brand */
  brand: BrandEnum;
  /** First Order of the customer associated with the visit */
  conversionOrder?: Maybe<Order>;
  /** The country of the customer */
  country?: Maybe<CountryEnum>;
  /** Delivery day of the visit */
  deliveryDay?: Maybe<Scalars['Int']>;
  /** Delivery options for the first delivery */
  deliveryOptions?: Maybe<Array<VisitDeliveryOption>>;
  /** Email of the visit */
  email?: Maybe<Scalars['String']>;
  /** First name of the visit */
  firstName?: Maybe<Scalars['String']>;
  /** is the user in an area we deliver to */
  inActiveArea?: Maybe<Scalars['Boolean']>;
  /** is the visit referred */
  isReferred?: Maybe<Scalars['Boolean']>;
  /** Is the visit vegetarian */
  isVegetarian?: Maybe<Scalars['Boolean']>;
  /** Last name of the visit */
  lastName?: Maybe<Scalars['String']>;
  /** Last step of the visit */
  lastStep?: Maybe<Scalars['Int']>;
  /** Creation date of the lead */
  leadSince?: Maybe<Scalars['DateTime']>;
  /** Information about the plan of the visit */
  plan?: Maybe<Plan>;
  /** Promotion code of the visit */
  promotionCode?: Maybe<Scalars['String']>;
  /** The Referrer Url of the visit */
  referrerUrl?: Maybe<Scalars['String']>;
  /** The Start Url of the visit */
  startUrl?: Maybe<Scalars['String']>;
  /** is the newsletter active */
  subscribedToNewsletter?: Maybe<Scalars['Boolean']>;
  /** Time slot of the visit */
  timeSlot?: Maybe<TimeSlot>;
  /** Visit's updated at time */
  updatedAt?: Maybe<Scalars['String']>;
  /** Customer ID of the visit */
  userId?: Maybe<Scalars['Int']>;
  /** UUID of the visit */
  uuid: Scalars['String'];
  /** Zipcode entered in the funnel */
  zipcode?: Maybe<Scalars['String']>;
};


/** Visit related information */
export type VisitDeliveryOptionsArgs = {
  zipcode: Scalars['String'];
};

/** Visit Delivery Option related information */
export type VisitDeliveryOption = WeekdayDeliveryOptionInterface & {
  __typename?: 'VisitDeliveryOption';
  /**
   * List of possible Delivery Options
   * @deprecated  please stop using this and use instead slot -> dates
   */
  dates?: Maybe<Array<VisitFirstDeliveryDateOption>>;
  /** If the week day is the default */
  isDefault?: Maybe<Scalars['Boolean']>;
  /** If the week day is selected */
  isSelected?: Maybe<Scalars['Boolean']>;
  /** List of possible Time Slots */
  slots?: Maybe<Array<SelectedTimeSlotOption>>;
  /** Weekday of delivery, from 1 to 7, where 1 is Monday */
  weekday?: Maybe<Scalars['Int']>;
};

/** Visit first delivery date option related information */
export type VisitFirstDeliveryDateOption = DeliveryOptionInterface & {
  __typename?: 'VisitFirstDeliveryDateOption';
  /** The day no further changes can be made to orders (excluding VIP changes) */
  cutoffDate?: Maybe<Scalars['Date']>;
  /** Date of delivery */
  date?: Maybe<Scalars['Date']>;
  /** If the date is the default */
  isDefault?: Maybe<Scalars['Boolean']>;
  /** If the date is selected */
  isSelected?: Maybe<Scalars['Boolean']>;
};

/** Weekday Delivery Options related information */
export type WeekdayDeliveryOptionInterface = {
  /** If the week day is the default */
  isDefault?: Maybe<Scalars['Boolean']>;
  /** If the week day is selected */
  isSelected?: Maybe<Scalars['Boolean']>;
  /** List of possible Time Slots */
  slots?: Maybe<Array<SelectedTimeSlotOption>>;
  /** Weekday of delivery, from 1 to 7, where 1 is Monday */
  weekday?: Maybe<Scalars['Int']>;
};


export type _Entity = Order;

/**
 * The sdl representing the federated service capabilities. Includes federation
 * directives, removes federation types, and includes rest of full schema after
 * schema directives have been applied
 */
export type _Service = {
  __typename?: '_Service';
  sdl?: Maybe<Scalars['String']>;
};

export type ReferralProgramFragment = (
  { __typename?: 'ReferralProgramSetting' }
  & Pick<ReferralProgramSetting, 'brand' | 'country' | 'configType' | 'configValue' | 'freeShipping' | 'maxAvailable' | 'minBoxes' | 'voucherExpirationDays'>
  & { settings: Array<(
    { __typename?: 'MultiplePromotionSetting' }
    & Pick<MultiplePromotionSetting, 'orderOrdinal' | 'value' | 'valueType' | 'freeShipping'>
  )> }
);

export type CreatePromotionMutationVariables = Exact<{
  createPromotion: CreatePromotionInputType;
  quantity?: Maybe<Scalars['Int']>;
  prefix?: Maybe<Scalars['String']>;
  codeLength?: Maybe<Scalars['Int']>;
}>;


export type CreatePromotionMutation = (
  { __typename?: 'MutationRoot' }
  & Pick<MutationRoot, 'createPromotion'>
);

export type UpdatePromotionMutationVariables = Exact<{
  updatePromotion: CreatePromotionInputType;
  id: Scalars['Int'];
}>;


export type UpdatePromotionMutation = (
  { __typename?: 'MutationRoot' }
  & { updatePromotion?: Maybe<(
    { __typename?: 'MutationErrorWrapper' }
    & { errors: Array<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'errorCode' | 'message'>
    )> }
  ) | (
    { __typename?: 'Promotion' }
    & Pick<Promotion, 'code'>
  )> }
);

export type UpdateReferralsMutationVariables = Exact<{
  referralSettings: ReferralProgramSettingInputType;
}>;


export type UpdateReferralsMutation = (
  { __typename?: 'MutationRoot' }
  & { updateReferralProgramSettings?: Maybe<(
    { __typename?: 'MutationErrorWrapper' }
    & { errors: Array<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'errorCode' | 'message'>
    )> }
  ) | (
    { __typename?: 'ReferralProgramSetting' }
    & ReferralProgramFragment
  )> }
);

export type GetPromotionQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPromotionQuery = (
  { __typename?: 'QueryRoot' }
  & { getPromotion?: Maybe<(
    { __typename?: 'Promotion' }
    & Pick<Promotion, 'id' | 'brand' | 'country' | 'code' | 'name' | 'platform' | 'promotionType' | 'usageLimit' | 'usageLimitPerUser' | 'value' | 'valueType' | 'freeShipping' | 'usages' | 'expirationDate' | 'targetingGroup' | 'marketingCopy'>
    & { parent?: Maybe<(
      { __typename?: 'Promotion' }
      & Pick<Promotion, 'id'>
    )>, settings: Array<(
      { __typename?: 'MultiplePromotionSetting' }
      & Pick<MultiplePromotionSetting, 'orderOrdinal' | 'value' | 'valueType' | 'freeShipping'>
    )> }
  )> }
);

export type GetPromotionByCodeQueryVariables = Exact<{
  code: Scalars['String'];
  brand?: Maybe<BrandEnum>;
  country?: Maybe<CountryEnum>;
}>;


export type GetPromotionByCodeQuery = (
  { __typename?: 'QueryRoot' }
  & { getPromotionByCode?: Maybe<(
    { __typename?: 'Promotion' }
    & Pick<Promotion, 'id' | 'brand' | 'country' | 'code'>
  )> }
);

export type GetPromotionFilesQueryVariables = Exact<{
  filename?: Maybe<Scalars['String']>;
}>;


export type GetPromotionFilesQuery = (
  { __typename?: 'QueryRoot' }
  & { generatedPromotionFiles: Array<(
    { __typename?: 'GeneratedPromotionFile' }
    & Pick<GeneratedPromotionFile, 'link' | 'createdAt'>
  )> }
);

export type ReferralProgramSettingsQueryVariables = Exact<{
  country: CountryEnum;
  brand: BrandEnum;
}>;


export type ReferralProgramSettingsQuery = (
  { __typename?: 'QueryRoot' }
  & { referralProgramSettings?: Maybe<(
    { __typename?: 'ReferralProgramSetting' }
    & ReferralProgramFragment
  )> }
);

export type PromotionsQueryVariables = Exact<{
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  brand: BrandEnum;
  country: CountryEnum;
  hasSplitVouchers?: Maybe<Scalars['Boolean']>;
  promotionType?: Maybe<PromotionType>;
  targetingGroup?: Maybe<PromotionTargetingGroup>;
  valueType?: Maybe<ValueType>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type PromotionsQuery = (
  { __typename?: 'QueryRoot' }
  & { promotions: (
    { __typename?: 'PromotionConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'PromotionEdge' }
      & Pick<PromotionEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Promotion' }
        & Pick<Promotion, 'id' | 'name' | 'code' | 'expirationDate' | 'createdBy'>
      )> }
    )>>>, pageInfo: (
      { __typename?: 'RelayPageInfo' }
      & Pick<RelayPageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  ) }
);

export const ReferralProgramFragmentDoc = gql`
    fragment ReferralProgram on ReferralProgramSetting {
  brand
  country
  configType
  configValue
  freeShipping
  maxAvailable
  minBoxes
  voucherExpirationDays
  settings {
    orderOrdinal
    value
    valueType
    freeShipping
  }
}
    `;
export const CreatePromotionDocument = gql`
    mutation CreatePromotion_Chilli($createPromotion: CreatePromotionInputType!, $quantity: Int, $prefix: String, $codeLength: Int) {
  createPromotion(
    createPromotion: $createPromotion
    quantity: $quantity
    prefix: $prefix
    codeLength: $codeLength
  )
}
    `;
export type CreatePromotionMutationFn = Apollo.MutationFunction<CreatePromotionMutation, CreatePromotionMutationVariables>;
export function useCreatePromotionMutation(baseOptions?: Apollo.MutationHookOptions<CreatePromotionMutation, CreatePromotionMutationVariables>) {
        return Apollo.useMutation<CreatePromotionMutation, CreatePromotionMutationVariables>(CreatePromotionDocument, baseOptions);
      }
export type CreatePromotionMutationHookResult = ReturnType<typeof useCreatePromotionMutation>;
export type CreatePromotionMutationResult = Apollo.MutationResult<CreatePromotionMutation>;
export type CreatePromotionMutationOptions = Apollo.BaseMutationOptions<CreatePromotionMutation, CreatePromotionMutationVariables>;
export const UpdatePromotionDocument = gql`
    mutation UpdatePromotion_Chilli($updatePromotion: CreatePromotionInputType!, $id: Int!) {
  updatePromotion(id: $id, updatePromotion: $updatePromotion) {
    ... on MutationErrorWrapper {
      errors {
        errorCode
        message
      }
    }
    ... on Promotion {
      code
    }
  }
}
    `;
export type UpdatePromotionMutationFn = Apollo.MutationFunction<UpdatePromotionMutation, UpdatePromotionMutationVariables>;
export function useUpdatePromotionMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePromotionMutation, UpdatePromotionMutationVariables>) {
        return Apollo.useMutation<UpdatePromotionMutation, UpdatePromotionMutationVariables>(UpdatePromotionDocument, baseOptions);
      }
export type UpdatePromotionMutationHookResult = ReturnType<typeof useUpdatePromotionMutation>;
export type UpdatePromotionMutationResult = Apollo.MutationResult<UpdatePromotionMutation>;
export type UpdatePromotionMutationOptions = Apollo.BaseMutationOptions<UpdatePromotionMutation, UpdatePromotionMutationVariables>;
export const UpdateReferralsDocument = gql`
    mutation UpdateReferrals_Chilli($referralSettings: ReferralProgramSettingInputType!) {
  updateReferralProgramSettings(referralSettings: $referralSettings) {
    ... on ReferralProgramSetting {
      ...ReferralProgram
    }
    ... on MutationErrorWrapper {
      errors {
        errorCode
        message
      }
    }
  }
}
    ${ReferralProgramFragmentDoc}`;
export type UpdateReferralsMutationFn = Apollo.MutationFunction<UpdateReferralsMutation, UpdateReferralsMutationVariables>;
export function useUpdateReferralsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReferralsMutation, UpdateReferralsMutationVariables>) {
        return Apollo.useMutation<UpdateReferralsMutation, UpdateReferralsMutationVariables>(UpdateReferralsDocument, baseOptions);
      }
export type UpdateReferralsMutationHookResult = ReturnType<typeof useUpdateReferralsMutation>;
export type UpdateReferralsMutationResult = Apollo.MutationResult<UpdateReferralsMutation>;
export type UpdateReferralsMutationOptions = Apollo.BaseMutationOptions<UpdateReferralsMutation, UpdateReferralsMutationVariables>;
export const GetPromotionDocument = gql`
    query GetPromotion_Chilli($id: Int!) {
  getPromotion(id: $id) {
    id
    brand
    country
    code
    name
    platform
    promotionType
    brand
    usageLimit
    usageLimitPerUser
    value
    valueType
    freeShipping
    usages
    expirationDate
    targetingGroup
    marketingCopy
    parent {
      id
    }
    settings {
      orderOrdinal
      value
      valueType
      freeShipping
    }
  }
}
    `;
export function useGetPromotionQuery(baseOptions: Apollo.QueryHookOptions<GetPromotionQuery, GetPromotionQueryVariables>) {
        return Apollo.useQuery<GetPromotionQuery, GetPromotionQueryVariables>(GetPromotionDocument, baseOptions);
      }
export function useGetPromotionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPromotionQuery, GetPromotionQueryVariables>) {
          return Apollo.useLazyQuery<GetPromotionQuery, GetPromotionQueryVariables>(GetPromotionDocument, baseOptions);
        }
export type GetPromotionQueryHookResult = ReturnType<typeof useGetPromotionQuery>;
export type GetPromotionLazyQueryHookResult = ReturnType<typeof useGetPromotionLazyQuery>;
export type GetPromotionQueryResult = Apollo.QueryResult<GetPromotionQuery, GetPromotionQueryVariables>;
export const GetPromotionByCodeDocument = gql`
    query GetPromotionByCode_Chilli($code: String!, $brand: BrandEnum, $country: CountryEnum) {
  getPromotionByCode(code: $code, brand: $brand, country: $country) {
    id
    brand
    country
    code
  }
}
    `;
export function useGetPromotionByCodeQuery(baseOptions: Apollo.QueryHookOptions<GetPromotionByCodeQuery, GetPromotionByCodeQueryVariables>) {
        return Apollo.useQuery<GetPromotionByCodeQuery, GetPromotionByCodeQueryVariables>(GetPromotionByCodeDocument, baseOptions);
      }
export function useGetPromotionByCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPromotionByCodeQuery, GetPromotionByCodeQueryVariables>) {
          return Apollo.useLazyQuery<GetPromotionByCodeQuery, GetPromotionByCodeQueryVariables>(GetPromotionByCodeDocument, baseOptions);
        }
export type GetPromotionByCodeQueryHookResult = ReturnType<typeof useGetPromotionByCodeQuery>;
export type GetPromotionByCodeLazyQueryHookResult = ReturnType<typeof useGetPromotionByCodeLazyQuery>;
export type GetPromotionByCodeQueryResult = Apollo.QueryResult<GetPromotionByCodeQuery, GetPromotionByCodeQueryVariables>;
export const GetPromotionFilesDocument = gql`
    query GetPromotionFiles_Chilli($filename: String) {
  generatedPromotionFiles(filename: $filename) {
    link
    createdAt
  }
}
    `;
export function useGetPromotionFilesQuery(baseOptions?: Apollo.QueryHookOptions<GetPromotionFilesQuery, GetPromotionFilesQueryVariables>) {
        return Apollo.useQuery<GetPromotionFilesQuery, GetPromotionFilesQueryVariables>(GetPromotionFilesDocument, baseOptions);
      }
export function useGetPromotionFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPromotionFilesQuery, GetPromotionFilesQueryVariables>) {
          return Apollo.useLazyQuery<GetPromotionFilesQuery, GetPromotionFilesQueryVariables>(GetPromotionFilesDocument, baseOptions);
        }
export type GetPromotionFilesQueryHookResult = ReturnType<typeof useGetPromotionFilesQuery>;
export type GetPromotionFilesLazyQueryHookResult = ReturnType<typeof useGetPromotionFilesLazyQuery>;
export type GetPromotionFilesQueryResult = Apollo.QueryResult<GetPromotionFilesQuery, GetPromotionFilesQueryVariables>;
export const ReferralProgramSettingsDocument = gql`
    query ReferralProgramSettings_Chilli($country: CountryEnum!, $brand: BrandEnum!) {
  referralProgramSettings(country: $country, brand: $brand) {
    ...ReferralProgram
  }
}
    ${ReferralProgramFragmentDoc}`;
export function useReferralProgramSettingsQuery(baseOptions: Apollo.QueryHookOptions<ReferralProgramSettingsQuery, ReferralProgramSettingsQueryVariables>) {
        return Apollo.useQuery<ReferralProgramSettingsQuery, ReferralProgramSettingsQueryVariables>(ReferralProgramSettingsDocument, baseOptions);
      }
export function useReferralProgramSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReferralProgramSettingsQuery, ReferralProgramSettingsQueryVariables>) {
          return Apollo.useLazyQuery<ReferralProgramSettingsQuery, ReferralProgramSettingsQueryVariables>(ReferralProgramSettingsDocument, baseOptions);
        }
export type ReferralProgramSettingsQueryHookResult = ReturnType<typeof useReferralProgramSettingsQuery>;
export type ReferralProgramSettingsLazyQueryHookResult = ReturnType<typeof useReferralProgramSettingsLazyQuery>;
export type ReferralProgramSettingsQueryResult = Apollo.QueryResult<ReferralProgramSettingsQuery, ReferralProgramSettingsQueryVariables>;
export const PromotionsDocument = gql`
    query Promotions_Chilli($code: String, $name: String, $brand: BrandEnum!, $country: CountryEnum!, $hasSplitVouchers: Boolean, $promotionType: PromotionType, $targetingGroup: PromotionTargetingGroup, $valueType: ValueType, $after: String, $before: String, $first: Int, $last: Int) {
  promotions(
    code: $code
    name: $name
    brand: $brand
    country: $country
    hasSplitVouchers: $hasSplitVouchers
    promotionType: $promotionType
    targetingGroup: $targetingGroup
    valueType: $valueType
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    edges {
      cursor
      node {
        id
        name
        code
        expirationDate
        createdBy
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;
export function usePromotionsQuery(baseOptions: Apollo.QueryHookOptions<PromotionsQuery, PromotionsQueryVariables>) {
        return Apollo.useQuery<PromotionsQuery, PromotionsQueryVariables>(PromotionsDocument, baseOptions);
      }
export function usePromotionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromotionsQuery, PromotionsQueryVariables>) {
          return Apollo.useLazyQuery<PromotionsQuery, PromotionsQueryVariables>(PromotionsDocument, baseOptions);
        }
export type PromotionsQueryHookResult = ReturnType<typeof usePromotionsQuery>;
export type PromotionsLazyQueryHookResult = ReturnType<typeof usePromotionsLazyQuery>;
export type PromotionsQueryResult = Apollo.QueryResult<PromotionsQuery, PromotionsQueryVariables>;