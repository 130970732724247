export enum Routes {
  ROOT = '/',
  DASHBOARD = '/dashboard',
  LOGIN = '/login',
  VERCEL_OAUTH_CALLBACK = '/zeit/auth',
  VOUCHERS = '/vouchers',
  VOUCHERS_CREATE = '/vouchers/new',
  VOUCHER_DETAIL = '/vouchers/:id',
  VOUCHER_EDIT = '/vouchers/:id/edit',
  VOUCHERS_CSV_FILES = '/vouchers/csv-files',
  REFERRALS = '/referrals',
}
