import React, { useState } from 'react'
import { Row, Col, Form, Input, Select, Divider, DatePicker, Alert } from 'antd'
import humanizeString from 'humanize-string'

import { PromotionPlatformType } from '../../../../apollo/generated/api'
import CountryDropdown from '../../../CountryDropdown'
import BrandDropdown from '../../../BrandDropdown'
import { PromotionTypeSelect, TargetGroupSelect } from '../../../Inputs'

const { Option } = Select

function VouchersWizardGeneral(): JSX.Element {
  const [showMarketingCopyAlert, setShowMarketingCopyAlert] = useState(false)
  const toggleMarketingCopyAlert = () => setShowMarketingCopyAlert(!showMarketingCopyAlert)

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="name"
            label="Campaign name"
            rules={[{ required: true, message: 'Please type a name for your promotion!' }]}
          >
            <Input data-testid="campaign-name" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <PromotionTypeSelect required />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="description" label="Description of the campaign">
            <Input.TextArea data-testid="campaign-description" autoSize allowClear />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="marketingCopy" label="Marketing copy">
            <Input.TextArea
              onFocus={toggleMarketingCopyAlert}
              onBlur={toggleMarketingCopyAlert}
              data-testid="marketing-copy"
              autoSize
              allowClear
            />
          </Form.Item>

          {showMarketingCopyAlert ? (
            <Alert
              type="warning"
              message={`
                If left blank, this field will automatically be populated
                with the default copy. If you want to change that, please
                input your own copy.
              `}
              showIcon
            />
          ) : null}
        </Col>
      </Row>

      <Divider orientation="left">Restrictions</Divider>

      <Row gutter={16}>
        <Col span={8}>
          <TargetGroupSelect required />
        </Col>

        <Col span={8}>
          <BrandDropdown />
        </Col>

        <Col span={8}>
          <CountryDropdown />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="expirationDate"
            label="Expiration date"
            rules={[{ required: true, message: 'Please select an expiration date!' }]}
          >
            <DatePicker
              style={{ width: '100%' }}
              data-testid="expiration-date-calendar"
              className="expiration-date-calendar"
              placeholder="Select an expiration date"
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="Select a platform" name="platform">
            <Select
              style={{ width: '100%' }}
              placeholder="All"
              data-testid="platform"
              optionFilterProp="children"
              showSearch
              allowClear
            >
              {Object.values(PromotionPlatformType).map(type => (
                <Option value={type} key={type}>
                  {humanizeString(type)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

export default VouchersWizardGeneral
