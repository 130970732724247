import jwtDecode from 'jwt-decode'
import axios from 'axios'

import config from '../../config'
import { CHILLI_USER_SESSION } from '../../constants/AuthConstants'
import { getLocalStorage, setLocalStorage } from '../localStorageUtils'

export enum SessionMessages {
  InvalidUserSession = "You aren't logged in or your session has expired.",
  UserLoggedOut = "You've logged out successfuly.",
  LoginFailed = "There was an error during your user's authentication.",
}

const userRoles = ['cc-agent', 'super_admin'] as const

export type UserRole = typeof userRoles[number]

export type SessionInformation = {
  token: {
    value: string
    exp: number
  }
  user: {
    email: string
    givenName: string
    picture: string
  }
}

export type UserSession = {
  hasValidSession: () => boolean
  session: SessionInformation | null
}

export const getUserSession = (): UserSession => {
  const session = getLocalStorage<SessionInformation>({ key: CHILLI_USER_SESSION, parse: true })
  const now = Math.floor(new Date().getTime() / 1000)
  const hasValidSession = (): boolean => !!session && session?.token.exp > now

  return { hasValidSession, session }
}

type ApiTokenClaims = {
  exp: number
  scopes: string[]
}

const createUserSession = (apiToken: string, user: SessionInformation['user']): UserSession => {
  const token = jwtDecode<ApiTokenClaims>(apiToken)
  const userSession = {
    token: {
      value: apiToken,
      exp: token.exp,
    },
    user,
  }

  setLocalStorage<SessionInformation>({
    key: CHILLI_USER_SESSION,
    value: userSession,
    stringify: true,
  })

  return getUserSession()
}

export const destroyUserSession = (): void => window.localStorage.removeItem(CHILLI_USER_SESSION)

const REQUEST_TIMEOUT = 10000

export const authenticate = async (googleToken: string): Promise<UserSession> => {
  // eslint-disable-next-line camelcase
  const decoded = jwtDecode<{ email: string; given_name: string; picture: string; exp: number }>(googleToken)
  const user = {
    email: decoded.email,
    givenName: decoded.given_name,
    picture: decoded.picture,
  }
  const apiResponse = await axios.post<{ token: string }>(
    `${config.restApiBaseUrl}/employee_token`,
    { email: user.email, provider_token: googleToken, scopes: ['admin'], country: 'us' },
    { timeout: REQUEST_TIMEOUT },
  )
  const apiToken = apiResponse.data.token
  const userSession = createUserSession(apiToken, user)

  return userSession
}
