import { Space } from 'antd'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import React from 'react'

interface Props {
  isEnabled?: boolean
  onClick?: () => void
}

function VouchersAdvancedSearchToggle({ isEnabled, onClick }: Props): JSX.Element {
  const action = isEnabled
    ? { label: 'Hide Advanced Filters', Icon: UpOutlined }
    : { label: 'Show Advanced Filters', Icon: DownOutlined }

  return (
    <div role="button" tabIndex={0} data-testid="vouchers-advanced-search-toggle" onClick={onClick}>
      <Space>
        <action.Icon />
        <span>{action.label}</span>
      </Space>
    </div>
  )
}

VouchersAdvancedSearchToggle.defaultProps = {
  isEnabled: false,
  onClick: undefined,
}

export default VouchersAdvancedSearchToggle
