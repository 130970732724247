import React from 'react'
import { Result, Typography } from 'antd'
import { ResultStatusType } from 'antd/lib/result'

import { GLOBAL_ERROR_MESSAGE } from '../../constants/ErrorConstants'

const { Text } = Typography
const GeneralError: React.FC<{ message: string; status?: ResultStatusType }> = ({ message, status = 500 }) => (
  <Result status={status} title={GLOBAL_ERROR_MESSAGE} subTitle={<Text copyable>{message}</Text>} />
)

export default GeneralError
