import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'

import { Routes } from '../../../constants/RoutesConstants'
import { SessionMessages, authenticate } from '../../../utils'

const TOKEN_QUERY_PARAMETER = 'token'
const LOGIN_REDIRECT = { pathname: Routes.LOGIN, state: { message: SessionMessages.LoginFailed } }

const VercelAuthCallback: React.FC<{ location: Pick<Location, 'search'> }> = ({ location }) => {
  const { search } = location
  const parsedQuery = new URLSearchParams(search)
  const token = parsedQuery.get(TOKEN_QUERY_PARAMETER)
  const [authenticationError, setAuthenticationError] = useState(false)
  const [authenticated, setAuthenticated] = useState(false)

  if (!token) {
    return <Redirect to={LOGIN_REDIRECT} />
  }

  authenticate(token)
    .then(() => setAuthenticated(true))
    .catch(() => setAuthenticationError(true))

  if (authenticated) {
    return <Redirect to={Routes.VOUCHERS} />
  }

  if (authenticationError) {
    return <Redirect to={LOGIN_REDIRECT} />
  }

  return <p>Fetching an API token, please hold...</p>
}

export default VercelAuthCallback
