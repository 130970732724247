import { Form, Select } from 'antd'
import humanizeString from 'humanize-string'
import React from 'react'

import { ValueType } from '../../apollo/generated/api'

interface Props {
  label?: string
  required?: boolean
}

function ValueTypeSelect({ label, required }: Props): JSX.Element {
  return (
    <Form.Item label={label} name="valueType" rules={[{ required, message: 'Please select a value type.' }]}>
      <Select
        style={{ width: '100%' }}
        placeholder="All"
        data-testid="value-type"
        optionFilterProp="children"
        allowClear
        showSearch
      >
        {Object.values(ValueType).map(type => (
          <Select.Option value={type} key={type}>
            {humanizeString(type)}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

ValueTypeSelect.defaultProps = {
  label: 'Type of discount',
  required: false,
}

export default ValueTypeSelect
