import React, { useState } from 'react'
import { useGoogleLogout } from 'react-google-login'
import { Redirect } from 'react-router-dom'
import { message } from 'antd'

import config from '../../../config'
import { Routes } from '../../../constants/RoutesConstants'
import { destroyUserSession, SessionMessages } from '../../../utils'
import { GOOGLE_COOKIE_POLICY } from '../../../constants/AuthConstants'
import { GOOGLE_LOGOUT_BUTTON_ERROR } from '../../../constants/MessagesConstants'

import * as S from './styles'

const Logout: React.FC = () => {
  const [loggedOut, setLoggedOut] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const { signOut, loaded } = useGoogleLogout({
    clientId: config.googleClientId,
    cookiePolicy: GOOGLE_COOKIE_POLICY,
    onLogoutSuccess: () => {
      destroyUserSession()
      setLoggedOut(true)
    },
    onFailure: (): void => {
      void message.error(GOOGLE_LOGOUT_BUTTON_ERROR)
    },
  })

  if (loggedOut) {
    return (
      <Redirect
        to={{
          pathname: Routes.LOGIN,
          state: { message: SessionMessages.UserLoggedOut },
        }}
      />
    )
  }

  return (
    <S.Logout data-testid="logout-button" type="button" onClick={signOut} disabled={!loaded}>
      Logout
    </S.Logout>
  )
}

export default Logout
