import React, { useState } from 'react'
import { Card, PageHeader, Table, Typography } from 'antd'
import moment from 'moment'
import Search from 'antd/lib/input/Search'

import { headerIcons } from '../../../constants/IconsConstants'
import { useGetPromotionFilesQuery } from '../../../apollo/generated/api'

const { Text } = Typography

const VouchersCsvFiles: React.FC = () => {
  const [filenameQuery, setFilenameQuery] = useState<string | null>(null)
  const { loading, data } = useGetPromotionFilesQuery({
    variables: {
      filename: filenameQuery,
    },
  })
  const csvFiles = data?.generatedPromotionFiles
    .slice()
    .sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf())

  const onSearch = (value: string): void => {
    setFilenameQuery(value)
  }

  return (
    <div data-testid="voucher-details">
      <PageHeader title="Vouchers .csv files" avatar={{ src: headerIcons.CSV_ICON, shape: 'square' }} />

      <Card style={{ marginBottom: 20 }}>
        <Search
          data-testid="promotion-file-search"
          placeholder="Search for generated files"
          allowClear
          size="middle"
          onSearch={onSearch}
        />
      </Card>

      <Table
        data-testid="csv-files-table"
        dataSource={csvFiles}
        loading={loading}
        columns={[
          {
            title: 'Link',
            key: 'link',
            dataIndex: 'link',
            render: (value: string) => (
              <>
                <a href={value} target="_blank" rel="noreferrer" download>
                  Download file
                </a>
                <div style={{ marginTop: 5, fontSize: 12 }}>
                  <Text type="secondary">{value}</Text>
                </div>
              </>
            ),
          },
          {
            title: 'Created at',
            key: 'createdAt',
            dataIndex: 'createdAt',
            render: (value: string) => moment(value).format('MMM D, yyyy ∙ HH:mm'),
          },
        ]}
        rowKey="link"
      />
    </div>
  )
}

export default VouchersCsvFiles
