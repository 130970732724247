/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import styled from 'styled-components'

export const Hello = styled.div`
  text-align: center;

  img {
    width: 100px;
    margin: 100px 0 40px;
  }
`

export const Footer = styled.div`
  bottom: 25px;
  color: #999;
  position: absolute;
  text-align: center;
  width: 100%;
`
