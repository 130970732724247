import List from './Vouchers'
import Form from './VouchersForm'
import Details from './VouchersDetails'
import Edit from './VouchersEdit'
import CsvFiles from './VouchersCsvFiles'

const Vouchers = {
  List,
  Form,
  Details,
  Edit,
  CsvFiles,
}

export default Vouchers
