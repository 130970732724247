import { Form, Select } from 'antd'
import React from 'react'

interface Props {
  label: string
  name: string
  testId?: string
  required?: boolean
}

function BooleanSelect({ label, name, required, testId }: Props): JSX.Element {
  return (
    <Form.Item label={label} name={name} rules={[{ required, message: 'Please select an option.' }]}>
      <Select
        style={{ width: '100%' }}
        placeholder="All"
        optionFilterProp="children"
        data-testid={testId}
        allowClear
        showSearch
      >
        <Select.Option value="true">Yes</Select.Option>
        <Select.Option value="false">No</Select.Option>
      </Select>
    </Form.Item>
  )
}

BooleanSelect.defaultProps = {
  required: false,
  testId: undefined,
}

export default BooleanSelect
