import VouchersWizardGeneral from './VouchersWizardGeneral'
import VouchersWizardCreate from './VouchersWizardCreate'
import VouchersSummary from './VouchersSummary'

const VouchersWizard = {
  General: VouchersWizardGeneral,
  Create: VouchersWizardCreate,
  Summary: VouchersSummary,
}

export default VouchersWizard
