import { Card, Col, Form, Input, InputNumber, Row, Typography } from 'antd'
import React from 'react'

import { PromotionFormData } from '../../types'

const { Text } = Typography

const MultipleVoucherFlow: React.FC<{ data: PromotionFormData | undefined; isEditing?: boolean }> = ({
  data,
  isEditing,
}) => {
  const codePreview = (): string => {
    if (!data) return '-'

    const { codePrefix, codeLength } = data

    if (codePrefix && codeLength) {
      return `${codePrefix}${'0'.repeat(Number(codeLength))}`
    }

    return '-'
  }

  return (
    <>
      <Row gutter={16}>
        <Col span={10}>
          <Form.Item name="codePrefix" label="Voucher code prefix">
            <Input data-testid="voucher-prefix" disabled={isEditing} />
          </Form.Item>
        </Col>

        <Col span={10}>
          <Form.Item
            name="codeLength"
            label={
              <>
                Voucher code length{' '}
                <Text type="secondary" style={{ fontSize: 12, marginLeft: 5 }}>
                  (between 6-12)
                </Text>
              </>
            }
          >
            <InputNumber style={{ width: '100%' }} disabled={isEditing} data-testid="code-length" max={12} min={6} />
          </Form.Item>
        </Col>
      </Row>

      <Card>
        Code preview: <Text code>{codePreview()}</Text>
        <div style={{ marginTop: 10 }}>
          <Text strong>Note that this is just a preview of how a code would look like with these settings.</Text>
        </div>
      </Card>
    </>
  )
}

export default MultipleVoucherFlow
