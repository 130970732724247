import React from 'react'
import { Descriptions, Divider, Table } from 'antd'
import humanizeString from 'humanize-string'
import moment from 'moment'

import { PromotionFormData } from '../../types'
import { MappedBrands, MappedCountries, MappedTargetGroups } from '../../../../constants/AppConstants'

const { Column } = Table

const VouchersSummary: React.FC<{ data?: PromotionFormData }> = ({ data }) => {
  const expirationDate = moment(data?.expirationDate).format('MMMM Do, YYYY')

  return (
    <>
      <Descriptions title="Campaign summary" style={{ fontWeight: 'bold' }} layout="vertical" column={4} bordered>
        <Descriptions.Item label="Campaign name">{data?.name}</Descriptions.Item>
        <Descriptions.Item label="Campaign description">{data?.description}</Descriptions.Item>
        <Descriptions.Item label="Marketing copy">{data?.marketingCopy}</Descriptions.Item>
        <Descriptions.Item label="Promotion type">
          {data?.promotionType ? humanizeString(data.promotionType) : '···'}
        </Descriptions.Item>

        <Descriptions.Item label="Target group">
          {data?.targetingGroup ? MappedTargetGroups[data.targetingGroup] : '···'}
        </Descriptions.Item>

        <Descriptions.Item label="Brand">{data?.brand ? MappedBrands[data.brand] : '···'}</Descriptions.Item>
        <Descriptions.Item label="Country">{data?.country ? MappedCountries[data.country] : '···'}</Descriptions.Item>
        <Descriptions.Item label="Platform">{data?.platform ? humanizeString(data.platform) : ''}</Descriptions.Item>
        <Descriptions.Item label="Expires in">{expirationDate}</Descriptions.Item>
      </Descriptions>

      <Divider />

      <Descriptions title="Vouchers summary" style={{ fontWeight: 'bold' }} layout="vertical" column={4} bordered>
        {data?.firstOrderOnly ? (
          <>
            <Descriptions.Item label="Voucher code">{data?.code}</Descriptions.Item>
            <Descriptions.Item label="Usage limit">{data?.usageLimit}</Descriptions.Item>
            <Descriptions.Item label="Usage limit (per user)">{data?.usageLimitPerUser}</Descriptions.Item>
          </>
        ) : (
          <>
            <Descriptions.Item label="Code prefix">{data?.codePrefix}</Descriptions.Item>
            <Descriptions.Item label="Code length">{data?.codeLength}</Descriptions.Item>
            <Descriptions.Item label="Number of Vouchers">{data?.quantity}</Descriptions.Item>
          </>
        )}
      </Descriptions>

      {data?.settings ? (
        <Table dataSource={data.settings} pagination={false}>
          <Column
            title="Ordinal"
            dataIndex="orderOrdinal"
            key="orderOrdinal"
            render={(value, _, index: number) => `Order #${index + 1}`}
          />
          <Column title="Discount Value" dataIndex="value" key="value" />
          <Column
            title="Type of discount"
            dataIndex="valueType"
            key="valueType"
            render={value => humanizeString(value)}
          />
          <Column
            title="Free Shipping"
            dataIndex="freeShipping"
            key="freeShipping"
            align="center"
            render={value => (value ? 'Yes' : 'No')}
          />
        </Table>
      ) : null}
    </>
  )
}

export default VouchersSummary
