import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import config from '../../../config'
import { Routes } from '../../../constants/RoutesConstants'

const StageRoute: React.FC<Props> = ({
  stage,
  current = config.stage,
  redirectPath = Routes.LOGIN,
  children,
  ...rest
}) => {
  const renderProp = (): React.ReactNode => (stage === current ? children : <Redirect to={redirectPath} />)

  return <Route {...rest} render={renderProp} />
}

type Props = RouteProps & {
  stage: string
  current?: string
  redirectPath?: string
}

export default StageRoute
