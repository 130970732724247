import { NetworkStatus } from '@apollo/client'

interface NetworkStatusDigest {
  isLoadingMore: boolean
  isLoading: boolean
}

function useNetworkStatus(networkStatus: NetworkStatus): NetworkStatusDigest {
  const loadingStates = [NetworkStatus.loading, NetworkStatus.refetch, NetworkStatus.setVariables]
  const isLoadingMore = networkStatus === NetworkStatus.fetchMore
  const isLoading = loadingStates.includes(networkStatus)

  return {
    isLoadingMore,
    isLoading,
  }
}

export default useNetworkStatus
