
      export type PossibleTypesResultData = {
  "possibleTypes": {
    "AdjustmentSource": [
      "Promotion",
      "TaxRate"
    ],
    "DeliveryOptionInterface": [
      "DeliveryOption",
      "VisitFirstDeliveryDateOption"
    ],
    "Ingredient": [
      "AssumedIngredient",
      "ShippedIngredient"
    ],
    "OrderPriceInterface": [
      "OrderPricing"
    ],
    "PaymentCredentials": [
      "AdyenKey",
      "BankAccount",
      "CreditCard",
      "DirectDebit",
      "InvoiceRecipient",
      "PaypalCustomer",
      "PreapprovalKey"
    ],
    "PlanInterface": [
      "Plan"
    ],
    "PromotionResponse": [
      "MutationErrorWrapper",
      "Promotion"
    ],
    "RecipeInterface": [
      "OrderRecipe",
      "Recipe"
    ],
    "RecipeRatingInterface": [
      "UserRecipeRating"
    ],
    "ReferralInterface": [
      "ReferralEmail",
      "ReferralLink"
    ],
    "TimeSlotInterface": [
      "SelectedTimeSlotOption",
      "TimeSlot"
    ],
    "UpdateReferralProgramSettingResponse": [
      "MutationErrorWrapper",
      "ReferralProgramSetting"
    ],
    "WeekdayDeliveryOptionInterface": [
      "UserPlanDeliveryOption",
      "VisitDeliveryOption"
    ],
    "_Entity": [
      "Order"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AdjustmentSource": [
      "Promotion",
      "TaxRate"
    ],
    "DeliveryOptionInterface": [
      "DeliveryOption",
      "VisitFirstDeliveryDateOption"
    ],
    "Ingredient": [
      "AssumedIngredient",
      "ShippedIngredient"
    ],
    "OrderPriceInterface": [
      "OrderPricing"
    ],
    "PaymentCredentials": [
      "AdyenKey",
      "BankAccount",
      "CreditCard",
      "DirectDebit",
      "InvoiceRecipient",
      "PaypalCustomer",
      "PreapprovalKey"
    ],
    "PlanInterface": [
      "Plan"
    ],
    "PromotionResponse": [
      "MutationErrorWrapper",
      "Promotion"
    ],
    "RecipeInterface": [
      "OrderRecipe",
      "Recipe"
    ],
    "RecipeRatingInterface": [
      "UserRecipeRating"
    ],
    "ReferralInterface": [
      "ReferralEmail",
      "ReferralLink"
    ],
    "TimeSlotInterface": [
      "SelectedTimeSlotOption",
      "TimeSlot"
    ],
    "UpdateReferralProgramSettingResponse": [
      "MutationErrorWrapper",
      "ReferralProgramSetting"
    ],
    "WeekdayDeliveryOptionInterface": [
      "UserPlanDeliveryOption",
      "VisitDeliveryOption"
    ],
    "_Entity": [
      "Order"
    ]
  }
};
      export default result;
    