import { Descriptions, Divider, Table, PageHeader, Button } from 'antd'
import humanizeString from 'humanize-string'
import React from 'react'
import { useParams, useHistory } from 'react-router'
import { Link } from 'react-router-dom'

import { MultiplePromotionSetting, useGetPromotionQuery, ValueType } from '../../../apollo/generated/api'
import { MappedTargetGroups } from '../../../constants/AppConstants'
import { Routes } from '../../../constants/RoutesConstants'

const { Column } = Table

const VouchersDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { goBack } = useHistory()
  const { data, loading, error } = useGetPromotionQuery({
    variables: { id: parseInt(id, 10) },
    fetchPolicy: 'cache-and-network',
  })

  if (loading) return <>Promo is loading</>
  if (data && data.getPromotion === null) return <>Unable to find promotion</>
  if (error) return <>{error.message}</>

  const promotionData = data?.getPromotion
  const firstOrderData: MultiplePromotionSetting = {
    orderOrdinal: 1,
    freeShipping: promotionData?.freeShipping || false,
    value: promotionData?.value || 0,
    valueType: promotionData?.valueType || ValueType.Fixed,
  }

  return (
    <div data-testid="voucher-details">
      <PageHeader
        title="Voucher details"
        extra={[
          data?.getPromotion?.parent && (
            <Link to={`${Routes.VOUCHERS}/${data?.getPromotion?.parent.id}`}>
              <Button key="parent-button">View Parent</Button>
            </Link>
          ),
          <Button key="back-button" onClick={goBack}>
            Back
          </Button>,
        ]}
      />

      <Descriptions title="Campaign summary" style={{ fontWeight: 'bold' }} layout="vertical" column={4} bordered>
        <Descriptions.Item label="Brand">{promotionData?.brand}</Descriptions.Item>
        <Descriptions.Item label="Country">{promotionData?.country}</Descriptions.Item>
        <Descriptions.Item label="Code">{promotionData?.code}</Descriptions.Item>
        <Descriptions.Item label="Campaign type">{promotionData?.promotionType}</Descriptions.Item>
        <Descriptions.Item label="Campaign name">{promotionData?.name}</Descriptions.Item>
        <Descriptions.Item label="Target group">
          {promotionData?.targetingGroup ? MappedTargetGroups[promotionData?.targetingGroup] : ''}
        </Descriptions.Item>

        <Descriptions.Item label="Platform">
          {promotionData?.platform ? humanizeString(promotionData.platform) : 'All'}
        </Descriptions.Item>

        <Descriptions.Item label="Expires in">{promotionData?.expirationDate}</Descriptions.Item>
        <Descriptions.Item label="Usage limit">{promotionData?.usageLimit}</Descriptions.Item>
        <Descriptions.Item label="Usage limit (per user)">{promotionData?.usageLimitPerUser}</Descriptions.Item>

        <Descriptions.Item label="Current Number of Redemptions">{promotionData?.usages}</Descriptions.Item>
        <Descriptions.Item label="Marketing Copy">{promotionData?.marketingCopy}</Descriptions.Item>
      </Descriptions>

      <Divider />

      <Table dataSource={[firstOrderData].concat(promotionData?.settings || [])} pagination={false}>
        <Column title="Ordinal" dataIndex="orderOrdinal" />
        <Column title="Discount Value" dataIndex="value" />
        <Column title="Type of discount" dataIndex="valueType" />
        <Column
          title="Free Shipping"
          dataIndex="freeShipping"
          align="center"
          render={value => (value ? 'Yes' : 'No')}
        />
      </Table>
    </div>
  )
}

export default VouchersDetails
