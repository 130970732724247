export const REDIRECT_PATH = 'REDIRECT_PATH'

export enum MappedBrands {
  DN = 'Dinnerly',
  MS = 'Marley Spoon',
}

export enum MappedCountries {
  AT = 'Austria',
  AU = 'Australia',
  BE = 'Belgium',
  DE = 'Germany',
  DK = 'Denmark',
  GB = 'Great Britain',
  MS = 'Marley Spoon',
  NL = 'Netherlands',
  SE = 'Sweden',
  US = 'United States of America',
}

export enum MappedTargetGroups {
  'active_customers' = 'All customers',
  'new_customers' = 'New customers',
  'reactivating_customers' = 'Reactivating customers',
}
