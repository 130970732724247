import React from 'react'
import { useParams } from 'react-router'
import { Skeleton, Alert } from 'antd'
import moment from 'moment'

import VouchersForm, { FormViews } from '../VouchersForm'
import { MultiplePromotionSetting, useGetPromotionQuery } from '../../../apollo/generated/api'

const VouchersEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { data, error, loading } = useGetPromotionQuery({
    variables: { id: parseInt(id, 10) },
    fetchPolicy: 'cache-and-network',
  })

  if (loading) return <Skeleton active />

  if (!data?.getPromotion) {
    return <Alert type="error" message="Can't load voucher data!" style={{ marginBottom: 20 }} showIcon />
  }

  // We need to append the general data into the first
  // settings entry. Weird approach, but that's what
  // we got!
  const settings: MultiplePromotionSetting[] = [
    {
      __typename: 'MultiplePromotionSetting',
      orderOrdinal: 1,
      value: data.getPromotion.value,
      valueType: data.getPromotion.valueType,
      freeShipping: data.getPromotion.freeShipping || false,
    },
    ...data.getPromotion.settings,
  ]
  // Disable until the `api` starts sending the correct format
  // for `Promotion`, will need another refactor by then.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formData: any = {
    ...data?.getPromotion,
    settings,
    ...(data.getPromotion.expirationDate
      ? {
          expirationDate: moment(data.getPromotion.expirationDate),
        }
      : null),
  }

  return (
    <>
      {error ? <Alert type="error" message={error.message} style={{ marginBottom: 20 }} showIcon /> : null}
      <VouchersForm data={formData} view={FormViews.EDIT} />
    </>
  )
}

export default VouchersEdit
