export const NOT_FOUND = `The page you're looking for doesn't exist.`

export const GOOGLE_LOGIN_BUTTON_ERROR = 'There was an error interacting with Google Login: '
export const GOOGLE_LOGOUT_BUTTON_ERROR = 'There was an error logging you out. Please try again.'
export const GOOGLE_AUTHENTICATION_FAILED = 'Could not login as there was an issue processing the response from Google.'
export const API_AUTHENTICATION_FAILED = `There was an error authenticating against Marley Spoon's API.
Please try again.`

export const NETWORK_ERROR = 'There was an error communicating with the server. Please try again.'
export const UNKNOWN_ERROR = 'There was an unknown error.'
export const MUTATION_SUCCESS = 'The operation was executed successfully'

export const KEEP_GOING = `You're good to go. Keep going`

export const ERROR_TITLE = 'Error'
