import { Alert, Col, Form, Input, Row } from 'antd'
import React from 'react'

import { PromotionFormData } from '../../types'
import { useGetPromotionByCodeLazyQuery } from '../../../../apollo/generated/api'

function SelfCreatedVoucherFlow({ data }: { data: PromotionFormData | undefined }): JSX.Element {
  const [voucherSearch, { data: searchData }] = useGetPromotionByCodeLazyQuery({ fetchPolicy: 'network-only' })

  const validateVoucherCode = () => {
    if (!data) return

    const { code, brand, country } = data

    // Only trigger `voucher search query` when
    // the voucher code length is higher than 3
    if (code && code?.length > 3) {
      voucherSearch({ variables: { code, brand, country } })
    }
  }

  return (
    <>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="code"
            label="Voucher code"
            rules={[{ required: true, message: 'Please type a code for your voucher!' }]}
          >
            <Input data-testid="voucher-code" onBlur={() => validateVoucherCode()} />
          </Form.Item>
        </Col>
      </Row>

      {searchData?.getPromotionByCode?.id ? (
        <Alert message="This voucher code already exists." type="warning" showIcon />
      ) : (
        <div />
      )}
    </>
  )
}

export default SelfCreatedVoucherFlow
