import { Form, Button, InputNumber, Checkbox, Select, Row, Col, Card, Switch, Typography, notification } from 'antd'
import { Store } from 'antd/lib/form/interface'
import React, { useState, useEffect } from 'react'
import humanizeString from 'humanize-string'
import { SelectValue } from 'antd/lib/select'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'

import { handleMutationResult } from '../../../apollo'
import {
  useReferralProgramSettingsLazyQuery,
  MultiplePromotionSettingsInputType,
  useUpdateReferralsMutation,
  ValueType,
  BrandEnum,
  CountryEnum,
  MultiplePromotionSetting,
} from '../../../apollo/generated/api'
import BrandDropdown from '../../BrandDropdown'
import CountryDropdown from '../../CountryDropdown'
import { getUserSession } from '../../../utils'
import { canUpdateReferrals } from '../../../utils/permissionUtils'

const { Text } = Typography
const { Option } = Select
const settingsConfig = {
  SINGLE: 1,
  // Take into account the already existing first row
  MULTIPLE: 3,
  LIMIT: 7,
}

type SettingsData = MultiplePromotionSettingsInputType[]

const ReferralsSettingsForm: React.FC = () => {
  const [referralSettings, { loading, data }] = useReferralProgramSettingsLazyQuery({ errorPolicy: 'all' })
  const [country, setCountry] = useState<CountryEnum>()
  const [brand, setBrand] = useState<BrandEnum>()
  const [splitVoucher, setSplitVoucher] = useState(false)
  const [settingsData, setSettingsData] = useState<SettingsData>([])
  const [settingsTotal, setSettingsTotal] = useState(1)
  const [updateReferralSettings, { loading: updateReferralsLoading }] = useUpdateReferralsMutation()
  const [form] = Form.useForm()
  const settingEntry = () => ({
    orderOrdinal: 0, // Just a placeholder
    value: 0,
    valueType: 'fixed' as ValueType,
    freeShipping: false,
  })

  const buildSettingsData = (): MultiplePromotionSettingsInputType[] => {
    const settings = (data?.referralProgramSettings?.settings || []) as MultiplePromotionSetting[]
    const entries = [...Array(settingsTotal).keys()]

    return settings.length > 0 ? settings.map(({ __typename, ...setting }) => setting) : entries.map(settingEntry)
  }

  const resetDefaultSettings = () => {
    setSplitVoucher(false)
    setSettingsData([])
    setSettingsTotal(1)
  }

  const searchSettings = ({ brand, country }: Store) => {
    setCountry(country)
    setBrand(brand)
    referralSettings({ variables: { brand, country } })

    resetDefaultSettings()
  }

  const canUpdateSettings = () => {
    const { session } = getUserSession()

    if (!canUpdateReferrals(session?.user.email || '')) {
      notification.error({
        message: 'Unauthorized',
        description: 'You must be a referrals manager to procced with this action.',
      })

      return false
    }

    return true
  }

  const updateSettings = (store: Store) => {
    if (country === undefined || brand === undefined || !canUpdateSettings()) return

    const formattedSettings = settingsData?.map((config, index) => ({
      ...config,
      value: Number(config.value),
      orderOrdinal: index + 2,
    }))
    const variables = {
      referralSettings: {
        country,
        brand,
        configType: store.configType,
        configValue: store.configValue,
        freeShipping: store.freeShipping,
        minBoxes: store.minBoxes,
        maxAvailable: store.maxAvailable,
        voucherExpirationDays: store.voucherExpirationDays,
        settings: formattedSettings,
      },
    }
    const mutation = updateReferralSettings({ variables })

    void handleMutationResult(mutation, 'updateReferralProgramSettings', {
      notifications: {
        success: {
          title: 'Done!',
        },
      },
    })
  }

  const updateSettingsData = ({
    value,
    dataIndex,
    field,
  }: {
    value: number | string | boolean | SelectValue | undefined
    dataIndex: number
    field: string
  }): void => {
    setSettingsData(settingsData?.map((data, index) => (index === dataIndex ? { ...data, [field]: value } : data)))
  }

  const addSettingData = (): void => {
    setSettingsData([...settingsData, settingEntry()])
  }

  const removeSettingsData = ({ dataIndex }: { dataIndex: number }): void => {
    setSettingsData(settingsData?.filter((_, index) => index !== dataIndex))

    if (settingsData.length <= 1) {
      setSplitVoucher(false)
    }
  }

  useEffect(() => {
    if (data?.referralProgramSettings) {
      const { referralProgramSettings } = data
      const settings = (referralProgramSettings?.settings || []) as MultiplePromotionSetting[]

      form.setFieldsValue({
        configType: referralProgramSettings.configType,
        configValue: referralProgramSettings.configValue,
        freeShipping: referralProgramSettings.freeShipping,
        minBoxes: referralProgramSettings.minBoxes,
        maxAvailable: referralProgramSettings.maxAvailable,
        voucherExpirationDays: referralProgramSettings.voucherExpirationDays,
      })

      setSplitVoucher(settings.length > 0)
    }
  }, [data])

  useEffect(() => {
    setSettingsTotal(splitVoucher ? settingsConfig.MULTIPLE : settingsConfig.SINGLE)
  }, [splitVoucher])

  useEffect(() => {
    setSettingsData(splitVoucher ? buildSettingsData() : [])
  }, [settingsTotal])

  const isLoading = loading || updateReferralsLoading

  return (
    <>
      <Card style={{ marginBottom: 25 }}>
        <Form onFinish={searchSettings} layout="vertical">
          <Row gutter={16} align="bottom">
            <Col span={10}>
              <CountryDropdown />
            </Col>

            <Col span={10}>
              <BrandDropdown />
            </Col>

            <Col span={4}>
              <Form.Item>
                <Button htmlType="submit" data-testid="search-settings-submit" disabled={loading} block>
                  Search settings
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>

      {data?.referralProgramSettings ? (
        <Card
          title="Referral Settings"
          extra={[
            <div key="split-voucher-switch">
              Split voucher
              <Switch
                data-testid="split-voucher-switch"
                onClick={() => setSplitVoucher(!splitVoucher)}
                defaultChecked={splitVoucher}
                checked={splitVoucher}
                style={{ marginLeft: 5 }}
              />
            </div>,
          ]}
        >
          <Form form={form} onFinish={updateSettings} data-testid="update-form" layout="vertical">
            <Row gutter={16}>
              <Col span={4}>
                <div style={{ marginBottom: 10 }}>
                  <Text strong>Min. Boxes</Text>
                </div>

                <Form.Item name="minBoxes">
                  <InputNumber style={{ width: '100%' }} min={0} />
                </Form.Item>
              </Col>

              <Col span={4}>
                <div style={{ marginBottom: 10 }}>
                  <Text strong>Max. Available</Text>
                </div>

                <Form.Item name="maxAvailable">
                  <InputNumber data-testid="max-available" style={{ width: '100%' }} min={0} />
                </Form.Item>
              </Col>

              <Col span={4}>
                <div style={{ marginBottom: 10 }}>
                  <Text strong>Discount value</Text>
                </div>

                <Form.Item name="configValue">
                  <InputNumber style={{ width: '100%' }} min={0} />
                </Form.Item>
              </Col>

              <Col span={4}>
                <div style={{ marginBottom: 10 }}>
                  <Text strong>Discount type</Text>
                </div>

                <Form.Item name="configType">
                  <Select style={{ width: '100%' }}>
                    {Object.values(ValueType).map(type => (
                      <Option value={type} key={type}>
                        {humanizeString(type)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={4}>
                <div style={{ marginBottom: 10 }}>
                  <Text strong>Expires in</Text>
                  <Text type="secondary" style={{ fontSize: 12, marginLeft: 5 }}>
                    (days)
                  </Text>
                </div>

                <Form.Item name="voucherExpirationDays">
                  <InputNumber style={{ width: '100%' }} min={0} />
                </Form.Item>
              </Col>

              <Col span={3} style={{ textAlign: 'center' }}>
                <div style={{ marginBottom: 10 }}>
                  <Text strong>Free shipping?</Text>
                </div>

                <Form.Item name="freeShipping" valuePropName="checked">
                  <Checkbox />
                </Form.Item>
              </Col>
            </Row>

            {settingsData?.map(({ value, valueType, freeShipping }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Row key={index} align="middle" gutter={16} style={{ marginBottom: 20 }}>
                <Col span={4} offset={4} style={{ textAlign: 'right', fontSize: 12 }}>
                  #{index + 2}
                </Col>

                <Col span={4}>
                  <InputNumber
                    onBlur={(event: React.FocusEvent<HTMLInputElement>) =>
                      updateSettingsData({
                        value: event.target.value,
                        dataIndex: index,
                        field: 'value',
                      })
                    }
                    data-testid={`discount-value-${index}`}
                    style={{ width: '100%' }}
                    value={value}
                  />
                </Col>

                <Col span={8}>
                  <Select
                    onChange={(value: SelectValue) =>
                      updateSettingsData({ value, dataIndex: index, field: 'valueType' })
                    }
                    data-testid={`discount-type-${index}`}
                    defaultValue={valueType}
                    value={valueType}
                    style={{ width: '100%' }}
                  >
                    {Object.values(ValueType).map(type => (
                      <Option value={type} key={type}>
                        {humanizeString(type)}
                      </Option>
                    ))}
                  </Select>
                </Col>

                <Col span={3} style={{ textAlign: 'center' }}>
                  <Checkbox
                    checked={freeShipping || false}
                    onChange={(e: CheckboxChangeEvent) =>
                      updateSettingsData({
                        value: e.target.checked,
                        dataIndex: index,
                        field: 'freeShipping',
                      })
                    }
                    data-testid={`free-shipping-${index}`}
                  />
                </Col>

                <Col span={1} style={{ textAlign: 'center' }}>
                  <Button type="link" onClick={() => removeSettingsData({ dataIndex: index })} danger>
                    <DeleteOutlined />
                  </Button>
                </Col>
              </Row>
            ))}

            <Row gutter={16} justify="end" style={{ marginTop: 40 }}>
              <Col span={4}>
                {splitVoucher && settingsData.length < settingsConfig.LIMIT ? (
                  <Button onClick={addSettingData} icon={<PlusOutlined />} block>
                    Add line
                  </Button>
                ) : null}
              </Col>

              <Col span={4}>
                <Button
                  type="primary"
                  htmlType="submit"
                  data-testid="update-settings-submit"
                  disabled={isLoading}
                  loading={isLoading}
                  block
                >
                  {isLoading ? 'Wait please ...' : 'Update Settings'}
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      ) : null}
    </>
  )
}

export default ReferralsSettingsForm
